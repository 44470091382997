import { writable } from "svelte/store";

const teamUser = writable(null);
const settingsPage = writable(null);
const teamUserCreateableRoles = writable([]);
const teamUserRole = writable(null);
const scimActive = writable(false);

export {
  teamUser,
  settingsPage,
  teamUserCreateableRoles,
  scimActive,
  teamUserRole,
};
