import i18next from "i18next";
import intervalPlural from "i18next-intervalplural-postprocessor";
import { writable, derived } from "svelte/store";
import onError from "apis/appsignal/appsignal.js";

const t = writable(function (key) {});

const translationCache = {};
const fallbackLng = "en";

const currentLanguage = derived(t, ($t, set) => {
  set(i18next.language);
});

let missingKeys = new Set();

async function fetchTranslations(locale) {
  if (translationCache[locale]) {
    return translationCache[locale];
  }

  const translationFileUrl = `/translations/${locale}.json`;

  try {
    const response = await fetch(translationFileUrl, { cache: "no-cache" });
    if (!response.ok) {
      throw new Error(
        `Translation file for ${locale} not found, Status: ${response.status}, statusText: ${response.statusText}`,
      );
    }
    const translations = await response.json();
    translationCache[locale] = translations;
    return translations;
  } catch (error) {
    console.error(`Error fetching translations for ${locale}:`, error);
    const translationError = new FrontendTranslationError(
      `Error fetching translations for ${locale}: ${error}.`,
    );
    onError(translationError);

    if (locale !== "en") {
      const fallbackLocale = Array.isArray(i18next.options.fallbackLng)
        ? i18next.options.fallbackLng[0]
        : i18next.options.fallbackLng || fallbackLng;
      return fetchTranslations(fallbackLocale);
    } else {
      return null;
    }
  }
}

async function setupI18n({ withLocale } = { withLocale: "en" }) {
  const translations = await fetchTranslations(withLocale);

  return i18next.use(intervalPlural).init({
    lng: withLocale,
    fallbackLng: fallbackLng,
    debug: true,
    saveMissing: true,
    missingKeyHandler: function (
      lngs,
      ns,
      key,
      fallbackValue,
      updateMissing,
      options,
    ) {
      if (!missingKeys.has(key)) {
        missingKeys.add(key);
        const translationError = new FrontendTranslationError(
          `missedKey: ${key}, lng: ${withLocale}, fallbackLng: ${fallbackLng}, lngs: ${lngs.join()}`,
        );
        onError(translationError);
      }
    },
    resources: {
      [withLocale]: {
        translation: translations,
      },
    },
  });
}

const changeLanguage = async (newLanguage) => {
  return setupI18n({ withLocale: newLanguage }).then((_t) => {
    t.set(_t);
    i18next.changeLanguage(newLanguage);
  });
};

const initializeI18N = async function (language) {
  return setupI18n({ withLocale: language }).then((_t) => {
    t.set(_t);
  });
};

class FrontendTranslationError extends Error {
  constructor(message) {
    super(message);
    this.name = "FrontendTranslationError";
  }
}

export { initializeI18N, t, changeLanguage, currentLanguage };
