<script>
  import * as sampleServices from "apps/sample/services";
  import { NavigationService } from "apps/sample/services";
  import * as sampleStore from "apps/sample/stores";
  import { SidebarOverview } from "components/common";
  import { isUserTeamUser } from "stores/user";

  import { Boundary, IconButton } from "components";

  import styles from "styleguide/SampleSidebarOverview.json";

  const { parentId } = sampleStore;

  function navigation(action) {
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const filterParams = Object.fromEntries(params.entries());

    NavigationService.navigation({
      params: {
        filterParams,
      },
      onSuccess: (response) => {
        window.location = response[action];
      },
    });
  }
</script>

<Boundary>
  <div data-component="SampleSidebarOverview" class={styles.wrapper}>
    <div class={styles.navigation}>
      <IconButton
        click={() => navigation("previous")}
        icon="stroke_regular_left"
        style="primary-text"
      />

      <IconButton
        click={() => navigation("next")}
        icon="stroke_regular_right"
        style="primary-text"
      />
    </div>

    <SidebarOverview
      showSmalltabs
      statusesTab={false}
      activityTab={$isUserTeamUser}
      store={sampleStore}
      services={sampleServices}
      itemId={$parentId}
    />
  </div>
</Boundary>

<style lang="scss">
  .wrapper {
    width: 335px;

    .navigation {
      display: flex;
      height: 108px;
      align-items: center;
      justify-content: end;
    }
  }
</style>
