import { passwordResetApi } from "apps/passwordReset/apis";
import { t } from "stores/i18n";
import { toasts } from "stores/toasts";
import { get } from "svelte/store";

class PasswordResetService {
  constructor() {}

  resetPassword({ params, onSuccess, onError } = {}) {
    passwordResetApi.resetPassword({
      params,
      success: (res) => {
        onSuccess?.(res);
      },
      error: (err) => {
        const firstError = err?.errors?.password?.[0];

        if (firstError?.error === "too_long") {
          toasts.send({
            message: get(t)("reset_password.long_password", {
              count: firstError?.count,
            }),
            type: "error",
          });
          onError?.(err);
          return;
        }

        if (firstError?.error === "taken_in_past") {
          toasts.send({
            message: get(t)("reset_password.repeat_password_error"),
            type: "error",
          });
          onError?.(err);
          return;
        }

        toasts.send({
          message: get(t)("errors.unknown"),
          type: "error",
        });
        onError?.(err);
      },
    });
  }

  static instance;

  static getInstance() {
    if (!PasswordResetService.instance) {
      PasswordResetService.instance = new PasswordResetService();
    }
    return PasswordResetService.instance;
  }
}

export const passwordResetService = PasswordResetService.getInstance();
