<script>
  import { Radio } from "components";
  import styles from "styleguide/RadioGroup.json";

  export let toggle;
  export let items;

  function onToggle(id) {
    if (toggle) {
      toggle(id);
    }
  }
</script>

<div data-component="RadioGroup" class={styles.group}>
  {#each items as { value, name, label, checked } (value)}
    <Radio
      {checked}
      onToggle={() => onToggle(value)}
      {label}
      value={name + value}
    />
  {/each}
</div>

<style lang="scss">
  .group {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
</style>
