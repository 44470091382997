<script>
  import { onMount } from "svelte";

  import { userDirectory } from "apps/administration/stores";
  import { t } from "stores/i18n.js";
  import { can } from "stores/permission.js";

  import BoxyhqApi from "apis/admin/boxyhq.js";

  import { Button, Toast } from "components";
  import styles from "styleguide/AdministrationUserSynchronization.json";

  let isDisabled;

  onMount(async () => {
    if (!$can("manage_scim", "account")) {
      window.history.back();
    }

    await loadDirectories();
  });

  async function loadDirectories() {
    BoxyhqApi.getDirectories({
      success: function (response) {
        userDirectory.set(response);
      },
    });
  }

  function createDirectory() {
    BoxyhqApi.createDirectory({
      success: function (response) {
        userDirectory.set(response);
      },
    });
  }

  function updateDirectory() {
    isDisabled = $userDirectory.active;
    BoxyhqApi.updateDirectory({
      params: {
        active: !isDisabled,
      },
      success: function (response) {
        userDirectory.set(response);
        loadDirectories();
      },
    });
  }
</script>

<div data-component="AdministrationUserSynchronization" class={styles.content}>
  <div class={styles.section}>
    <p>
      {$t("admin_user_synchronization.description")}
    </p>
    {#if $userDirectory !== null}
      {#if !$userDirectory.active}
        <Toast
          title={$t("admin_user_synchronization.disabled.title")}
          message={$t("admin_user_synchronization.disabled.message")}
          type="warning"
        >
          <Button click={updateDirectory} style="primary">
            {$t("admin_user_synchronization.disabled.enable")}
          </Button>
        </Toast>
      {:else}
        <p>
          {$t("admin_user_synchronization.active")}
        </p>
      {/if}
      <p>
        <strong>
          {$t("admin_user_synchronization.scim_endpoint")}
        </strong>
        <br />
        {$userDirectory.scim_endpoint}
      </p>
      <p>
        <strong>
          {$t("admin_user_synchronization.scim_secret")}
        </strong>
        <br />
        {$userDirectory.scim_secret}
      </p>
    {/if}
  </div>
  <div class={styles.buttons}>
    {#if $userDirectory === null}
      <Button click={createDirectory} style="primary">
        {$t("admin_user_synchronization.create_sync")}
      </Button>
    {:else if $userDirectory.active}
      <Button click={updateDirectory} style="error">
        {$t("admin_user_synchronization.disable_sync")}
      </Button>
    {/if}
  </div>
</div>

<style scoped lang="scss">
  .content {
    width: 679px;
    padding: 24px;
    padding-top: 0px;
    background: var(--white);
    box-shadow:
      0px 6px 8px -6px rgba(24, 39, 75, 0.12),
      0px 8px 16px -6px rgba(24, 39, 75, 0.08);
    border-radius: 8px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
  }

  .section {
    padding-top: 24px;
  }

  .header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }

  .section-header {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--primary-100);
    padding-bottom: 8px;
  }
  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  p {
    margin-top: 0;
  }
  .inputs {
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-weight: 500;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.5;
  }
</style>
