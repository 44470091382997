import { activities } from "stores/confirmation/confirmation";
import ConfirmationApi from "apis/confirmation/confirmation";

class ActivitiesService {
  constructor() {}

  getActivities({ params = {} }) {
    ConfirmationApi.getActivities({
      params,
      success: (responce) => {
        activities.set({ ...responce, loaded: true });
        onSuccess?.(responce);
      },
    });
  }
}

export default new ActivitiesService();
