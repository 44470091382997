<script>
  import { passwordForgotService } from "apps/passwordForgot/services";
  import { t } from "stores/i18n";

  import { validate } from "lib/validate.js";

  import { AppAuth, Button, TextInput } from "components";

  import styles from "styleguide/PasswordForgot.json";

  export let appConfig;
  export let initialState;

  let email = initialState?.email || "";
  let emailErrors = [];

  function handleResetErrors() {
    emailErrors = [];
  }

  function handleSubmit() {
    const errors = validate(email, ["email"]);

    if (errors?.length) {
      emailErrors = errors;
      return;
    }

    const params = { email };
    passwordForgotService.confirmEmail({
      params,
      onSuccess: () =>
        (window.location = `/logins/sign_in?login[email]=${email}`),
    });
  }
</script>

<AppAuth {...appConfig}>
  <div data-component="PasswordForgot" class={styles.wrapper}>
    <div class={styles.content}>
      <div class={styles.title}>
        {$t("forgot_password.title")}
      </div>

      <TextInput
        style={"border medium round-border"}
        bind:value={email}
        errors={emailErrors}
        onKeyup={handleResetErrors}
      />
    </div>

    <div class={styles.action}>
      <Button click={handleSubmit} style={"primary"}>
        {$t("forgot_password.action")}
      </Button>
    </div>
  </div>
</AppAuth>

<style lang="scss">
  .wrapper {
    @include typography-body-regular;

    display: flex;
    flex-direction: column;
    gap: 24px;
    color: var(--primary-500);
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .title {
    @include typography-caption-semibold;
  }

  .action {
    display: flex;
    flex-direction: column;
  }
</style>
