<script>
  import {
    SidebarActivity,
    SidebarComments,
    SidebarStatus,
  } from "components/common/SidebarOverview/components";
  import { t } from "stores/i18n.js";

  import { Box, LegacyButton as Button, Smalltabs } from "components";
  import styles from "styleguide/SidebarOverview.json";

  export let showSmalltabs = false;

  export let statusesTab = true;
  export let commentsTab = true;
  export let activityTab = true;

  export let store;
  export let services;
  export let itemId;

  let tabs = {};

  const { comments } = store;

  $: commentsLength = $comments.filter((item) => !item.is_deleted).length;

  if (statusesTab) {
    tabs["status"] = {
      id: "status",
      component: SidebarStatus,
      state: "SidebarStatus",
    };
  }

  if (commentsTab) {
    tabs["comments"] = {
      id: "comments",
      component: SidebarComments,
      itemId: itemId,
      store: store,
    };
  }

  if (activityTab) {
    tabs["activity"] = {
      id: "activity",
      component: SidebarActivity,
      itemId: itemId,
      store: store,
      services: services,
    };
  }

  let activeTab = Object.values(tabs)[0];

  function changeTab(target) {
    activeTab = tabs[target];
  }
</script>

<div data-component="SidebarOverview">
  <Box>
    {#if showSmalltabs}
      <Smalltabs>
        {#each Object.values(tabs) as tab}
          <div class={styles.smalltab}>
            <Button
              style={"smalltab fullsize"}
              active={activeTab.id === tab.id}
              click={() => changeTab(tab.id)}
            >
              {$t("sidebar_overview." + tab.id)}
              {#if tab.id === "comments"}
                <span
                  class={commentsLength > 0
                    ? styles.count
                    : styles["empty-count"]}
                >
                  ({commentsLength})
                </span>
              {/if}
            </Button>
          </div>
        {/each}
      </Smalltabs>
    {/if}

    <Box style={"no-border"}>
      <svelte:component
        this={activeTab.component}
        state={activeTab.state}
        {store}
        {services}
        {itemId}
      />
    </Box>
  </Box>
</div>

<style lang="scss">
  .count {
    color: var(--red-200);
  }

  .empty-count {
    color: var(--primary-300);
  }
</style>
