<script>
  import { sanitizeRelaxed } from "lib/sanitize.js";

  import styles from "styleguide/Radio.json";

  export let value;
  export let checked;
  export let label = "";
  export let onToggle = () => {};
</script>

<label class={styles.wrapper} data-component="Radio">
  <input
    class={styles.input}
    type="radio"
    on:change={() => onToggle(value)}
    {checked}
    {value}
  />
  {#if label}
    <span class={styles.label}>{@html sanitizeRelaxed(label)}</span>
  {/if}
</label>

<style lang="scss">
  .wrapper {
    width: 100%;
    max-width: fit-content;
    display: flex;
    gap: 8px;
    cursor: pointer;
  }

  .label {
    align-self: center;
  }

  .input {
    @include typography-body-regular;

    appearance: none;
    background-color: inherit;
    margin: 0;

    color: currentColor;
    width: 20px;
    height: 20px;
    border: 1px solid var(--primary-050);
    border-radius: 50%;

    display: grid;
    place-content: center;
    flex-shrink: 0;
    transform: translateY(1px);
    cursor: pointer;

    &::before {
      content: "";
      width: 12px;
      height: 12px;
      background: var(--blue);
      border-radius: 50%;
      transform: scale(0);
      transition: 0.2s all ease;
      cursor: pointer;
    }

    &:checked::before {
      transform: scale(1);
    }
  }
</style>
