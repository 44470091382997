<script>
  import { account, isAccountFeature } from "stores/account.js";
  import { brandSpace } from "stores/brand_space";
  import { t } from "stores/i18n.js";
  import { isUserTeamUser } from "stores/user.js";

  import { platformLink } from "lib/helpers.js";

  import { NavButton, LanguageSwitcher } from "components";
  import { MainNavMenu } from "./components";
  import { brandspacesWithHelp } from "./constants";

  import styles from "styleguide/MainNavigation.json";

  export let showActions = true;
  export let showLanguageSwitcher = false;

  $: isHelpVisible =
    brandspacesWithHelp.includes($brandSpace) &&
    !$isAccountFeature("hide_documentation");

  function handleLogoClick() {
    window.location.href = platformLink("dashboard");
  }
</script>

<div data-component="MainNavigation" class={styles.wrapper}>
  <div
    class={styles.account}
    on:click={handleLogoClick}
    on:keydown={handleLogoClick}
    role="button"
    tabindex="0"
  >
    {#if $account.has_custom_logo}
      <img
        src={$account.logo}
        alt={$account.official_name}
        class={styles["account-logo"]}
      />
    {/if}

    <div>
      {#if !$account.has_custom_logo}
        <img
          src={$account.default_logo}
          alt="Auditi"
          class={styles["auditi-logo"]}
        />
      {/if}

      <div class={styles["company-name"]}>
        {$account.official_name}
      </div>
    </div>
  </div>

  {#if showLanguageSwitcher}
    <div class={styles.languages}>
      <LanguageSwitcher isSmall />
    </div>
  {/if}

  {#if showActions}
    <div class={styles.actions}>
      <NavButton link={platformLink("dashboard")}>
        {$t("mainnavigation.dashboard")}
      </NavButton>

      {#if $isUserTeamUser}
        <NavButton link={platformLink("account")}>
          {$t("mainnavigation.administration")}
        </NavButton>
      {/if}

      {#if isHelpVisible}
        <NavButton link={platformLink("docs")}>
          {$t("mainnavigation.help")}
        </NavButton>
      {/if}

      <MainNavMenu />
    </div>
  {/if}
</div>

<style lang="scss">
  .wrapper {
    display: flex;
    justify-content: space-between;
    height: 80px;
    background: var(--header-background-color);
    z-index: var(--z-index-navigation);
    padding: 0 var(--page-gutter);
  }

  .account {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
  }

  .auditi-logo {
    width: 46px;
    height: 14px;
  }

  .account-logo {
    max-height: var(--logo-height);
    margin-right: 20px;
    align-self: var(--logo-position);
  }

  .company-name {
    @include typography-headline-semibold;

    display: var(--account-name-display);
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: right;
    gap: 16px;
  }

  .languages {
    margin: auto 16px auto auto;
  }
</style>
