import { brandSpace } from "stores/brand_space";
import { writable, get } from "svelte/store";
import ReviewApi from "apis/review/review.js";
import { dateTimeString } from "lib/helpers.js";

const client = writable({});
const title = writable({});
const billingData = writable({});
const senderAddress = writable({});
const types = writable(["balance", "open", "single"]);
const selectedType = writable("balance");
const deadline = writable("");
const startSending = writable("");
const responsePeriod = writable("14");
const targetDate = writable("");
const fixedResponsePeriod = writable("");
const fiscalYearBeginningDate = writable("");
const usePreviousLetterhead = writable(false);
const openCreditorTemplate = writable(false);
const openDebitorTemplate = writable(false);
const enableSpecificResponses = writable(false);
const invalid = writable(false);
const companies = writable([]);
const step = writable(1);
const touchedSteps = writable({
  1: { dirty: false, valide: false },
  2: { dirty: false, valide: false },
  3: { dirty: false, valide: false },
  4: { dirty: false, valide: true },
});
const user = writable({});
const contactPerson = writable({});

function create(options) {
  let { projectId, success, error } = options;
  let confirmations = [];
  let _billingData = get(billingData);
  let billing_data = {};
  let open_letter = {};

  if (get(brandSpace) === "made") {
    console.log(billing_data, _billingData);
    billing_data = {
      made_anlagejahr: _billingData.anlagejahr,
      made_auftragsnummer: _billingData.auftragsnummer,
      made_auftragspartner_id: _billingData.auftragspartner.id,
    };
  } else if (get(brandSpace) === "ebde") {
    billing_data = {
      ebde_cost: _billingData.cost,
      ebde_part_number: _billingData.part_number,
      ebde_order: _billingData.order_number,
      ebde_year: _billingData.order_year,
    };
  }

  if (get(selectedType) === "open" && get(brandSpace) !== "eyde") {
    open_letter = {
      open_creditor_letter: true,
      open_debitor_letter: true,
    };
  }

  let params = {
    confirmations,
    project_id: projectId,
    name: get(title),
    sender_address: get(senderAddress),
    client_id: get(client).id,
    deadline: get(deadline),
    type: get(selectedType),
    start_sending_at: get(startSending),
    response_period: get(responsePeriod),
    short_open_creditor_template: get(openCreditorTemplate),
    short_open_debitor_template: get(openDebitorTemplate),
    enable_specific_responses: get(enableSpecificResponses),
    target_date: get(targetDate),
    fixed_response_period: get(fixedResponsePeriod),
    fiscal_year_beginning_date: get(fiscalYearBeginningDate),
    use_previous_letterhead: get(usePreviousLetterhead),
    user_id: get(user).id,
    contact_id: get(contactPerson).id,
    ...billing_data,
    ...open_letter,
  };

  ReviewApi.create({
    params,
    success,
    error,
  });
}

function reset() {
  title.set("");
  client.set({});
  senderAddress.set("");
  deadline.set(dateTimeString(new Date(new Date().getFullYear() - 1, 11, 31)));
  targetDate.set(
    dateTimeString(new Date(new Date().getFullYear() - 1, 11, 31)),
  );
  fiscalYearBeginningDate.set(
    dateTimeString(new Date(new Date().getFullYear() - 1, 0, 1)),
  );
  startSending.set("");
  responsePeriod.set("14");
  openCreditorTemplate.set(false);
  openDebitorTemplate.set(false);
  enableSpecificResponses.set(false);
  usePreviousLetterhead.set(false);
  invalid.set(false);
  step.set(1);
  touchedSteps.set({
    1: { dirty: false, valide: false },
    2: { dirty: false, valide: false },
    3: { dirty: false, valide: false },
    4: { dirty: false, valide: true },
  });
  user.set({});
  contactPerson.set({});
}

function getInitBillingData() {
  switch (get(brandSpace)) {
    case "made":
      return {
        anlagejahr: 2019,
        auftragsnummer: "",
        auftragspartner: {},
      };
    case "ebde":
      return {
        order_year: 2019,
        order_number: "",
        part_number: "",
        cost: "",
      };
    default:
      return {};
  }
}

brandSpace.subscribe(() => {
  billingData.set(getInitBillingData());
});

export {
  client,
  title,
  billingData,
  senderAddress,
  types,
  selectedType,
  step,
  touchedSteps,
  deadline,
  companies,
  startSending,
  responsePeriod,
  targetDate,
  fixedResponsePeriod,
  fiscalYearBeginningDate,
  usePreviousLetterhead,
  openCreditorTemplate,
  openDebitorTemplate,
  enableSpecificResponses,
  invalid,
  user,
  contactPerson,
  create,
  reset,
  getInitBillingData,
};
