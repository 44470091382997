<script>
  import { onDestroy } from "svelte";
  import { t } from "stores/i18n.js";

  import { validate } from "lib/validate.js";

  import { Dragicon, IconButton, TextInput, Tooltip } from "components";
  import styles from "styleguide/ConfirmationCreateItemSigner.json";

  export let dirty;
  export let value;
  export let valid;
  export let onEnter;
  export let enterIcon;
  export let onDelete;
  export let onRemoveSignature;
  export let disabled;
  export let deleteButtonDisabled;
  export let onSort;
  export let isEdit = false;

  $: errors = disabled ? [] : validate(value, ["email"]);
  $: valid = !errors.length;

  onDestroy(() => {
    valid = true;
  });
</script>

<div class={styles.wrapper} data-component="ConfirmationCreateItemSigner">
  <div class={styles.input}>
    <TextInput
      {onEnter}
      {enterIcon}
      {disabled}
      bind:value
      mountFocus={true}
      errors={dirty ? errors : []}
      placeholder={$t("confirmation_create_item_signer.placeholder")}
    />
  </div>

  <div class={styles.dragicon}>
    <Dragicon {onSort} />
  </div>

  {#if onRemoveSignature && isEdit}
    <Tooltip>
      <div slot="content" class={styles.remove_signature}>
        <IconButton
          style="primary-text"
          icon={"rotate.svg"}
          click={onRemoveSignature}
        />
      </div>

      <div slot="tooltip">
        {$t("confirmation_create_item_signer.request_new_signature")}
      </div>
    </Tooltip>
  {/if}

  {#if onDelete}
    <IconButton
      style="error-text"
      icon={deleteButtonDisabled ? "trash_muted.svg" : "trash.svg"}
      click={onDelete}
      disabled={deleteButtonDisabled}
    />
  {/if}
</div>

<style lang="scss">
  .wrapper {
    display: flex;
    gap: 8px;
  }

  .input {
    flex: 1;
  }

  .dragicon {
    display: flex;
    padding: 0 8px;
    align-items: center;
    height: 40px;
  }

  .remove_signature {
    display: flex;
  }
</style>
