<script>
  import { account, accountTheme, accountRemoveLogo } from "stores/account.js";
  import { t } from "stores/i18n.js";

  import AccountApi from "apis/admin/accounts.js";

  import { toastsHelper } from "shared/helpers";

  import { Button, Dropdown, IconButton, TextInput } from "components";

  import styles from "styleguide/ThemeAccountLogo.json";

  let hiddenLogoInput;
  let newLogo = null;
  let logoHeight;

  $: positions = {
    "flex-start": $t("theme_account_logo.flex-start"),
    center: $t("theme_account_logo.center"),
    "flex-end": $t("theme_account_logo.flex-end"),
  };

  $: accountNameOptions = {
    none: $t("theme_account_logo.none"),
    block: $t("theme_account_logo.block"),
  };

  $: accountTheme.subscribe((theme) => {
    logoHeight = parseInt(theme["logo-height"]);
  });

  function updateLogoHeight() {
    $accountTheme["logo-height"] = logoHeight + "px";
    previewTheme();
  }

  function updateLogoPosition(value) {
    $accountTheme["logo-position"] = value;
    previewTheme();
  }

  function previewTheme() {
    AccountApi.previewTheme($accountTheme);
  }

  function incrementLogoHeight(delta) {
    logoHeight = Math.max(parseInt($accountTheme["logo-height"]) + delta, 0);
    updateLogoHeight();
  }

  function selectLogo() {
    hiddenLogoInput.click();
  }

  function setLogoPreview(event) {
    newLogo = event.target.files[0];

    if (!newLogo) {
      return;
    }

    if (!newLogo.type.startsWith("image/")) {
      toastsHelper.sendError("theme_account_logo.image_type_error");
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      $account.logo = reader.result;
      $account.has_custom_logo = true;
      $accountTheme["account-name-display"] = "block";
      $accountTheme["logo-height"] = "30px";
      $accountTheme["logo-position"] = "center";
      hiddenLogoInput.value = "";
    };
    reader.readAsDataURL(newLogo);
    previewTheme();
  }

  function removeLogo() {
    $account.logo = $account.default_logo;
    $account.has_custom_logo = false;
    $accountTheme["account-name-display"] = "block";
    $accountTheme["logo-height"] = "30px";
    $accountTheme["logo-position"] = "center";
    $accountRemoveLogo = true;
    previewTheme();
  }
</script>

<div data-component="ThemeAccountLogo" class={styles.content}>
  <div>
    <div class={styles.header}>
      {$t("theme_account_logo.title")}
    </div>
    <div class={styles.buttons}>
      {#if $account.has_custom_logo}
        <Button click={selectLogo} style="primary-tonal" type="file">
          {$t("theme_account_logo.change_logo")}
        </Button>
        <Button click={removeLogo} style={"error-text"} icon={"trash"} />
      {:else}
        <Button click={selectLogo} style="primary-tonal" type="file">
          {$t("theme_account_logo.select_logo")}
        </Button>
      {/if}
    </div>
  </div>
  {#if $account.has_custom_logo}
    <div class="size">
      <div class={styles.header}>
        {$t("theme_account_logo.logo_height")}
      </div>
      <div class={styles.buttons}>
        <IconButton
          style={"primary-text"}
          click={() => incrementLogoHeight(-1)}
          icon="minus"
          disabled={logoHeight === 0}
        />
        <div class={styles["pixel-input"]}>
          <TextInput bind:value={logoHeight} onChange={updateLogoHeight} />
        </div>
        <IconButton
          style={"primary-text"}
          click={() => incrementLogoHeight(+1)}
          icon="plus"
        />
      </div>
    </div>
    <div class={styles["dropdown-wrapper"]}>
      <div class={styles.header}>
        {$t("theme_account_logo.position")}
      </div>
      <Dropdown
        placement="bottom"
        items={positions}
        initial={$t(`theme_account_logo.${$accountTheme["logo-position"]}`)}
        bind:selected={$accountTheme["logo-position"]}
        click={updateLogoPosition}
      />
    </div>
    <div class={styles["account-name-wrapper"]}>
      <div class={styles.header}>
        {$t("theme_account_logo.hide_account_name")}
      </div>
      <Dropdown
        placement="bottom"
        items={accountNameOptions}
        initial={$t(
          `theme_account_logo.${$accountTheme["account-name-display"]}`,
        )}
        bind:selected={$accountTheme["account-name-display"]}
        click={previewTheme}
      />
    </div>
  {/if}
  <input
    type="file"
    bind:this={hiddenLogoInput}
    class={styles.hidden}
    on:change={setLogoPreview}
    accept="image/*"
  />
</div>

<style scoped lang="scss">
  .content {
    display: flex;
    flex-direction: row;
    gap: 36px;
  }
  .header {
    margin: 0px 0px 5px 0px;
    font-weight: bold;
  }
  .buttons {
    justify-content: space-between;
    gap: 4px;
    display: flex;
  }
  .pixel-input {
    width: 60px;
  }
  .dropdown-wrapper {
    width: 120px;
  }
  .hidden {
    display: none;
  }
</style>
