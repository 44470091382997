<script>
  import { iconPath, imagePath } from "lib/helpers.js";
  import styles from "styleguide/LegacyButton.json";

  export let style = "";
  export let navigate = false;
  export let newtab = false; // for navigate
  export let link = false;
  export let click = false;
  export let active = false;
  export let counter = null;
  export let color = "blue"; // counter color
  export let icon = null;
  export let disabled = false;
  export let selected = false;
  export let loading = false;

  $: calculatedStyles = function () {
    let s = style.split(" ");
    if (active) {
      s.push("active");
    }
    if (counter !== null) {
      s.push("with-counter");
    }
    if (icon !== null) {
      s.push("with-icon");
    }
    if (disabled) {
      s.push("disabled");
    }

    const res = s.map((x) => styles[x]).join(" ");
    return res;
  };

  $: counterStyles = function () {
    let s = ["counter", color];
    if (selected) {
      s.push("selected");
    }
    return s.map((x) => styles[x]).join(" ");
  };

  function clickHandler(event) {
    if (!disabled && navigate) {
      if (newtab) {
        window.open(navigate, "_blank");
        this.blur();
      } else {
        // navigateTo(navigate)
      }
    }

    if (!disabled && !loading && click) {
      click(event);
      this.blur();
    }

    if (!disabled && link) {
      window.location = link;
      this.blur();
    }
  }
</script>

<button
  data-component="LegacyButton"
  {disabled}
  class={calculatedStyles()}
  on:click={clickHandler}
>
  {#if counter !== null}
    <div class={counterStyles()}>{counter}</div>
  {/if}

  {#if loading}
    <img
      class="{styles.loading} {styles.icon}"
      src={imagePath("loading-spinner")}
      alt={""}
    />
  {:else if icon !== null}
    {#if style.includes("breadcrumb-button")}
      {#if icon.includes("notification")}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M10.28 15.013c.578 0 .939.626.649 1.126-.618 1.065-1.982 1.427-3.047.81-.335-.194-.614-.473-.81-.809-.292-.5.07-1.127.648-1.127zM9 .75c2.912 0 5.272 2.36 5.272 5.272 0 2.186.394 3.829 1.039 5.01.33.607.641.958.834 1.11l.054.04c.588.391.352 1.283-.313 1.367l-.103.006H2.217c-.707 0-1.005-.872-.498-1.311l.082-.063c.185-.123.525-.482.888-1.148.605-1.108.989-2.621 1.035-4.607l.004-.404C3.728 3.11 6.088.75 9 .75z"
          />
        </svg>
      {:else if icon.includes("import")}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M2.035 7.272c-.38 0-.693-.283-.743-.649l-.007-.101V3.107c0-1.247.96-2.269 2.181-2.352L3.627.75h10.745c1.241 0 2.254.97 2.337 2.195l.005.162v3.415c0 .414-.336.75-.75.75-.38 0-.693-.283-.743-.649l-.007-.101V3.107c0-.439-.323-.798-.737-.85l-.105-.007H3.627c-.428 0-.783.325-.835.749l-.007.108v3.415c0 .414-.336.75-.75.75zm6.964 9.978c-.38 0-.694-.283-.743-.649l-.007-.101V8.347l-1.701 1.715c-.265.268-.682.294-.976.078l-.085-.073c-.267-.264-.294-.681-.077-.976l.072-.084 2.98-3.008c.025-.027.053-.051.082-.074l-.078.069c.037-.038.078-.07.12-.099.014-.01.029-.018.043-.026l.043-.023.052-.023.044-.015c.017-.006.036-.011.055-.016l.042-.008c.019-.004.039-.007.059-.009l.045-.003H9.027c.016 0 .031.002.047.003l-.075-.003c.047 0 .093.004.137.012l.035.008c.021.004.042.01.063.017l.04.014.05.022.046.025.041.025.036.025c.036.028.064.052.09.079l2.979 3.008c.291.294.289.769-.005 1.06-.268.265-.685.288-.977.068l-.084-.073-1.701-1.718V16.5c0 .414-.336.75-.75.75z"
          />
        </svg>
      {:else if icon.includes("history")}
        <svg
          width="18"
          height="19"
          viewBox="0 0 18 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.750488 9.01213C0.751266 13.5677 4.44425 17.2607 9.00051 17.2623C13.5565 17.2615 17.2503 13.5678 17.251 9.01213C17.2495 4.45548 13.5565 0.762497 9.0009 0.761719C7.29749 0.761719 5.67587 1.28046 4.31882 2.21746L3.42102 1.25497C2.99187 0.794729 2.22088 1.03577 2.1303 1.6585L1.65219 4.95725C1.58364 5.43191 1.97207 5.84807 2.45033 5.81237L5.77329 5.56437C6.40099 5.51754 6.69527 4.76498 6.26584 4.30478L5.35554 3.32888C6.42663 2.6402 7.68293 2.26172 9.00077 2.26172C12.7281 2.26236 15.7498 5.28406 15.751 9.01226C15.7504 12.7393 12.7281 15.7616 9.00064 15.7623C5.27283 15.761 2.25112 12.7393 2.25049 9.01187C2.25042 8.59766 1.91457 8.26193 1.50036 8.262C1.08615 8.26207 0.750418 8.59792 0.750488 9.01213ZM9.00035 5.0773C9.38005 5.0773 9.69384 5.35946 9.74351 5.72553L9.75035 5.8273V8.65584L12.719 11.0819C13.0398 11.344 13.0873 11.8165 12.8252 12.1372C12.5869 12.4288 12.1748 12.4946 11.8608 12.3072L11.7699 12.2433L8.52587 9.59235C8.43083 9.51468 8.35978 9.41854 8.31366 9.31336C8.28603 9.25063 8.26674 9.1834 8.2572 9.11307L8.25705 9.11091C8.2527 9.07829 8.25049 9.0454 8.25043 9.01246L8.25035 9.0113V5.8273C8.25035 5.41309 8.58614 5.0773 9.00035 5.0773Z"
          />
        </svg>
      {/if}
    {:else}
      <img class={styles.icon} src={iconPath(icon)} alt={icon} />
    {/if}
  {/if}

  <slot />
</button>

<style scoped lang="scss">
  button,
  button:hover,
  button:focus {
    all: unset;
    position: relative;
    box-sizing: border-box;
    font-family: var(--default-font);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 38px;
    padding: 0 10px;
    background: transparent;
    color: var(--blue-200);
    font-weight: 500;
    border-radius: var(--border-radius);
    border: 2px solid transparent;
    display: flex;
    gap: 4px;

    &:focus:not(:active) {
      border: 2px solid var(--white);
      outline: 2px solid var(--blue);
    }

    &.flex-wrap {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
    }

    &.fullsize {
      width: 100%;
    }

    &.blue {
      color: var(--blue-400);

      &:hover {
        color: var(--blue-500);
      }
    }

    &.green {
      color: var(--green-400);
    }

    &.red {
      color: var(--red-200);
    }

    &.yellow {
      color: var(--yellow-400);
    }

    &.grey {
      color: var(--primary-300);
    }
  }

  .reverse,
  .reverse:hover,
  .reverse:focus {
    flex-direction: row-reverse;
  }

  .with-counter,
  .with-counter:hover,
  .with-counter:focus {
    position: relative;
    display: flex;
    justify-content: flex-start;
    padding-left: 44px;
    height: 34px;
  }

  .with-icon,
  .with-icon:hover,
  .with-icon:focus {
    &.sidebar {
      gap: 12px;
      img {
        padding: 0 5px;
      }
    }

    &.reverse {
      img {
        margin-left: 0;
      }

      &.sidebar {
        img {
          margin-left: 15px;
        }
      }
    }
  }

  .icon-only,
  .icon-only:focus,
  .icon-only:hover {
    width: 40px;
    justify-content: center;
    align-items: center;
    &:focus:not(:active) {
      outline: 2px solid var(--brand);
    }
    img.icon {
      margin-right: 0;
    }
    &.red {
      &:hover {
        background-color: var(--red-010);
      }
      &:active {
        background-color: var(--red-100);
      }
    }
    &.blue {
      &:hover {
        background-color: var(--brand-010);
      }
    }
  }
  .border-icon,
  .border-icon:hover,
  .border-icon:focus {
    img {
      width: 30px;
      height: 30px;
      padding: 5px;
      border: 1px solid var(--primary-050);
      border-radius: var(--border-radius);
      box-sizing: border-box;
    }
  }

  .sidebar {
    &.disabled {
      background: transparent;
    }
  }

  .sidebar-action,
  .sidebar-action:hover,
  .sidebar-action:focus {
    justify-content: flex-start;
    width: 100%;
    border-radius: 0;
    border-top: 1px solid var(--primary-050);
    padding: 10px 20px;
    height: 50px;

    img {
      padding: 6px;
      margin-right: 6px;
    }
  }

  .counter {
    position: absolute;
    left: 0;
    top: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    min-width: 30px;
    padding: 0 6px;
    font-weight: 600;
    border-radius: var(--border-radius);

    &.blue {
      background: var(--blue-010);
      color: var(--blue-200);

      &.selected {
        border: solid 1px var(--blue-200);
      }
    }

    &.green {
      background: var(--green-010);
      color: var(--green-500);

      &.selected {
        border: solid 1px var(--green-500);
      }
    }

    &.red {
      background: var(--red-010);
      color: var(--red-200);
      color: var(--red-200);

      &.selected {
        border: solid 1px var(--red-200);
      }
    }

    &.yellow {
      background: var(--yellow-025);
      color: var(--orange-400);

      &.selected {
        border: solid 1px var(--orange-400);
      }
    }

    &.grey {
      background: var(--primary-025);
      color: var(--primary-400);

      &.selected {
        border: solid 1px var(--primary-400);
      }
    }
  }

  .hover:hover {
    background-color: var(--brand-010);

    &.grey {
      background: var(--primary-025);
    }
  }

  .margin-top,
  .margin-top:hover,
  .margin-top:focus {
    margin-top: 15px;
  }

  .margin-right,
  .margin-right:hover,
  .margin-right:focus {
    margin-right: 10px;
  }

  .small,
  .small:hover,
  .small:focus {
    font-size: 12px;
    height: 20px;
    padding: 0 5px;
    border-radius: 4px;

    &.icon-only,
    &.icon-only:focus,
    &.icon-only:hover {
      width: 20px;
      padding: 0;

      img.icon {
        margin-right: 0;
      }
    }

    &.with-icon,
    &.with-icon:hover,
    &.with-icon:focus {
      img {
        margin-right: 5px;
      }
    }
  }

  .medium,
  .medium:hover,
  .medium:focus {
    height: 30px;
    padding: 0 10px;

    &.icon-only,
    &.icon-only:focus,
    &.icon-only:hover {
      width: 30px;
    }

    &.with-icon:not(.icon-only),
    &.with-icon:hover:not(.icon-only),
    &.with-icon:focus:not(.icon-only) {
      padding: 0 10px 0 2px;
      img {
        margin-right: 2px;
      }
    }
  }

  .height-auto,
  .height-auto:hover,
  .height-auto:focus {
    height: auto;
  }

  .no-padding,
  .no-padding:hover,
  .no-padding:focus {
    padding: 0;
  }

  .nowrap,
  .nowrap:hover,
  .nowrap:focus {
    white-space: nowrap;
  }

  .medium-padding,
  .medium-padding:hover,
  .medium-padding:focus {
    padding: 0 35px;
  }

  .close,
  .close:hover,
  .close:focus {
    height: 30px;
    width: 30px;
    padding: 0;
    color: var(--red-200);
    border: 1px solid var(--primary-050);

    &:hover {
      border-color: var(--brand);
    }
    &.with-background {
      background-color: #fff;
    }
  }

  .inverted {
    border: 1px solid transparent;
    color: #fff;

    &:hover {
      border-color: #fff;
    }
  }

  .border,
  .border:hover,
  .border:focus {
    border: 1px solid var(--primary-050);
    background: #fff;

    &.active,
    &:hover {
      border-color: var(--blue);
    }

    &.blue.active,
    &.blue:hover {
      border-color: var(--blue);
    }
    &.red.active,
    &.red:hover {
      border-color: var(--red-200);
    }
  }

  .boundary,
  .boundary:hover,
  .boundary:focus {
    border: 1px solid var(--primary-050);

    &.blue {
      border-color: var(--brand);
    }
    &.red {
      border-color: var(--red-200);
    }
  }

  .success,
  .success:hover,
  .success:focus {
    background: var(--green-010);
    color: #fff;
    font-weight: 500;
  }

  .option,
  .option:hover,
  .option:focus {
    justify-content: flex-start;
    width: 100%;
    border-radius: 0;
    border-top: 1px solid var(--primary-050);
    height: auto;
    padding: 10px 12px;

    &:first-child {
      border-top: 0;
      border-top-left-radius: var(--border-radius);
      border-top-right-radius: var(--border-radius);
    }

    &:last-child {
      border-bottom-left-radius: var(--border-radius);
      border-bottom-right-radius: var(--border-radius);
    }

    &:hover {
      background: var(--brand-050);
    }

    &.medium {
      padding: 5px 10px !important;
      border-top: none;
    }

    &.disabled {
      color: var(--primary-300);
      background: transparent;
    }
  }

  .tab,
  .tab:hover,
  .tab:focus {
    height: 30px;
    padding: 4px 6px;

    &.active {
      font-weight: 600;
      color: var(--primary-500);
    }
  }

  .tab:hover {
    background: var(--brand-050);
  }

  .smalltab,
  .smalltab:hover,
  .smalltab:focus {
    height: 50px;
    padding: 0 10px;
    border-radius: 0;
    margin-bottom: -1px;
    border-bottom: 2px solid transparent;

    &.active {
      font-weight: 600;
      border-bottom: 2px solid var(--primary-500);
      color: var(--primary-500);
    }
  }

  .cancel,
  .cancel:hover,
  .cancel:focus {
    color: var(--primary-100);
  }

  .ghost,
  .ghost:hover,
  .ghost:focus {
    font-weight: 400;
    padding: 0;
  }

  .inherit,
  .inherit:hover,
  .inherit:focus {
    color: inherit;
  }

  .disabled,
  .disabled:hover,
  .disabled:focus {
    color: var(--primary-300);
    background: var(--primary-050);
    pointer-events: none;

    &.with-icon img {
      filter: saturate(0.5) !important;
      opacity: 0.75;
    }
  }

  .objectionable {
    color: var(--primary-300);
    border: solid 1px transparent;

    &:hover,
    &:focus {
      color: var(--red-200);
      border: solid 1px var(--red-200);
    }
  }

  .attachment:hover {
    background-color: var(--brand-010);
  }

  .loading {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .transparent {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.15);
    border: 1px solid rgba(255, 255, 255, 0.25);
  }

  .tiny,
  .tiny:focus,
  .tiny:hover {
    height: 22px;
    padding: 0 6px;
    font-size: 12px;
    border-radius: 4px;
    color: var(--primary-300);

    &.with-icon img {
      width: 18px;
      height: 18px;
      padding: 0;
      margin-right: 4px;
    }

    &.red {
      color: var(--red-200);
    }

    &.grey {
      color: var(--brand-400);
      background: var(--primary-025);
    }
  }

  .tiny.disabled {
    color: var(--primary-300);
  }

  .tiny.remove:hover {
    color: var(--red-200);
    background-color: rgba(234, 87, 98, 0.1);
  }

  .background {
    color: #fff !important;

    &.grey {
      color: var(--primary-300) !important;
      background: var(--primary-025);
    }

    &.red {
      background-color: var(--red-100);

      &:hover {
        background-color: var(--red-200);
      }
    }
  }

  .header,
  .header:focus,
  .header:hover {
    color: var(--header-button-text-color);

    &:hover {
      color: var(--header-button-hover-text-color);
      background-color: var(--header-button-hover-background-color);
    }
  }

  .eyde-header {
    background: transparent;

    &:hover {
      background: transparent;
      border-color: var(--yellow) !important;

      img {
        filter: hue-rotate(200deg) !important;
      }
    }

    img {
      // Turns the Notification Bell grey
      filter: grayscale(100%) !important;
    }
  }

  .breadcrumb-button {
    justify-content: center;
    align-items: center;
    font-weight: 400;
    color: var(--breadcrumb-button-text-color);
    height: 30px !important;
    gap: 0px !important;

    svg {
      margin-right: 5px;
    }

    path {
      fill: var(--breadcrumb-button-text-color);
    }

    &:hover {
      color: var(
        --breadcrumb-button-text-highlight-color,
        var(--breadcrumb-button-active-text-color)
      );
      background-color: var(
        --breadcrumb-button-background-highlight-color,
        var(--breadcrumb-button-hover-background-color)
      );
      font-weight: 400;

      path {
        fill: var(
          --breadcrumb-button-text-highlight-color,
          var(--breadcrumb-button-active-text-color)
        );
      }
    }

    &:focus:not(:active) {
      color: var(
        --breadcrumb-button-text-highlight-color,
        var(--breadcrumb-button-active-text-color)
      );
      background-color: var(
        --breadcrumb-button-background-highlight-color,
        var(--breadcrumb-button-hover-background-color)
      );
      outline: 2px solid
        var(
          --breadcrumb-button-text-highlight-color,
          var(--breadcrumb-button-active-text-color)
        );
      font-weight: 400;

      path {
        fill: var(
          --breadcrumb-button-text-highlight-color,
          var(--breadcrumb-button-active-text-color)
        );
      }
    }

    &:active {
      color: var(
        --breadcrumb-button-text-highlight-color,
        var(--breadcrumb-button-active-text-color)
      );
      background-color: var(
        --breadcrumb-button-background-highlight-color,
        var(--breadcrumb-button-hover-background-color)
      );
      font-weight: 400;
    }
  }

  .quickshare-button {
    font-weight: 500 !important;
    path {
      fill: var(--breadcrumb-button-text-color);
    }
  }

  .breadcrumb-icon-button {
    width: 30px;
    padding: 0 6px !important;

    path {
      fill: var(--breadcrumb-button-text-color);
    }

    svg {
      margin-right: 0px;
    }

    &:hover,
    &:focus,
    &:active {
      width: 30px;
    }
  }

  .log-out {
    border: 2px solid transparent;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-decoration-line: underline;

    color: #052861; // var(--header-color-hover)
    width: 80px;
    height: 20px;
  }
  .new-settings-border {
    color: var(--brand-400);
    border: 1px solid var(--primary-050);

    &:hover {
      border: 1px solid var(--brand-400);
    }

    &:active {
      border: 1px solid var(--brand-400);
    }

    &:focus:not(:active) {
      border: 1px solid var(--primary-050);
      outline-offset: 2px;
      outline: 2px solid var(--brand);
    }
  }

  .new-settings-primary {
    background: var(--brand);
    color: var(--white);
    font-weight: 500;
    padding: 0 12px;

    &:hover {
      background: var(--brand-400);
      color: var(--white);
    }

    &:active {
      background: var(--brand-active);
      color: var(--white);
    }

    &:focus:not(:active) {
      background: var(--brand);
      outline-offset: 2px;
      outline: 2px solid var(--brand);
      color: var(--white);
    }
  }

  .quickshare-disabled {
    border: 1px solid var(--primary-020, #d3d9e3);
    background: #fff;
    color: var(--primary-050, #8b9eb9);
    line-height: 20px;
    &:hover {
      background-color: var(--primary-020, #d3d9e3);
      color: var(--primary-050, #8b9eb9);
    }
    &:active {
      background-color: var(--primary-020, #d3d9e3);
      color: var(--primary-050, #8b9eb9);
    }
    &:focus {
      outline: 2px solid var(--primary-050, #8b9eb9);
      color: var(--primary-050, #8b9eb9);
    }
  }

  .quickshare-cancel {
    display: inline-flex;
    align-items: center;
    border-radius: 8px;
    background: #fff;
    color: var(--blue-100, #0a5ad9);
    padding: 0;

    &:hover {
      padding: 0;
    }
    &:active {
      padding: 0;
    }
    &:focus {
      padding: 0;
    }
  }

  .quickshare-delete {
    display: flex;
    padding: 10px 12px;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid var(--primary-020, #d3d9e3);

    color: var(--primary-080, #3b5e8e);
    text-align: center;
    font-weight: 500;
    line-height: 20px;

    &:hover {
      padding: 10px 12px;
    }
    &:active {
      padding: 10px 12px;
    }
    &:focus {
      padding: 10px 12px;
    }
  }
</style>
