import Api from "apis/api";

class ApiKeyApi {
  constructor() {
    this.endpoint = "/accounts";
  }

  hasApiKey(options) {
    const { success, error } = options;

    Api.get(`${this.endpoint}/api_key`, {
      success: (response) => {
        success(response.data);
      },
      error: (response) => {
        if (error) {
          error();
        } else {
          console.error("Error", response);
        }
      },
    });
  }

  generateApiKey(options) {
    const { params, success, error } = options;

    Api.post(`${this.endpoint}/api_key`, {
      params,
      success: (response) => {
        success(response.data);
      },
      error: (response) => {
        if (error) {
          error();
        } else {
          console.error("Error", response);
        }
      },
    });
  }

  deleteApiKey(options) {
    const { params, success, error } = options;

    Api.delete(`${this.endpoint}/api_key`, {
      params,
      success: (response) => {
        success(response.data);
      },
      error: (response) => {
        if (error) {
          error();
        } else {
          console.error("Error", response);
        }
      },
    });
  }

  static instance;

  static getInstance() {
    if (!ApiKeyApi.instance) {
      ApiKeyApi.instance = new ApiKeyApi();
    }
    return ApiKeyApi.instance;
  }
}

export const apiKeyApi = ApiKeyApi.getInstance();
