import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input"];

  keydown(event) {
    if (event.key == "Enter" || event.keyCode == 13 || event.which == 13) {
      event.preventDefault();
      this.submitForm();
    }
  }

  submitForm() {
    let inputValue = this.inputTarget.value;
    if (inputValue != "") {
      let url = this.inputTarget.dataset.url;
      this.submit(url, { value: inputValue });
      this.inputTarget.value = "";
    }
  }

  addClick(event) {
    event.preventDefault();
    this.submitForm();
  }

  submit(url, data) {
    console.log(url, data);
    $.ajax({
      url: url,
      type: "POST",
      data: data,
      error: function () {
        // callback()
      },
      success: function (res) {
        // callback(res)
      },
    });
  }

  resetCachedForm() {
    const form = document.querySelector("#gateway-session-form-password");
    const submitButton = form.querySelector('input[type="submit"]');
    const defaultButtonText = "Next";

    const updateButtonState = () => {
      submitButton.removeAttribute("disabled");
      submitButton.value = defaultButtonText;
    };

    updateButtonState();
    window.addEventListener("pageshow", updateButtonState);
  }
}
