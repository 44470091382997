<script>
  import { onMount } from "svelte";

  import { AllowListItems } from "apps/administration/components";
  import { currentIpAddress, ipWhitelist } from "stores/account.js";
  import { t } from "stores/i18n.js";
  import { teamUserCreateableRoles } from "stores/team_user.js";
  import { user } from "stores/user.js";

  import AllowedIpAddressesApi from "apis/compliance_package/allowed_ip_addresses_api";
  import TeamUsersApi from "apis/users/team_users";

  import { Button, Dropdown, TextInput } from "components";
  import styles from "styleguide/AllowList.json";

  let ip_address;
  let description;
  let role;
  let errors = {};

  onMount(async () => {
    AllowedIpAddressesApi.getAllowedIpList();
    TeamUsersApi.getCreateableAdminRoles($user);
  });

  function setErrors(error) {
    errors = Object.entries(error).reduce((acc, [key, errorObject]) => {
      const errorType = Object.values(errorObject?.[0])?.[0] ?? "unknown";
      return {
        ...acc,
        [key]: $t(`errors.${errorType}`),
      };
    }, {});
  }

  $: if (ip_address || role) {
    errors = {};
  }

  function addIpToList() {
    if (!ip_address || !role) {
      errors = {
        ip_address: ip_address ? "" : $t("allow_list.ip_address_required"),
        role: role ? "" : $t("allow_list.role_required"),
      };
      return;
    }

    const data = {
      ip_address,
      description,
      role,
    };
    AllowedIpAddressesApi.addAllowedIpToList({
      data: data,
      successCallback: clearFields,
      errorCallback: setErrors,
    });
  }

  function clearFields() {
    ip_address = "";
    description = "";
    role = "";
    errors = {};
  }
</script>

<div data-component="AllowList" class={styles["allow-list"]}>
  <div class={styles["section"]}>
    <div class={styles["section-header"]}>
      {$t("allow_list.header")}
    </div>
    <div class={styles["section-description"]}>
      <div>{$t("allow_list.description")}</div>
      <div>{$t("allow_list.subtitle")} {$currentIpAddress}</div>
    </div>
    <div class={styles["section-ipaddress"]}>
      <div class={styles.row}>
        <div class={styles.input}>
          <div class={styles["text-input"]}>
            <TextInput
              bind:value={ip_address}
              maxlength={20}
              errors={errors?.ip_address ? [errors.ip_address] : []}
              label={$t("allow_list.ip_address")}
              footnotes={[$t("allow_list.cidr")]}
            />
          </div>
        </div>
        <div class={styles.input}>
          <Dropdown
            style={"acts-as-button"}
            items={$teamUserCreateableRoles}
            initial={$t("allow_list.select")}
            label={$t("allow_list.role")}
            errors={errors?.role ? [errors.role] : []}
            bind:selected={role}
          />
        </div>
      </div>
      <div>
        <div class={styles["text-input"]}>
          <TextInput
            bind:value={description}
            optional={true}
            maxlength={20}
            label={$t("allow_list.ip_description")}
          />
        </div>
        <div class={styles["button"]}>
          <Button click={addIpToList} style="primary">
            {$t("allow_list.add")}
          </Button>
        </div>
      </div>
    </div>
    {#if $ipWhitelist?.length > 0}
      <AllowListItems />
    {/if}
  </div>
</div>

<style lang="scss">
  .section {
    &-header {
      display: flex;
      gap: 8px;
      align-items: center;
      padding-top: 24px;
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      color: var(--primary-500, #052861);
    }

    &-description {
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding-top: 8px;
      font-size: 14px;
      color: var(--primary-300);
    }

    &-ipaddress {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }

  .row {
    display: flex;
    gap: 1em;
    margin-top: 24px;

    .input {
      flex: 1;
      font-weight: 500;
    }
  }

  .text-input {
    font-weight: 500;
  }

  .button {
    margin: 16px 0 24px;
  }

  .buttons {
    display: flex;
    gap: 8px;
    padding: 24px 0;
  }
</style>
