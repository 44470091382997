<script>
  import { profileSettingsService } from "apps/profileSettings/services";

  import { account } from "stores/account.js";
  import { breadcrumb } from "stores/breadcrumb.js";
  import { t, changeLanguage } from "stores/i18n.js";
  import { teamUser, settingsPage } from "stores/team_user.js";
  import { user } from "stores/user.js";

  import { userPath } from "lib/helpers.js";

  import { Dropdown, Switch } from "components";
  import styles from "styleguide/ProfilePersonalization.json";

  let currentTeamUser;

  // TODO move to a separate helper for all ProfileSettings components
  teamUser.subscribe((team_user) => {
    if (team_user) {
      currentTeamUser = team_user;
      breadcrumb.set([
        { translation: "administration", path: userPath + "/account" },
        { translation: "employees", path: userPath + "/team_users" },
        {
          name: currentTeamUser.name
            ? currentTeamUser.name
            : currentTeamUser.email,
          click: () => {
            settingsPage.set("overview");
            window.history.pushState(
              "",
              "",
              userPath + "/team_users/" + currentTeamUser.permalink + "/edit",
            );
          },
        },
        { translation: "personalization" },
      ]);
    }
  });

  function updateTeamUser() {
    if (currentTeamUser.permalink === $user.permalink) {
      changeLanguage(currentTeamUser.language).then(() => {
        profileSettingsService.updateTeamUser({
          params: {
            teamUser: currentTeamUser,
          },
        });
      });
    }
  }

  $: languages = $account.languages.reduce(
    (acc, lang) => ({
      ...acc,
      [lang]: $t(`personalization.${lang}`),
    }),
    {},
  );
</script>

<div class={styles.content}>
  <div class={styles["first-section"]}>
    <div class={styles["section-header"]}>
      {$t("personalization.platform_language")}
    </div>
    <div class={styles["dropdown-section"]}>
      <Dropdown
        click={updateTeamUser}
        placement={"bottom"}
        initial={$t(`personalization.${currentTeamUser.language}`)}
        items={languages}
        bind:selected={currentTeamUser.language}
      />
    </div>
  </div>
  {#if currentTeamUser.permalink === $user.permalink}
    <div class={styles["last-section"]}>
      <div class={styles["section-header"]}>
        {$t("personalization.breadcrumb_header")}
      </div>
      <div class={styles["section-description"]}>
        {$t("personalization.breadcrumb_desc_1")}
      </div>
      <div class={styles["section-description"]}>
        {$t("personalization.breadcrumb_desc_2")}
      </div>
      <div class={styles["breadcrumb-settings"]}>
        <div class={styles.switch}>
          <Switch
            bind:toggled={currentTeamUser.sticky_breadcrumb}
            toggle={updateTeamUser}
          />
        </div>
        <div class={styles["section-description"]}>
          {$t(
            `personalization.sticky_breadcrumb_${currentTeamUser.sticky_breadcrumb}`,
          )}
        </div>
      </div>
    </div>
  {/if}
</div>

<style scoped lang="scss">
  .content {
    top: 24px;
    padding: 0px 24px;
    background: var(--white);
    width: 679px;
    box-shadow:
      0px 6px 8px -6px rgba(24, 39, 75, 0.12),
      0px 8px 16px -6px rgba(24, 39, 75, 0.08);
    border-radius: 8px;
    font-family: "Inter";
    font-style: normal;
  }

  .first-section {
    padding: 24px 0;
  }

  .last-section {
    padding: 24px 0;
    border-top: 1px solid #eaecf1;
  }

  .section-header {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--primary-500);
    padding-bottom: 8px;
  }

  .section-description {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: var(--primary-500);
    padding: 8px 0px;
  }

  .dropdown-section {
    width: 300px;
    padding: 16px 0px 4px 0px;
  }

  .breadcrumb-settings {
    display: flex;
    align-items: center;
  }

  .switch {
    top: 4px;
    padding-right: 10px;
  }
</style>
