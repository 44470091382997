<script>
  import { ipWhitelist } from "stores/account.js";
  import { t } from "stores/i18n.js";
  import { teamUserCreateableRoles } from "stores/team_user.js";

  import AllowedIpAddressesApi from "apis/compliance_package/allowed_ip_addresses_api";
  import { dateTimeFormat } from "lib/helpers.js";

  import { IconButton, Tooltip } from "components";
  import styles from "styleguide/AllowListItems.json";

  function removeItem(permalink) {
    AllowedIpAddressesApi.deleteAllowedIpToList(permalink);
  }
</script>

<div data-component="AllowListItems" class={styles["list"]}>
  <div class={styles["list-header"]}>
    <div class={styles["list-header-ip"]}>
      {$t(`allow_list_items.address`)}
    </div>
    <div class={styles["list-header-description"]}>
      {$t(`allow_list_items.description`)}
    </div>
    <div class={styles["list-header-role"]}>
      {$t(`allow_list_items.role`)}
    </div>
  </div>
  {#each $ipWhitelist as item}
    <div class={styles["list-item"]}>
      <div class={styles["list-item-ip"]}>
        {item.ip_address}
      </div>
      <div class={styles["list-item-description"]}>
        {item.description}
      </div>
      <div class={styles["list-item-role"]}>
        {$teamUserCreateableRoles.hasOwnProperty(item.role)
          ? $teamUserCreateableRoles[item.role]
          : "-"}
      </div>
      <div class={styles["list-item-popover"]}>
        <Tooltip placement="bottom">
          <div slot="content" class={styles.wrapper}>
            <IconButton icon="info" />
          </div>
          <div slot="tooltip" class={styles["tooltip-content"]}>
            <div class={styles["tooltip-subtitle"]}>
              {$t(`allow_list_items.added_by`)}
            </div>
            <div class={styles["list-item-added-by"]}>
              {item.created_by}
            </div>
            <div class={styles["tooltip-subtitle"]}>
              {$t(`allow_list_items.added_on`)}
            </div>
            <div class={styles["list-item-added-on"]}>
              {dateTimeFormat(item.created_at)}
            </div>
          </div>
        </Tooltip>
      </div>
      <div class={styles["list-item-remove"]}>
        <IconButton
          style="error-text"
          icon="trash"
          click={removeItem.bind(this, item.permalink)}
        />
      </div>
    </div>
  {/each}
</div>

<style lang="scss">
  .list {
    margin-bottom: 2em;

    &-header {
      color: var(--primary-500, #052861);
      font-weight: 500;
      padding: 14px 0;
      border-bottom: 1px solid var(--primary-025, #d3d9e3);
      display: flex;
      &-ip {
        width: 20%;
      }
      &-description {
        width: 40%;
      }
      &-role {
        width: 40%;
      }
    }

    &-item {
      display: flex;
      align-items: center;
      border-bottom: 1px solid var(--primary-025, #d3d9e3);
      padding: 12px 0;

      &:last-child {
        border-bottom: none;
      }

      &-ip {
        width: 20%;
      }
      &-description {
        width: 40%;
      }
      &-role {
        width: 25%;
      }
      &-popover {
        width: 10%;
      }

      &-section {
        padding: 18px 0;
      }
    }
  }

  .tooltip-content {
    width: 100%;
    font-weight: 400;
    line-height: 22px;
    margin: 0.9rem;
  }

  .tooltip-subtitle {
    color: var(--primary-500, #052861);
    font-weight: 500;
    line-height: 22px;
    padding: 8px 0;
    &:first-child {
      padding: 0 0 8px 0;
    }
  }
</style>
