<script>
  import { webhookEvents } from "apps/administration/modules/administrationApi/stores";
  import { t } from "stores/i18n.js";

  import { Boundary, Checkbox } from "components";

  import styles from "styleguide/AdministrationWebhookEvents.json";

  export let events;
  export let error;
  export let onChange = () => {};

  function getCheckboxValue(key) {
    return events.some((event) => event === key);
  }

  function handleChange(key, value) {
    const eventsCopy = [...events];

    events = value
      ? [...eventsCopy, key]
      : eventsCopy.filter((event) => event !== key);

    onChange();
  }
</script>

<Boundary>
  <div data-component="AdministrationWebhookEvents" class={styles.wrapper}>
    <p class={styles.title}>{$t("administration_webhooks.events")}:</p>

    {#each Object.entries($webhookEvents || {}) as [key, value]}
      <div class={styles.item}>
        <p class={styles["item-title"]}>
          {$t(`administration_webhook_events.${key}`)}:
        </p>

        {#each Object.entries(value) as [valueKey, items]}
          <div class={styles.group}>
            <p class={styles["group-label"]}>
              {$t(`administration_webhook_events.${valueKey}`)}:
            </p>

            <div class={styles.events}>
              {#each items as item}
                <Checkbox
                  label={$t(`administration_webhook_events.${item}`)}
                  toggled={getCheckboxValue(`${valueKey}.${item}`)}
                  toggle={(val) => handleChange(`${valueKey}.${item}`, val)}
                />
              {/each}
            </div>
          </div>
        {/each}
      </div>
    {/each}

    {#if error}
      <p class={styles.error}>{error}</p>
    {/if}
  </div>
</Boundary>

<style lang="scss">
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .title {
    @include typography-caption-medium;
  }

  .item {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .item-title {
    @include typography-body-medium;
  }

  .group-label {
    @include typography-body-medium;
  }

  .group {
    display: grid;
    gap: 16px;
    grid-template-columns: 20% minmax(0, 1fr);
  }

  .events {
    display: flex;
    gap: 8px 24px;
    flex-wrap: wrap;
  }

  .error {
    color: var(--red-200);
  }

  p {
    margin: 0;
  }
</style>
