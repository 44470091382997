<script>
  import { passwordResetService } from "apps/passwordReset/services";

  import { t } from "stores/i18n";

  import { AppAuth, ResetPasswordForm } from "components";

  export let initialState;
  export let appConfig;

  function updatePassword(data) {
    const { newPassword, newPasswordConfirmation } = data;

    const params = {
      newPassword,
      newPasswordConfirmation,
      token: initialState.reset_password_token,
    };

    passwordResetService.resetPassword({
      params,
      onSuccess: (res) => {
        if (res?.location) {
          window.location = res.location;
        }
      },
    });
  }
</script>

<AppAuth {...appConfig}>
  <ResetPasswordForm
    onSubmit={updatePassword}
    isCurrentPassword={false}
    isTwoColumnLayout={false}
    isFullWidthAction
    title={$t("reset_password.title")}
    actionBtnText={$t("reset_password.action")}
    rules={initialState?.custom_password_rule || {}}
  />
</AppAuth>
