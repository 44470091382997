<script>
  import { getContext } from "svelte";

  import { isAccountFeature } from "stores/account";
  import { item, getItem } from "stores/confirmation/confirmation_item.js";
  import { t } from "stores/i18n.js";
  import { can } from "stores/permission.js";
  import { isUserTeamUser } from "stores/user.js";

  import AttachmentsApi from "apis/attachments/attachments.js";
  import ConfirmationResponsesApi from "apis/confirmation/confirmation_responses.js";
  import {
    fileIcon,
    downloadFile,
    distanceOfTime,
    dateTimeFormat,
  } from "lib/helpers.js";

  import {
    Button,
    DocumentThumbnails,
    Options,
    Toast,
    Tooltip,
  } from "components";
  import styles from "styleguide/ConfirmationResponses.json";

  export let attachments;
  export let integrity;
  export let permalink;
  export let received_via;
  export let received_at;
  export let response_url;
  export let client_preview;
  export let number_of_pages;
  export let internal;

  const config = getContext("config");
  const MAX_PAGES = 50;

  function receivedViaTitle() {
    if (received_via != "upload") {
      return received_via;
    }

    return "upload" + (internal === true ? "-intern" : "-extern");
  }

  function deleteResponse(id) {
    if (confirm($t("questions.sure"))) {
      ConfirmationResponsesApi.delete({
        params: {
          id,
          confirmation_item_id: $item.permalink,
        },
        success: () => {
          getItem();
        },
      });
    }
  }

  function downloadPdf(mailingUrl) {
    downloadFile(
      {
        url: mailingUrl,
        filename: `${$item.company} - ${$t("confirmation_responses.title")}.pdf`,
      },
      null,
      true,
    );
  }

  function onDownloadAttachment(id) {
    AttachmentsApi.getById({
      params: {
        id,
        parent_id: permalink,
        parent_type: "confirmation_response",
      },
      success: (file) => {
        downloadFile(file);
      },
    });
  }
</script>

<div data-component="ConfirmationResponses">
  <div class={styles.block}>
    <div class={styles.header}>
      <div class={styles.title}>
        {#if integrity && integrity !== "unknown"}
          <Tooltip
            style="small"
            nowrap={false}
            message={$t(`confirmation_responses.${integrity}_integrity`)}
            diff="50px"
          >
            <span class="{styles.circle} {styles[integrity]}"></span>
          </Tooltip>
        {/if}
        <span class={styles.type}>{$t("confirmation_responses.title")}</span>
        <Tooltip message={dateTimeFormat(received_at)}>
          <span class={styles.meta}>
            {$t("confirmation_responses.received")}
            {#if received_at}
              {distanceOfTime(
                new Date(received_at).getTime() / 1000,
                config.language,
              )}
            {/if}
            {$t(`confirmation.via.${receivedViaTitle()}`)}
          </span>
        </Tooltip>
      </div>

      <Options
        style="no-border acts-as-button left"
        title={$t("actions.options")}
      >
        {#if response_url && ($isUserTeamUser || $isAccountFeature("mailing_allow_clientuser_download"))}
          <Button
            style="blue-text option"
            click={downloadPdf.bind(this, response_url)}
            >{$t("confirmation_responses.download_pdf")}</Button
          >
        {/if}
        {#if $can("destroy", "confirmation_response")}
          <Button
            style="error-text option"
            click={deleteResponse.bind(this, permalink)}
            >{$t("actions.delete")}</Button
          >
        {/if}
      </Options>
    </div>

    {#if $isUserTeamUser && client_preview}
      <div class={styles["toast-margin-top"]}>
        <Toast
          type="warning"
          message={$t("confirmation_responses.self_sent_request")}
        />
      </div>
    {/if}

    <div class={styles.list}>
      <DocumentThumbnails
        documentUrl={response_url}
        documentId={permalink}
        documentTitle={`${$item.company} - ${$t("confirmation_responses.title")}.pdf`}
        numberOfPages={number_of_pages}
      />
    </div>

    <div class={styles.attachments}>
      {#each attachments as { created_at, filename, permalink, filesize }}
        <div class={styles.attachment}>
          <div class={styles.details}>
            <img
              class={styles.icon}
              src={filename ? fileIcon(filename) : "file.svg"}
              alt={"svg"}
            />

            <div class={styles.info}>
              <div class={styles.name}>{filename}</div>
              <div class={styles.meta}>
                {dateTimeFormat(created_at)}
                {#if filesize}({filesize} MB){/if}
              </div>
            </div>
          </div>

          <div class={styles.buttons}>
            <Button
              style="primary-text small"
              click={() => onDownloadAttachment(permalink)}
              >{$t("confirmation_responses.download")}</Button
            >
          </div>
        </div>
      {/each}
    </div>
  </div>
</div>

<style lang="scss">
  .block {
    padding: 12px 20px 24px;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .title {
    display: flex;
    align-items: center;
  }

  .circle {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border-radius: 50%;
    display: inline-block;

    &.red {
      background-color: var(--red-100);
    }

    &.yellow {
      background-color: var(--yellow);
    }

    &.green {
      background-color: var(--green);
    }
  }

  .type {
    font-size: 18px;
    margin-right: 10px;
  }

  .meta {
    font-size: 12px;
    font-weight: 500;
    color: var(--primary-300);
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    padding-top: 15px;
  }

  .blank {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-top: 46%;
  }

  .loading {
    width: 60px;
    height: 60px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .attachments {
    margin-top: 20px;
  }

  .attachment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .buttons {
    display: flex;
  }

  .details {
    display: flex;
    align-items: center;
  }

  .icon,
  .info {
    margin-left: 10px;
  }

  .name {
    font-weight: 500;
  }

  .toast-margin-top {
    margin-top: 20px;
  }
</style>
