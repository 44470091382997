<script>
  import { onDestroy } from "svelte";
  import {
    ConfirmationNewBlock,
    ConfirmationNewClient,
    ConfirmationNewDate,
  } from "components/confirmationNew";
  import { Modal } from "components/modals";
  import { brandSpace, hasBrandSpace } from "stores/brand_space";
  import {
    confirmation,
    confirmationId,
    getConfirmation,
    activeSettingsEdit,
  } from "stores/confirmation/confirmation.js";
  import {
    title,
    deadline,
    startSending,
    client,
    senderAddress,
    targetDate,
    responsePeriod,
    fixedResponsePeriod,
    fiscalYearBeginningDate,
    getInitBillingData,
    billingData,
  } from "stores/confirmation/confirmation_new.js";
  import { t } from "stores/i18n.js";
  import { user } from "stores/user.js";

  import ConfirmationApi from "apis/confirmation/confirmation.js";
  import ProjectsApi from "apis/dashboard/projects.js";
  import { isDateAfterDate } from "lib/helpers.js";
  import { toastsHelper } from "shared/helpers";

  import { Button, Search } from "components";

  export let hasImplausibleDates = false;

  let dirty = false;
  let dateValide;
  let clientValide;
  let projectId;

  const onHide = function () {
    activeSettingsEdit.set(false);
  };

  function modifySearchResults(response) {
    return response.data.projects.map((item) => {
      return {
        value: item.id,
        label: item.text,
      };
    });
  }

  function setProject(id) {
    projectId = id;
  }

  function resetDatesValidation() {
    hasImplausibleDates = false;
  }

  function validatePlausibleDates() {
    hasImplausibleDates =
      isDateAfterDate($fiscalYearBeginningDate, $targetDate) ||
      isDateAfterDate($fiscalYearBeginningDate, $deadline) ||
      isDateAfterDate($startSending, $fixedResponsePeriod);
  }

  function submit() {
    validatePlausibleDates();

    if (hasImplausibleDates) {
      toastsHelper.sendError(
        "confirmation_settings_edit.implausible_dates_toast_error",
      );
      return;
    }

    dirty = true;
    let newBillingData = { ...$billingData };

    let params = {
      id: $confirmationId,
      name: $title,
      sender_address: $senderAddress,
      deadline: $deadline,
      start_sending_at: $startSending,
      target_date: $targetDate,
      response_period: $responsePeriod,
      fixed_response_period: $fixedResponsePeriod,
      fiscal_year_beginning_date: $fiscalYearBeginningDate,
      enable_specific_responses: $confirmation.enable_specific_responses,
      ...(projectId ? { project_id: projectId } : {}),
    };

    if ($hasBrandSpace("made")) {
      params.billing_data = {};
      params.billing_data[$brandSpace] = {
        anlagejahr: newBillingData.anlagejahr,
        auftragsnummer: newBillingData.auftragsnummer,
        auftragspartner_id: newBillingData.auftragspartner.id,
      };
    } else if ($hasBrandSpace("ebde")) {
      params.billing_data = {};
      params.billing_data[$brandSpace] = newBillingData;
    }

    if (clientValide && dateValide) {
      ConfirmationApi.edit({
        params,
        success: () => {
          confirmation.update((data) => {
            data.name = $title;
            data.sender_address = $senderAddress;
            data.deadline = $deadline;
            data.start_sending_at = $startSending;
            data.client_name = $client.email;
            data.target_date = $targetDate;
            data.response_period = $responsePeriod;
            data.fixed_response_period = $fixedResponsePeriod;
            data.fiscal_year_beginning_date = $fiscalYearBeginningDate;
            return data;
          });
          activeSettingsEdit.set(false);
          billingData.set(newBillingData);
          getConfirmation();
        },
        error: (response) => {
          if (response.code && response.code.name) {
            toastsHelper.sendError("errors.name_is_already_taken");
          } else {
            toastsHelper.sendError("errors.check_entries");
          }
        },
      });
    }
  }

  const unsubscribe = confirmation.subscribe((value) => {
    title.set(value.name);
    senderAddress.set(value.sender_address);
    deadline.set(value.deadline);
    startSending.set(value.start_sending_at);
    targetDate.set(value.target_date);
    responsePeriod.set(value.response_period);
    fixedResponsePeriod.set(value.fixed_response_period);
    fiscalYearBeginningDate.set(value.fiscal_year_beginning_date);
    client.set({
      text: value.client_name,
    });

    let _billingData = {};

    if (value.billing_data && value.billing_data[$brandSpace]) {
      if ($hasBrandSpace("made")) {
        _billingData = {
          anlagejahr: value.billing_data[$brandSpace].anlagejahr,
          auftragsnummer: value.billing_data[$brandSpace].auftragsnummer,
          auftragspartner: {
            id: value.billing_data[$brandSpace].auftragspartner_id,
            text: value.billing_data[$brandSpace].auftragspartner_name,
          },
        };
      } else if ($hasBrandSpace("ebde")) {
        _billingData = value.billing_data[$brandSpace];
      }
    } else {
      _billingData = getInitBillingData();
    }

    billingData.set(_billingData);
  });

  onDestroy(() => {
    unsubscribe();
  });
</script>

{#if $activeSettingsEdit}
  <Modal
    shown={$activeSettingsEdit}
    {onHide}
    title={$t("confirmation_settings_edit.title")}
  >
    <div data-component="ConfirmationSettingsEdit">
      <ConfirmationNewClient
        {dirty}
        active={true}
        clientLock={true}
        clientShow={$user.role !== "client"}
        bind:valide={clientValide}
      />

      <ConfirmationNewDate
        {dirty}
        active={true}
        {resetDatesValidation}
        bind:valide={dateValide}
      />

      <ConfirmationNewBlock
        active="true"
        title={$t("confirmation_settings_edit.project_label")}
      >
        <Search
          placement="top"
          clear={false}
          select={setProject}
          {modifySearchResults}
          searchApi={ProjectsApi}
          placeholder={$t("confirmation_settings_edit.project")}
          footnotes={[$t("confirmation_settings_edit.project_footnotes")]}
        />
      </ConfirmationNewBlock>
    </div>

    <svelte:fragment slot="bottom">
      <Button disabled={hasImplausibleDates} style={"primary"} click={submit}>
        {$t("confirmation_contacts_edit.submit")}
      </Button>
    </svelte:fragment>
  </Modal>
{/if}
