<script>
  import clsx from "clsx";

  import styles from "styleguide/NavButton.json";

  export let link = "";
  export let isPublic = false;

  function handleClick(e) {
    // manual href handling is used to prevent platform tag
    // "style id="account-style" render duplication and theme update bug
    e.preventDefault();
    window.location.href = link;
  }
</script>

<a
  href={link}
  on:click={handleClick}
  class={clsx(styles.link, { [styles["public"]]: isPublic })}
  data-component="NavButton"
>
  <slot />
</a>

<style lang="scss">
  .link {
    @include typography-body-semibold;

    border-radius: var(--border-radius);
    color: var(--header-button-text-color);
    background-color: var(--header-button-background-color);
    display: flex;
    align-items: center;
    padding: 10px 12px;
    width: fit-content;
    text-decoration: none;
    user-select: none;

    &.public {
      font-weight: 500;
    }

    &:visited {
      color: var(--header-button-text-color);
    }

    &:hover {
      background-color: var(
        --header-button-background-highlight-color,
        var(--header-button-background-hover-color)
      );
      color: var(--header-button-text-highlight-color);
    }

    &:focus-visible {
      outline: 2px solid var(--header-button-outline-color);
    }

    &:focus:not(:active) {
      background-color: var(
        --header-button-background-highlight-color,
        var(--header-button-background-hover-color)
      );
      color: var(--header-button-text-highlight-color);
    }

    &:active {
      background-color: var(
        --header-button-background-highlight-color,
        var(--header-button-background-active-color)
      );
      color: var(--header-button-text-highlight-color);
      outline: none;
    }
  }
</style>
