import { writable, derived } from "svelte/store";

class DefaultSettingsStore {
  constructor() {
    const initialStoreValues = {
      project_allowed: writable([]),
      project_default: writable(null),
    };

    for (const key in initialStoreValues) {
      this[key] = initialStoreValues[key];
      this[`${key}Readable`] = derived(this[key], ($value) => $value);
    }
  }

  update(values) {
    Object.keys(values).forEach((key) => {
      if (this[key] && typeof this[key].set === "function") {
        this[key].set(values[key]);
      }
    });
  }
}

export const defaultSettingsStore = new DefaultSettingsStore();
