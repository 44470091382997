import Api from "apis/api";
import { buildFormData } from "lib/helpers.js";

class ProfileSettingsApi {
  constructor() {
    this.endpoint = "/team_users";
  }

  getTeamUser(options) {
    const { params, success, error } = options;

    Api.get(`${this.endpoint}/${params.permalink}`, {
      success,
      error,
    });
  }

  updateTeamUser(options) {
    const { params, success, error } = options;

    const formData = new FormData();
    buildFormData(formData, params.teamUser);

    Api.patch(`${this.endpoint}/${params.teamUser.permalink}`, {
      body: formData,
      success,
      error,
    });
  }

  changePassword(options) {
    const { params, success, error } = options;

    const formData = new FormData();
    buildFormData(formData, params.teamUser);

    Api.patch(`${this.endpoint}/${params.teamUser.permalink}/password`, {
      body: formData,
      success,
      error,
    });
  }

  removeAvatar(options) {
    const { params, success, error } = options;

    Api.delete(`${this.endpoint}/${params.permalink}/avatar`, {
      success,
      error,
    });
  }

  static instance;

  static getInstance() {
    if (!ProfileSettingsApi.instance) {
      ProfileSettingsApi.instance = new ProfileSettingsApi();
    }
    return ProfileSettingsApi.instance;
  }
}

export const profileSettingsApi = ProfileSettingsApi.getInstance();
