<script>
  import { DashboardStatus } from "apps/dashboard";
  import { hasBrandSpace } from "stores/brand_space";
  import {
    projectsStats,
    projectsSelectedFilters,
    filterProjects,
  } from "stores/dashboard/project.js";
  import { t } from "stores/i18n.js";

  import { imagePath } from "lib/helpers";

  import { Boundary, Box } from "components";
  import styles from "styleguide/DashboardProjectListOverview.json";
</script>

<Box title={$t("dashboard_overview.overview")}>
  <Boundary>
    <div data-component="DashboardProjectListOverview" class={styles.wrapper}>
      {#if !$projectsStats}
        <img src={imagePath("loading-spinner.gif")} alt="loading" />
      {:else}
        {#each Object.entries($projectsStats) as [status, count] (status)}
          {#if !(($hasBrandSpace("eyde") || $hasBrandSpace("made")) && status === "notifications_pbc_count")}
            <div class={styles.item}>
              <DashboardStatus
                type="overview"
                {status}
                {count}
                selected={$projectsSelectedFilters[status]}
                click={filterProjects.bind(this, status, count)}
              />
            </div>
          {/if}
        {/each}
      {/if}
    </div>
  </Boundary>
</Box>

<style lang="scss">
  .wrapper {
    padding: 20px;
  }

  .item {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
</style>
