<script>
  import { webhooksService } from "apps/administration/modules/administrationApi/services";

  import { subscriptions } from "apps/administration/modules/administrationApi/stores";
  import { t } from "stores/i18n.js";

  import { Boundary, Button, Tag } from "components";

  import styles from "styleguide/AdministrationWebhooksList.json";

  export let editableItem;

  let disabledItemId = "";

  function handleEdit(item) {
    editableItem = { ...item };
  }

  function handleDelete(id) {
    const isConfirmed = confirm($t("questions.sure"));

    if (isConfirmed) {
      disabledItemId = id;

      const params = {
        id,
      };
      webhooksService.deleteSubscription({
        params,
        onSuccess: () => (disabledItemId = ""),
        onError: () => (disabledItemId = ""),
      });
    }
  }
</script>

<Boundary>
  <div data-component="AdministrationWebhooksList" class={styles.wrapper}>
    {#each $subscriptions as item (item.title)}
      <div class={styles.item}>
        <div class={styles.info}>
          <div>
            <p class={styles.title}>{item.title}</p>
            <p class={styles.descr}>{item.endpoint_url}</p>
          </div>

          <div class={styles.events}>
            <span class={styles.descr}>
              {$t("administration_webhooks.events")}:
            </span>
            <div class={styles.tags}>
              {#each item.event_types as event}
                <Tag label={event} color="grey" size="small" />
              {/each}
            </div>
          </div>
        </div>

        <div class={styles.buttons}>
          <Button
            style={"primary-text"}
            click={() => handleEdit(item)}
            disabled={disabledItemId === item.permalink}
          >
            {$t("actions.edit")}
          </Button>

          <Button
            style={"error-text"}
            click={() => handleDelete(item.permalink)}
            disabled={disabledItemId === item.permalink}
          >
            {$t("actions.delete")}
          </Button>
        </div>
      </div>
    {/each}
  </div>
</Boundary>

<style lang="scss">
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .item {
    display: flex;
    justify-content: space-between;
    gap: 16px;
  }

  .info {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .title {
    @include typography-body-medium;
  }

  .descr {
    @include typography-body-regular;

    color: var(--primary-400);
  }

  .events {
    display: flex;
    gap: 8px;
  }

  .tags {
    display: flex;
    gap: 8px;
    align-items: center;
    flex-wrap: wrap;
  }

  .buttons {
    display: flex;
    gap: 8px;
  }

  p {
    margin: 0;
  }
</style>
