<script>
  import { onMount, onDestroy } from "svelte";

  import { profileSettingsService } from "apps/profileSettings/services";

  import { accountPasswordRules, isAccountFeature } from "stores/account.js";
  import { hasBrandSpace } from "stores/brand_space";
  import { breadcrumb } from "stores/breadcrumb.js";
  import { t } from "stores/i18n.js";
  import { teamUser, settingsPage, scimActive } from "stores/team_user.js";
  import { user } from "stores/user.js";

  import AccountApi from "apis/admin/accounts";

  import { userPath } from "lib/helpers";

  import { BRAND_SPACE, ACCOUNT_FEATURES } from "shared/constants";

  import { Button, Dropdown, Toast, ResetPasswordForm } from "components";

  import styles from "styleguide/ProfileSecurity.json";

  let currentTeamUser;
  let isPasswordRulesLoading = false;

  $: isCustomPasswordRules = $isAccountFeature(
    ACCOUNT_FEATURES.compliancePackage,
  );
  $: isPasswordForm =
    currentTeamUser.permalink === $user.permalink &&
    !isPasswordRulesLoading &&
    !$hasBrandSpace(BRAND_SPACE.eyde);
  $: accessTypes = {
    false: $t("personal_security.account_active"),
    true: $t("personal_security.account_inactive"),
  };

  // TODO move to a separate helper for all ProfileSettings components
  const teamUserUnsubscribe = teamUser.subscribe((user) => {
    if (user) {
      currentTeamUser = user;
      breadcrumb.set([
        { translation: "administration", path: userPath + "/account" },
        { translation: "employees", path: userPath + "/team_users" },
        {
          name: currentTeamUser.name
            ? currentTeamUser.name
            : currentTeamUser.email,
          click: () => {
            settingsPage.set("overview");
            window.history.pushState(
              "",
              "",
              userPath + "/team_users/" + currentTeamUser.permalink + "/edit",
            );
          },
        },
        { translation: "personal_security" },
      ]);
    }
  });

  function getAccessText() {
    return currentTeamUser.suspended
      ? $t("personal_security.account_inactive")
      : $t("personal_security.account_active");
  }

  function updateTeamUser() {
    const params = {
      teamUser: currentTeamUser,
    };

    profileSettingsService.updateTeamUser({
      params,
    });
  }

  function updatePassword({
    currentPassword,
    newPassword,
    newPasswordConfirmation,
  }) {
    currentTeamUser.current_password = currentPassword;
    currentTeamUser.new_password = newPassword;
    currentTeamUser.new_password_confirmation = newPasswordConfirmation;

    const params = { teamUser: currentTeamUser };
    profileSettingsService.changePassword({
      params,
      onSuccess: () => (window.location = "/login"),
    });
  }

  onMount(() => {
    if (isCustomPasswordRules) {
      isPasswordRulesLoading = true;
      AccountApi.getCustomPasswordRules({
        onSuccess: () => (isPasswordRulesLoading = false),
        onError: () => (isPasswordRulesLoading = false),
      });
    }
  });

  onDestroy(teamUserUnsubscribe);
</script>

<div class={styles.content} data-component="ProfileSecurity">
  {#if $scimActive}
    <Toast message={$t("profile_settings.scim_active")} type="info" />
  {/if}

  {#if currentTeamUser.permalink === $user.permalink}
    <div class={styles["first-section"]}>
      <div class={styles["section-header"]}>
        {$t("personal_security.two_factor_header")}
      </div>
      <div class={styles["section-description"]}>
        {$t("personal_security.two_factor_desc_" + $user.verifyEnabled)}
      </div>
      <div class={styles["disable-button"]}>
        <Button
          link={userPath + "/2fa-verification/new"}
          style={"primary-text"}
        >
          {$t("personal_security.two_factor_button_" + $user.verifyEnabled)}
        </Button>
      </div>
    </div>
  {/if}
  <div class={styles.section}>
    <div class={styles["section-header"]}>
      {$t("personal_security.access_header")}
    </div>
    <div class={styles["dropdown-section"]}>
      <Dropdown
        click={updateTeamUser}
        items={accessTypes}
        initial={getAccessText()}
        placement="bottom"
        bind:selected={currentTeamUser.suspended}
        footnotes={[$t("personal_security.access_desc")]}
        disabled={$scimActive}
        lock={$scimActive}
      />
    </div>
  </div>
  {#if isPasswordForm}
    <div class={styles["last-section"]}>
      <ResetPasswordForm
        onSubmit={updatePassword}
        description={$t("personal_security.password_desc")}
        {...isCustomPasswordRules && { rules: $accountPasswordRules }}
      />
    </div>
  {/if}
</div>

<style lang="scss">
  // TODO refactor styles in a separate ticket

  .content {
    width: 679px;
    top: 24px;
    padding: 24px;

    background: var(--white);
    box-shadow:
      0 6px 8px -6px rgba(24, 39, 75, 0.12),
      0 8px 16px -6px rgba(24, 39, 75, 0.08);
    border-radius: 8px;

    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
  }

  .first-section {
    padding: 0 0 24px 0;
    border-bottom: 1px solid #eaecf1;
  }

  .section {
    padding: 24px 0;
  }

  .last-section {
    border-top: 1px solid #eaecf1;
    padding-top: 24px;
  }

  .section-header {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--primary-500);
    padding-bottom: 8px;
  }

  .section-description {
    font-size: 14px;
    line-height: 22px;
    color: var(--primary-500);
  }

  .dropdown-section {
    width: 300px;
    padding: 16px 0 4px 0;
  }

  .dropdown-info {
    font-size: 12px;
    line-height: 18px;
    color: var(--primary-300);
    padding-top: 4px;
  }

  .disable-button {
    padding-top: 24px;
  }
</style>
