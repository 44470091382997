<script>
  import { onDestroy } from "svelte";
  import { t } from "stores/i18n.js";
  import { activeTemplate } from "stores/pbc.js";
  import AuditTemplateApi from "apis/pbc/audit_templates.js";
  import styles from "styleguide/AuditTemplateSidebar.json";

  export let favoritesFilters;
  export let customFilters;
  export let systemFilters;
  export let activeFilter;
  export let onFilterChange;

  const unsubscribe = activeTemplate.subscribe((value) => {
    if (value === "manage" || value === "overwrite") {
      AuditTemplateApi.get(value);
    }
  });

  onDestroy(unsubscribe);
</script>

<div class={styles.sidebar}>
  {#each favoritesFilters as filter}
    <div
      on:click={() => onFilterChange(filter)}
      class={`${styles["sidebar-item"]} ${activeFilter === filter ? styles.active : ""}`}
    >
      {$t(`audit_template.${filter}`)}
    </div>
  {/each}
  {#if !!customFilters.length}
    <div class={styles["sidebar-title"]}>
      {$t("audit_template.custom_templates")}
    </div>
  {/if}
  {#each customFilters as filter}
    <div
      on:click={() => onFilterChange(filter)}
      class={`${styles["sidebar-item"]} ${activeFilter === filter ? styles.active : ""}`}
    >
      {$t(`audit_template.${filter}`)}
    </div>
  {/each}
  {#if !!systemFilters.length}
    <div class={styles["sidebar-title"]}>
      {$t("audit_template.system_templates")}
    </div>
  {/if}
  {#each systemFilters as filter}
    <div
      on:click={() => onFilterChange(filter)}
      class={`${styles["sidebar-item"]} ${activeFilter === filter ? styles.active : ""}`}
    >
      {$t(`audit_template.${filter}`)}
    </div>
  {/each}
</div>

<style lang="scss">
  .sidebar-title {
    margin-top: 16px;
    color: var(--primary-300);
    @include typography-footnote-regular;
  }

  .sidebar-item {
    @include typography-body-medium;
    color: var(--blue-200);
    margin: 4px -24px 0px -24px;
    padding: 4px 25px;
    position: relative;
    cursor: pointer;

    &.active {
      color: var(--primary-500);
      &:after {
        content: "";
        width: 3px;
        height: 22px;
        background: var(--primary-500);
        border-radius: 3px 0 0 3px;
        position: absolute;
        right: 0;
      }
    }
  }
</style>
