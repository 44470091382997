<script>
  import {
    SampleDetailsCardActions,
    SampleDetailsCardBalances,
  } from "apps/sample/components/detailsCard";
  import { SampleService } from "apps/sample/services";
  import { sample } from "apps/sample/stores";
  import clsx from "clsx";
  import { ContactInfoCard } from "components/common";
  import { t } from "stores/i18n.js";
  import { isUserTeamUser } from "stores/user.js";
  import { Box } from "components";
  import styles from "styleguide/SampleDetails.json";

  function validateAddress(value) {
    SampleService.validateAddress({
      params: {
        id: $sample.permalink,
        address_validated: value,
      },
    });
  }

  function handleCreateSampleMailing(sendVia) {
    SampleService.createSampleMailing({
      params: {
        via: sendVia,
        sample_id: $sample.permalink,
      },
      onSuccess: () => {
        SampleService.getSample({
          params: {
            id: $sample.permalink,
          },
        });
      },
    });
  }

  function handleOpenPositionsModal() {
    SampleService.getOpenPositions({
      params: {
        id: $sample.permalink,
      },
      onSuccess: () => {
        SampleService.resetOpenPositionsModal();
      },
    });
  }

  function handleSampleEditModal() {
    SampleService.resetSampleEditModal();
  }

  function handleSampleDeleteModal() {
    SampleService.resetSampleDeleteModal();
  }

  function onSubmitSampleDelete() {
    SampleService.deleteSample({
      params: {
        id: $sample.permalink,
      },
      onSuccess: () => {
        window.location = $sample.review_path;
      },
    });
  }
</script>

<Box>
  <div class={styles.card} data-component="SampleDetails">
    <div class={clsx(styles.contact, styles["card-section"])}>
      <ContactInfoCard
        {...$sample.contact}
        reference={$sample.reference}
        contact={$sample.contact.contact_name}
        {validateAddress}
      />
    </div>

    <div class={clsx(styles["section-divider"], styles.divider)} />

    <div class={clsx(styles.account, styles["card-section"])}>
      <div class={styles["card-section--details"]}>
        <div>
          <div class={styles.title}>{$t("sample_details.account")}</div>
          <div>{$sample.reference}</div>
        </div>
      </div>

      <div class={styles["card-section--details"]}>
        <SampleDetailsCardBalances />
      </div>
    </div>

    <div class={clsx(styles["card-divider"], styles.divider)} />

    {#if $isUserTeamUser}
      <div class={styles.actions}>
        <SampleDetailsCardActions
          {handleCreateSampleMailing}
          {handleOpenPositionsModal}
          {handleSampleEditModal}
          {handleSampleDeleteModal}
          {onSubmitSampleDelete}
        />
      </div>
    {/if}
  </div>
</Box>

<style lang="scss">
  .card {
    display: grid;
    grid-template-columns: 1fr 0.5px 1fr;
    grid-template-rows: auto 0.5px auto;
    grid-template-areas:
      "contact section-divider account"
      "card-divider card-divider card-divider"
      "actions actions actions";

    &-section {
      margin-top: 5px;

      &--details {
        display: grid;
        gap: 5px;
        padding: 10px 20px 15px;

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  .contact {
    grid-area: contact;
  }

  .account {
    grid-area: account;
  }

  .actions {
    grid-area: actions;
  }

  .section-divider {
    grid-area: section-divider;
  }

  .card-divider {
    grid-area: card-divider;
  }

  .title {
    color: var(--primary-300);
  }

  .divider {
    background-color: var(--primary-050);
  }
</style>
