import { passwordForgotApi } from "apps/passwordForgot/apis";
import { t } from "stores/i18n";
import { toasts } from "stores/toasts";
import { get } from "svelte/store";

class PasswordForgotService {
  constructor() {}

  confirmEmail({ params, onSuccess, onError } = {}) {
    const { email } = params || {};

    passwordForgotApi.confirmEmail({
      params: { email },
      success: (res) => {
        onSuccess?.(res);
      },
      error: (err) => {
        toasts.send({
          message: get(t)("errors.unknown"),
          type: "error",
        });
        onError?.(err);
      },
    });
  }

  static instance;

  static getInstance() {
    if (!PasswordForgotService.instance) {
      PasswordForgotService.instance = new PasswordForgotService();
    }
    return PasswordForgotService.instance;
  }
}

export const passwordForgotService = PasswordForgotService.getInstance();
