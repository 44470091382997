<script>
  import { onMount } from "svelte";
  import clsx from "clsx";
  import { iconPath } from "lib/helpers.js";
  import { Button, IconButton, InputWrapper } from "components";
  import styles from "styleguide/TextInput.json";

  export let label;
  export let value = "";
  export let style = "";
  export let placeholder;
  export let icon;
  export let iconReverse;
  export let button;
  export let lock = false;
  export let disabled = false;
  export let optional;
  export let footnotes;
  export let errors = [];
  export let invalid = false;
  export let input;
  export let onEnter = () => {};
  export let onInput = () => {};
  export let onChange = () => {};
  export let onFocus = () => {};
  export let onBlur = () => {};
  export let onKeydown = () => {};
  export let onKeyup = () => {};
  export let onMousedown = () => {};
  export let onIconClick = () => {};
  export let onClick = () => {};
  export let enterIcon = undefined;
  export let enterText = undefined;
  export let count = null;
  export let autocomplete = "new-password";
  export let textarea = false;
  export let textareaRows = 4;
  export let mountFocus = false;
  export let clearOnEnter = false;
  export let maxlength;
  export let type = "text";

  $: _invalid = invalid || errors.length > 0;
  let visible = false;

  function toggleVisible() {
    visible = !visible;
    type = visible ? "text" : "password";
  }

  function handlerIconClick() {
    if (onIconClick) {
      onIconClick(value);
      if (clearOnEnter) {
        value = "";
      }
    }
  }

  function handlerKeydown(event) {
    if (onKeydown) onKeydown(value);
    if (onEnter && event.code === "Enter") {
      onEnter(value);
      if (clearOnEnter) {
        value = "";
      }
    }
  }

  function handlerKeyup(event) {
    if (onKeyup) onKeyup(value);
    if (onEnter && event.code === "Enter") {
      onEnter(value);
      if (clearOnEnter) {
        value = "";
      }
    }
  }

  onMount(() => {
    if (mountFocus && !value) {
      input.focus();
    }
  });
</script>

<InputWrapper {label} {optional} {footnotes} {errors}>
  <span
    data-component="TextInput"
    class={clsx(
      styles["text-input"],
      style.split(" ").map((x) => styles[x]),
      { [styles["with-icon"]]: icon },
      { [styles["with-reverse"]]: iconReverse },
      { [styles["invalid"]]: _invalid },
      { [styles["disabled"]]: disabled },
    )}
  >
    {#if icon}
      <img class={styles.icon} src={iconPath(icon)} alt="" />
    {/if}
    {#if type === "password" || visible}
      <img
        class={styles["password-icon"]}
        src={iconPath(visible ? "eye_closed" : "eye")}
        alt=""
        on:click={toggleVisible}
      />
    {/if}
    {#if button}
      <div class={styles.button}>
        <IconButton
          icon="plus"
          style={"primary-text small"}
          click={handlerIconClick}
        />
      </div>
    {/if}
    {#if textarea}
      <textarea
        rows={textareaRows}
        bind:this={input}
        bind:value
        on:keydown={onKeydown}
        {placeholder}
        {disabled}
        {autocomplete}
      />
    {:else if type === "button"}
      <input
        bind:this={input}
        bind:value
        on:click={onInput}
        on:focus={onFocus}
        type="button"
        {placeholder}
        disabled={disabled || lock}
        {maxlength}
        {autocomplete}
      />
    {:else if type === "password"}
      <input
        bind:this={input}
        bind:value
        on:keydown={handlerKeydown}
        on:keyup={handlerKeyup}
        on:input={onInput}
        on:change={onChange}
        on:focus={onFocus}
        on:blur={onBlur}
        on:mousedown={onMousedown}
        class="passwordInput"
        style="padding-left: 12px"
        type="password"
        {icon}
        {placeholder}
        disabled={disabled || lock}
        {maxlength}
        {autocomplete}
      />
    {:else}
      <input
        bind:this={input}
        bind:value
        on:keydown={handlerKeydown}
        on:keyup={handlerKeyup}
        on:input={onInput}
        on:change={onChange}
        on:focus={onFocus}
        on:blur={onBlur}
        on:mousedown={onMousedown}
        on:click={onClick}
        type="text"
        {placeholder}
        disabled={disabled || lock}
        {maxlength}
        {autocomplete}
      />
    {/if}

    {#if count !== null}
      <div class={styles.count}>
        {count}
      </div>
    {/if}

    {#if (enterIcon || enterText) && !(disabled || lock)}
      <div class={styles.enter}>
        <Button
          click={handlerIconClick}
          icon={enterIcon}
          style={"primary-text small"}
        >
          {enterText ? enterText : ""}
        </Button>
      </div>
    {/if}

    {#if lock}
      <img class={styles.lock} src={iconPath("grey_lock.svg")} alt="lock" />
    {/if}
  </span>
</InputWrapper>

<style lang="scss">
  input,
  textarea {
    box-sizing: border-box;
  }

  .text-input {
    display: flex;
    position: relative;
    flex: 1;
  }

  .icon {
    position: absolute;
    top: 11px;
    left: 15px;
    z-index: 1;
  }

  .small {
    .icon {
      top: 6px;
      left: 5px;
    }
    input {
      height: 30px;
      padding: 0 8px;
    }
    textarea {
      padding: 12px;
      padding: 8px;
    }
    input,
    textarea {
      font-size: 14px;
    }
  }

  .medium {
    .icon {
      top: 16px;
      left: 15px;
    }
    input {
      height: 40px;
      padding: 0 12px;
    }
    textarea {
      padding: 12px;
    }
    input,
    textarea {
      font-size: 14px;
    }
  }

  .big {
    .icon {
      top: 16px;
      left: 15px;
    }
    input {
      height: 50px;
    }
    input,
    textarea {
      font-size: 16px;
    }
  }

  .with-icon {
    input {
      padding-left: 45px;
    }

    &.small {
      input {
        padding-left: 35px;
      }
    }
  }

  .no-border {
    input {
      border: 0;
    }
  }

  .round-border {
    input,
    textarea {
      width: 100%;
      text-align: left;
      background: #fff;
      color: var(--primary-500);
      border-radius: var(--border-radius);
      border: solid 1px var(--primary-050);
      outline: none;

      .disabled & {
        background: var(--primary-010);
      }

      &:hover,
      &:focus,
      &:active {
        &:not([disabled]) {
          border: solid 1px var(--blue);
        }
      }
    }
  }

  .dropdown-border {
    input,
    textarea {
      background: #fff;
      border: solid 1px var(--primary-050);
      outline: none;

      &:hover {
        &:not([disabled]) {
          border: solid 1px var(--blue);
        }
      }

      &:focus,
      &:active {
        &:not([disabled]) {
          border: solid 1px var(--blue);
          outline: 3px solid var(--blue-010);
        }
      }
    }
  }

  .no-border-sides {
    input {
      border-right: 0;
      border-left: 0;
    }
  }

  .left-side {
    input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .right-side {
    input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .disabled {
    input,
    textarea {
      background: var(--primary-010);
    }
  }

  .fullsize {
    input,
    textarea {
      width: 100%;
      box-sizing: border-box;
    }
  }

  .invalid {
    input,
    textarea {
      border: solid 1px var(--red-100);
    }
  }

  .lock,
  .count,
  .enter {
    display: flex;
    align-items: center;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
  }

  .disabled {
    .count {
      color: var(--primary-300);
    }
  }

  .button {
    display: flex;
    align-items: center;
    min-width: 34px;
  }

  input {
    &::-webkit-input-placeholder {
      color: var(--primary-300);
    }

    &::-moz-placeholder {
      color: var(--primary-300);
    }

    &:-ms-input-placeholder {
      color: var(--primary-300);
    }

    &:-moz-placeholder {
      color: var(--primary-300);
    }

    &:focus {
      &::-webkit-input-placeholder {
        color: var(--primary-100);
      }

      &::-moz-placeholder {
        color: var(--primary-100);
      }

      &:-ms-input-placeholder {
        color: var(--primary-100);
      }

      &:-moz-placeholder {
        color: var(--primary-100);
      }
    }
  }

  textarea {
    resize: none;
  }

  .password-icon {
    top: 12px;
    right: 18px;
    position: absolute;
    z-index: 1;
  }

  .error {
    color: var(--red-100);
  }

  .text-center {
    input {
      text-align: center;
    }
  }
  .help {
    color: var(--primary-060, #667b9e);
    font-size: 0.75em;
    line-height: 1.125em;
    margin: 0.5em;
  }
</style>
