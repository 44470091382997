<script>
  import {
    sample,
    hasOpenPositions,
    showSampleEditModal,
    showSampleDeleteModal,
    showOpenPositionsModal,
  } from "apps/sample/stores";
  import {
    SampleEditModal,
    SampleOpenPositionsModal,
    DeleteModal,
  } from "components/modals";
  import { hasBrandSpace } from "stores/brand_space";
  import { t } from "stores/i18n.js";

  import { Button, Options } from "components";
  import styles from "styleguide/SampleDetailsCardActions.json";

  export let handleCreateSampleMailing;
  export let handleOpenPositionsModal;
  export let handleSampleEditModal;
  export let handleSampleDeleteModal;
  export let onSubmitSampleDelete;
</script>

<div class={styles.wrapper} data-component="SampleDetailsCardActions">
  <div class={styles.actions}>
    <Options
      style="acts-as-button-tonal no-border"
      title={$t("sample_details.card_actions.new_request")}
      isLeft={true}
    >
      <Button
        style="blue-text option"
        click={() => handleCreateSampleMailing("postal")}
      >
        {$t("sample_details.card_actions.send_via_postal")}
      </Button>
      <Button
        style="blue-text option"
        click={() => handleCreateSampleMailing("email")}
      >
        {$t("sample_details.card_actions.send_via_email")}
      </Button>
    </Options>

    {#if $hasOpenPositions && $hasBrandSpace("aude")}
      <Button style="primary-text" click={handleOpenPositionsModal}>
        {$t("sample_details.card_actions.open_positions")} ({$sample.open_positions_count})
      </Button>
    {/if}

    <Button
      disabled={$sample.contact.address_validated}
      click={handleSampleEditModal}
      style="primary-text"
    >
      {`${$t("sample_details.card_actions.edit_sample_type").replace("%{sample_type}", $sample.sample_type)}`}
    </Button>
  </div>

  <Button click={handleSampleDeleteModal} style="error-text">
    {$t("sample_details.card_actions.delete_item")}
  </Button>

  {#if $showOpenPositionsModal}
    <SampleOpenPositionsModal />
  {/if}

  {#if $showSampleEditModal}
    <SampleEditModal />
  {/if}

  {#if $showSampleDeleteModal}
    <DeleteModal
      bind:shown={$showSampleDeleteModal}
      title={$t("sample_details.card_actions.delete_title")}
      description={$t("sample_details.card_actions.delete_description")}
      btnTitle={$t("sample_details.card_actions.delete_item")}
      onDelete={onSubmitSampleDelete}
    />
  {/if}
</div>

<style lang="scss">
  .wrapper {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;

    .actions {
      display: flex;
      gap: 8px;
      align-items: center;
      justify-content: start;
    }
  }
</style>
