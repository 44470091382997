export const BRAND_SPACE = Object.freeze({
  aude: "aude",
  btde: "btde",
  ebde: "ebde",
  eyde: "eyde",
  gtgr: "gtgr",
  maat: "maat",
  made: "made",
  rpde: "rpde",
  rsde: "rsde",
  sode: "sode",
});
