<script>
  import { onMount } from "svelte";
  import { AuditTemplateDetailsFooter } from "apps/pbc/components/AuditTemplate/index.js";
  import { t } from "stores/i18n.js";
  import { toasts } from "stores/toasts.js";
  import AuditTemplateApi from "apis/pbc/audit_templates.js";
  import { Button, Tag, TextInput } from "components";
  import styles from "styleguide/AuditTemplateDetails.json";

  export let selectedTemplateItem;

  let editTitle = selectedTemplateItem?.name;
  let editDescription = selectedTemplateItem?.description;

  let isEdit = false;
  let isErrorEditName = false;
  let titleRef;

  let tags = [];
  if (selectedTemplateItem?.account) {
    tags.push($t("audit_template.account_templates"));
  }
  if (selectedTemplateItem?.favorite) {
    tags.push($t("audit_template.favorite_templates"));
  }
  if (selectedTemplateItem?.personal) {
    tags.push($t("audit_template.personal_templates"));
  }
  if (selectedTemplateItem?.standard) {
    tags.push($t("audit_template.standard_templates"));
  }
  if (selectedTemplateItem?.team) {
    tags.push($t("audit_template.team_templates"));
  }

  function onClickEditButton() {
    isEdit = true;
  }

  function onEdit() {
    isErrorEditName = false;
    if (editTitle) {
      let params = {
        id: selectedTemplateItem.permalink,
        name: editTitle,
        description: editDescription,
      };

      AuditTemplateApi.edit({
        params,
        success: onEditSuccess,
        error: onEditError,
      });
    } else {
      isErrorEditName = true;
    }
  }

  function onEditError() {
    isErrorEditName = true;
    editTitle = selectedTemplateItem?.name;
    editDescription = selectedTemplateItem?.description;
  }

  function onEditSuccess() {
    AuditTemplateApi.get();
    toasts.send({
      message: $t("audit_template.updated"),
      type: "success",
    });
    isEdit = false;
  }

  function onCancel() {
    isEdit = false;
    editTitle = selectedTemplateItem.name;
    editDescription = selectedTemplateItem.description;
  }

  onMount(() => {
    titleRef.scrollIntoView({
      behavior: "auto",
      block: "end",
    });
  });
</script>

<div data-component="AuditTemplateDetails" class={styles.details}>
  <div class={styles.item}>
    {#if isEdit}
      <div class={styles.inputs}>
        <TextInput
          label={$t("audit_template.template_title")}
          bind:value={editTitle}
          onEnter={onEdit}
          invalid={isErrorEditName}
          errors={isErrorEditName
            ? [$t("audit_template.template_title_error")]
            : []}
        />
        <div>
          <TextInput
            label={$t("audit_template.template_description_title")}
            textarea={true}
            optional={true}
            bind:value={editDescription}
            onEnter={onEdit}
          />
          <div class={styles["input-footnote"]}>
            {$t("audit_template.template_description")}
          </div>
        </div>
      </div>
      <div class={styles.buttons}>
        <Button click={onEdit} style={"primary"}>
          {$t("actions.save")}
        </Button>
        <Button click={onCancel} style={"error-text"}>
          {$t("actions.cancel")}
        </Button>
      </div>
    {:else}
      <div class={styles.title} bind:this={titleRef}>
        {selectedTemplateItem?.name}
      </div>
      {#if selectedTemplateItem?.description}
        <div class={styles.description}>
          {selectedTemplateItem?.description}
        </div>
      {/if}
      {#if selectedTemplateItem?.team}
        <div class={styles.meta}>
          {$t("audit_template.created_by")}
          {selectedTemplateItem?.created_by?.name}
        </div>
      {/if}
    {/if}
    <div class={styles.tags}>
      {#if selectedTemplateItem?.read_only}
        <Tag
          style={"semi-bold"}
          color={"grey"}
          label={$t("audit_template.read_only")}
          size="small"
        />
      {/if}
      {#each tags as tag}
        <Tag style={"semi-bold"} color={"grey"} label={tag} size="small" />
      {/each}
    </div>
    {#each selectedTemplateItem?.control_group_templates as { name, control_templates }}
      <div class={styles.list}>
        <div class={styles["list-title"]}>{name}</div>
        <ul class={styles["list-items"]}>
          {#each control_templates as item}
            <li class={styles["list-item"]}>{item.name}</li>
          {/each}
        </ul>
      </div>
    {/each}
  </div>
  <AuditTemplateDetailsFooter
    {onClickEditButton}
    {isErrorEditName}
    {selectedTemplateItem}
    {onEditSuccess}
    {onEditError}
  />
</div>

<style lang="scss">
  .details {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }
  .back {
    margin-bottom: 24px;
  }
  .item {
    margin-bottom: auto;
  }
  .tags {
    display: flex;
    gap: 8px;
    margin-bottom: 16px;
  }
  .inputs {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .input-footnote {
    @include typography-footnote-regular;
    color: var(--primary-300);
  }
  .buttons {
    display: flex;
    gap: 8px;
    margin-top: 16px;
    margin-bottom: 8px;
  }
  .title {
    @include typography-caption-semibold;
    color: var(--primary-500);
    margin: 8px 0;
  }
  .description {
    @include typography-body;
    color: var(--primary-500);
    margin-bottom: 8px;
  }

  .meta {
    @include typography-footnote-regular;
    color: var(--primary-300);
    margin-bottom: 16px;
  }
  .list {
    color: var(--primary-500);
    padding: 16px;
    border: 1px solid var(--primary-050);
    margin: 16px 0;
    border-radius: 8px;
  }
  .list-title {
    @include typography-body-semibold;
    margin-bottom: 8px;
  }

  .list-items {
    list-style-type: disc;
    padding-left: 24px;
  }

  .list-item {
    @include typography-body-regular;
    margin-bottom: 4px;
  }
</style>
