import { t } from "stores/i18n.js";
import { toasts } from "stores/toasts.js";
import { get } from "svelte/store";

class Toasts {
  constructor() {}

  #sendMessage(messageKey, type) {
    toasts.send({
      message: get(t)(messageKey),
      type,
    });
  }

  sendError(messageKey) {
    this.#sendMessage(messageKey, "error");
  }

  sendSuccess(messageKey) {
    this.#sendMessage(messageKey, "success");
  }

  sendWarning(messageKey) {
    this.#sendMessage(messageKey, "warning");
  }
}

export default new Toasts();
