<script>
  import { onMount } from "svelte";

  import { t } from "stores/i18n.js";
  import { selectedType } from "stores/review/review_new.js";
  import { Radio } from "components";
  import styles from "styleguide/ReviewNewTypeItem.json";

  export let types;
  export let dirty;
  export let active;

  $: getSelected = (type) => {
    return $selectedType === type;
  };

  function toggleItemType(type) {
    dirty = false;
    selectedType.set(type);
  }

  onMount(() => {
    // TODO add const with all the possible options for selectedType
    selectedType.set("balance");
  });
</script>

<div class={styles.wrapper} data-component="ReviewNewTypeItem">
  {#each $types as type (type)}
    {#if active}
      <div class={styles["item"]}>
        <Radio
          checked={$selectedType === type}
          onToggle={toggleItemType}
          value={type}
          label={$t(`review_new_type_item.${type}.title`)}
        />

        {#if getSelected(type)}
          <div class={styles["item-info"]}>
            <div>{@html $t(`review_new_type_item.${type}.title_desc`)}</div>

            <div class={styles["bold"]}>
              {$t(`review_new_type_item.${type}.values_required`)}
            </div>
            <div>{$t(`review_new_type_item.${type}.values_desc`)}</div>

            {#if $t(`review_new_type_item.${type}.optional_values`)}
              <div class={styles["bold"]}>
                {$t(`review_new_type_item.${type}.optional_values`)}
              </div>
              <div>{$t(`review_new_type_item.${type}.optional_desc`)}</div>
            {/if}

            <div class={styles["bold"]}>
              {$t(`review_new_type_item.${type}.method_available`)}
            </div>
            <div>{$t(`review_new_type_item.${type}.method_desc`)}</div>
          </div>
        {/if}
      </div>
    {:else if getSelected(type)}
      <div>
        <div class={styles.subtitle}>
          {$t("confirmation_new_type.label")}
        </div>
        <div>{$t(`review_new_type_item.${type}.title`)}</div>
      </div>
    {/if}
  {/each}
</div>

<style lang="scss">
  .wrapper {
    min-height: 40px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .item {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &-info {
      margin-left: 28px;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .bold {
        font-weight: 500;
      }
    }
  }

  .subtitle {
    font-size: 12px;
    line-height: 18px;
    color: var(--primary-300);
    margin-top: 16px;
  }
</style>
