<script>
  import SpinnerIcon from "components/icons/SpinnerIcon.svelte";
  import { t } from "stores/i18n.js";
  import { audit } from "stores/pbc.js";
  import { can } from "stores/permission.js";
  import { toasts } from "stores/toasts.js";
  import AuditApi from "apis/pbc/audit.js";
  import AuditTemplateApi from "apis/pbc/audit_templates.js";
  import { Button, Options } from "components";
  import styles from "styleguide/AuditTemplateDetailsFooter.json";

  export let onClickEditButton;
  export let isErrorEditName;
  export let selectedTemplateItem;
  export let onEditSuccess;
  export let onEditError;
  export let onBackButtonClick;

  let isLoadingUseTemplates = false;

  function onOverwrite() {
    const isConfirmed = confirm($t("control_status.confirm_override"));
    if (isConfirmed) {
      AuditTemplateApi.overwrite({
        params: {
          id: selectedTemplateItem.permalink,
          name: selectedTemplateItem.name,
          description: selectedTemplateItem.description,
          overwrite: true,
          audit_id: $audit.permalink,
        },
        success: onEditSuccess,
        error: onEditError,
      });
    }
  }

  function onDelete() {
    const isConfirmed = confirm($t("control_status.confirm_deletion"));
    if (isConfirmed) {
      AuditTemplateApi.delete(selectedTemplateItem.permalink);
      onBackButtonClick();
    }
  }

  function onToggleFavorite() {
    isErrorEditName = false;

    AuditTemplateApi.toggleFavorite({
      params: {
        id: selectedTemplateItem.permalink,
        audit_id: $audit.permalink,
      },
      success: () => {
        onEditSuccess();
        onBackButtonClick();
      },
      error: () => {
        toasts.send({
          title: $t("audit_template.template_import_error"),
          type: "error",
        });
      },
    });
  }

  function onUseTemplate() {
    isErrorEditName = false;
    isLoadingUseTemplates = true;
    AuditTemplateApi.import({
      params: {
        id: selectedTemplateItem.permalink,
        audit_id: $audit.permalink,
      },
      success: () => {
        AuditApi.getAudits();
        isLoadingUseTemplates = false;
        onEditSuccess();
      },
      error: () => {
        toasts.send({
          title: $t("audit_template.template_import_error"),
          type: "error",
        });
        isLoadingUseTemplates = false;
      },
    });
  }

  function onTeamShare() {
    isErrorEditName = false;

    AuditTemplateApi.toggleTeamShare({
      params: { id: selectedTemplateItem.permalink },
      success: () => {
        onEditSuccess();
        onBackButtonClick();
      },
      error: () => {
        toasts.send({
          title: $t("audit_template.template_import_error"),
          type: "error",
        });
      },
    });
  }

  function onAccountShare() {
    isErrorEditName = false;

    AuditTemplateApi.toggleAccountShare({
      params: { id: selectedTemplateItem.permalink },
      success: () => {
        onEditSuccess();
        onBackButtonClick();
      },
      error: () => {
        toasts.send({
          title: $t("audit_template.template_import_error"),
          type: "error",
        });
      },
    });
  }
</script>

<div class={styles.footer}>
  {#if selectedTemplateItem?.read_only}
    {#if selectedTemplateItem?.team && $can("manage_on_account_level", "audit_template")}
      <Button style={"primary-text"} click={onAccountShare}>
        {$t("audit_template.share_with_account")}
      </Button>
    {/if}
    {#if selectedTemplateItem?.favorite}
      <Button style={"primary-text"} click={onToggleFavorite}>
        {$t("audit_template.remove_from_favorites")}
      </Button>
    {:else}
      <Button style={"primary-text"} click={onToggleFavorite}>
        {$t("audit_template.mark_as_favorite")}
      </Button>
    {/if}
  {:else}
    <Options
      style={"no-border acts-as-button top right full-height"}
      title={$t("actions.options")}
    >
      <Button style={"blue-text option"} click={onClickEditButton}>
        {$t("audit_template.edit")}
      </Button>
      <Button style={"blue-text option"} click={onOverwrite}>
        {$t("audit_template.override")}
      </Button>
      {#if selectedTemplateItem?.favorite}
        <Button style={"blue-text option"} click={onToggleFavorite}>
          {$t("audit_template.remove_from_favorites")}
        </Button>
      {:else}
        <Button style={"blue-text option"} click={onToggleFavorite}>
          {$t("audit_template.mark_as_favorite")}
        </Button>
      {/if}
      {#if selectedTemplateItem?.account}
        <Button style={"blue-text option"} click={onAccountShare}>
          {$t("audit_template.remove_from_account")}
        </Button>
      {:else}
        {#if selectedTemplateItem?.team}
          <Button style={"blue-text option"} click={onTeamShare}>
            {$t("audit_template.remove_from_team")}
          </Button>
        {:else}
          <Button style={"blue-text option"} click={onTeamShare}>
            {$t("audit_template.share_with_team")}
          </Button>
        {/if}
        <Button style={"blue-text option"} click={onAccountShare}>
          {$t("audit_template.share_with_account")}
        </Button>
      {/if}
      <Button style={"error-text option"} click={onDelete}>
        {$t("audit_template.delete")}
      </Button>
    </Options>
  {/if}
  {#if $can("import", "audit_template")}
    <Button
      style={"primary"}
      click={onUseTemplate}
      loading={isLoadingUseTemplates}
    >
      {#if isLoadingUseTemplates}
        <div class={styles.loader}>
          <SpinnerIcon height="18px" width="18px" />
        </div>
      {/if}
      {$t("audit_template.use_template")}
    </Button>
  {/if}
</div>

<style lang="scss">
  .footer {
    display: flex;
    padding: 16px 24px;
    margin: 0 -24px -16px;
    gap: 8px;
    position: sticky;
    bottom: 0;
    background: #ffffff;
    justify-content: end;
    border-top: 1px solid var(--primary-050);
    border-radius: 0 0 8px 0;
    align-items: center;
  }
  .loader {
    cursor: not-allowed;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--brand-100);
    border-radius: 8px;
  }
</style>
