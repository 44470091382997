<script>
  import { onMount } from "svelte";
  import {
    ConfirmationSignerEmpty,
    ConfirmationSignerIntro,
    ConfirmationSignerSignature,
    ConfirmationSignerSuccess,
  } from "apps/confirmationSigner";
  import {
    step,
    token,
    mailings,
    documentsNumber,
    signer,
    client,
    footerLinks,
  } from "stores/confirmation/confirmation_signer.js";

  import { App, FrontendWrapper } from "components";

  export let appConfig;
  export let initialState;

  const themes = {
    empty: ConfirmationSignerEmpty,
    intro: ConfirmationSignerIntro,
    signature: ConfirmationSignerSignature,
    success: ConfirmationSignerSuccess,
  };

  onMount(async () => {
    token.set(initialState.token);
    documentsNumber.set(initialState.documents_num);
    signer.set(initialState.signer);
    client.set(initialState.client);
    footerLinks.set(initialState.footer_links);

    if (initialState.mailings.length) {
      mailings.set(initialState.mailings);
    } else {
      step.set("empty");
    }
  });
</script>

<App {...appConfig} container={true}>
  <div>
    <FrontendWrapper {footerLinks} showLoginBtn={false} size="full">
      <svelte:component this={themes[$step]} />
    </FrontendWrapper>
  </div>
</App>
