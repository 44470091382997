<script>
  import SpinnerIcon from "components/icons/SpinnerIcon.svelte";
  import { Modal } from "components/modals";
  import { t } from "stores/i18n.js";
  import { activeTemplate, audit } from "stores/pbc.js";
  import { toasts } from "stores/toasts.js";
  import AuditTemplateApi from "apis/pbc/audit_templates.js";
  import { Button, TextInput } from "components";
  import styles from "styleguide/AuditTemplateNew.json";

  let name = "";
  let description = "";
  let errorName = false;
  let isLoading = false;

  function resetValues() {
    name = "";
    description = "";
  }

  function onCreateSuccess() {
    AuditTemplateApi.get();
    name = "";
    description = "";
    activeTemplate.set("manage");
    isLoading = false;
    toasts.send({
      message: $t("audit_template.updated"),
      type: "success",
    });
  }

  function onCreateError() {
    errorName = true;
    isLoading = false;
  }

  function onCreate() {
    errorName = false;
    if (name) {
      isLoading = true;
      let params = {
        name: name,
        description: description,
      };
      params.audit_id = $audit.permalink;

      AuditTemplateApi.create({
        params,
        success: onCreateSuccess,
        error: onCreateError,
      });

      errorName = false;
      resetValues();
    } else {
      errorName = true;
    }
  }

  const onHide = function () {
    activeTemplate.set(null);
  };
</script>

{#if $activeTemplate === "create"}
  <Modal title={$t("audit_template.title_create")} shown={true} {onHide}>
    <div class={styles.inputs}>
      <TextInput
        label={$t("audit_template.template_title")}
        bind:value={name}
        onEnter={onCreate}
        invalid={errorName}
        errors={errorName ? [$t("audit_template.template_title_error")] : []}
      />
      <div>
        <TextInput
          label={$t("audit_template.template_description_title")}
          textarea={true}
          optional={true}
          bind:value={description}
          onEnter={onCreate}
        />
        <div class={styles["input-footnote"]}>
          {$t("audit_template.template_description")}
        </div>
      </div>
    </div>
    <svelte:fragment slot="bottom">
      <div class={styles.button}>
        <Button click={onCreate} style={"primary"} loading={isLoading}>
          {#if isLoading}
            <div class={styles.loader}>
              <SpinnerIcon height="18px" width="18px" />
            </div>
          {/if}
          {$t("actions.save")}
        </Button>
      </div>
    </svelte:fragment>
  </Modal>
{/if}

<style lang="scss">
  .inputs {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .input-footnote {
    @include typography-footnote-regular;
    color: var(--primary-300);
  }

  .button {
    text-align: right;
  }

  .loader {
    cursor: not-allowed;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--brand-100);
    border-radius: 8px;
  }
</style>
