<script>
  import { onMount } from "svelte";
  import {
    AuditTemplateDetails,
    AuditTemplateList,
    AuditTemplateNew,
    AuditTemplateSidebar,
  } from "apps/pbc/components/AuditTemplate/components/index.js";
  import { Modal } from "components/modals";
  import { isAccountFeature } from "stores/account.js";
  import { t } from "stores/i18n.js";
  import { auditTemplates, activeTemplate } from "stores/pbc.js";
  import { get } from "svelte/store";
  import { Button, Boundary, TextInput } from "components";

  import styles from "styleguide/AuditTemplate.json";

  let queryFilter = "";
  let activeFilter = "all";
  let filteredTemplates = [];
  let selectedTemplate;
  let selectedCategory;
  let scrollContainerRef;

  $: favoritesFilters = ["favorite_templates"];
  $: customFilters = [
    "personal_templates",
    "team_templates",
    "account_templates",
  ];
  $: systemFilters = setStandardTemplates($auditTemplates);

  onMount(() => {
    if ($activeTemplate !== "overwrite") {
      if ($auditTemplates?.account_templates?.length > 0) {
        customFilters.push("account_templates");
      }
    }
  });

  function setStandardTemplates(templates) {
    if ($isAccountFeature("audit_default_templates")) {
      const langTemplates = Object.keys(templates || {}).filter((key) =>
        key.includes("standard_templates"),
      );
      return [...langTemplates];
    }
    return [];
  }

  function filterByString(data, filterString) {
    if (!filterString) {
      return data;
    }
    let result;
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        let filtered = data[key].filter((template) =>
          template.name.toLowerCase().includes(filterString.toLowerCase()),
        );
        result = {
          ...result,
          [key]: filtered,
        };
      }
    }
    return result;
  }

  function handleScrollToFilterItem(targetId) {
    const el = document.getElementById(targetId);
    if (el) {
      el.scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    }
    activeFilter = targetId;
  }

  function onFilterChange(filter) {
    if (selectedCategory) {
      selectedTemplate = null;
      setTimeout(() => {
        handleScrollToFilterItem(filter);
      }, 100);
      return;
    }
    handleScrollToFilterItem(filter);
  }

  const onBackButtonClick = () => {
    const tempSelectedCategory = selectedCategory;
    selectedTemplate = null;
    selectedCategory = null;
    setTimeout(() => {
      onFilterChange(tempSelectedCategory);
    }, 150);
  };

  $: selectedTemplateItem =
    !!selectedCategory &&
    !!$auditTemplates &&
    $auditTemplates[selectedCategory].find(
      (el) =>
        !!selectedTemplate?.permalink &&
        el?.permalink === selectedTemplate?.permalink,
    );

  $: filteredTemplates = filterByString($auditTemplates, queryFilter);

  const onClickDetails = (template, category) => {
    selectedTemplate = template;
    selectedCategory = category;
    activeFilter = null;
    setTimeout(() => {
      scrollContainerRef.scrollTo({
        top: 100,
        left: 100,
        behavior: "smooth",
      });
    }, 150);
  };

  const onHide = function () {
    selectedTemplate = null;
    activeTemplate.set(null);
  };
</script>

<Boundary>
  {#if $activeTemplate}
    <AuditTemplateNew />

    {#if $activeTemplate === "manage" || $activeTemplate === "overwrite"}
      <Modal
        isWide
        isFullHeight
        isScrollingInsideModal
        shown={true}
        {onHide}
        style={"no-padding"}
      >
        <div data-component="AuditTemplate" class={styles.header} slot="header">
          <div class={styles["sidebar-title"]}>
            {$t("audit_template.templates")}
          </div>
          {#if selectedTemplate}
            <Button click={onBackButtonClick} style={"primary-text small"}>
              {$t(`audit_template.back_button_text`)}
            </Button>
          {:else}
            <div class={styles["search"]}>
              <TextInput
                placeholder={$t("audit_template.search")}
                bind:value={queryFilter}
                icon="search"
                style={"no-border"}
              />
            </div>
          {/if}
        </div>
        <div class={styles.sidebar} slot="sidebar">
          <AuditTemplateSidebar
            {favoritesFilters}
            {customFilters}
            {systemFilters}
            bind:activeFilter
            {onFilterChange}
          />
        </div>
        <div class={styles.content} bind:this={scrollContainerRef}>
          {#if selectedTemplateItem}
            <AuditTemplateDetails {selectedTemplateItem} {onBackButtonClick} />
          {:else if filteredTemplates}
            {#each [...favoritesFilters, ...customFilters, ...systemFilters] as filter}
              {#if !!filteredTemplates[filter]?.length}
                <AuditTemplateList
                  {onClickDetails}
                  filteredTemplates={filteredTemplates[filter]}
                  title={filter}
                />
              {/if}
            {/each}
          {/if}
        </div>
      </Modal>
    {/if}
  {/if}
</Boundary>

<style lang="scss">
  .header {
    display: flex;
    min-height: 42px;
    align-items: center;
  }
  .search {
    border: 1px solid var(--primary-050);
    border-radius: 8px;
    width: 470px;
    max-width: calc(100% - 220px);
  }
  .sidebar-title {
    margin: auto;
    width: 220px;
    @include typography-headline-semibold;
  }
  .content {
    padding: 16px 24px;
    height: 100%;
  }
</style>
