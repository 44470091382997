import { t } from "stores/i18n";
import { get } from "svelte/store";

export const getValidators = ({ password, rules, isPasswordMatch }) => {
  const numbers = password.match(/\d/g) || [];
  const lowerCases = password.match(/[a-z]/g) || [];
  const upperCases = password.match(/[A-Z]/g) || [];
  const specialChars = password.match(/[$-/:-?{-~!"^_`\[\]]/g) || [];

  const getName = (name) =>
    get(t)(`reset_password_form.password_criteria_${name}`, {
      postProcess: "interval",
      count: rules[`min_${name}`],
    });

  return [
    {
      value: "length",
      condition: password.length >= rules.min_length,
      name: getName("length"),
    },
    {
      value: "numeric",
      condition: numbers.length >= rules.min_numeric,
      name: getName("numeric"),
    },
    {
      value: "lowercase",
      condition: lowerCases.length >= rules.min_lowercase,
      name: getName("lowercase"),
    },
    {
      value: "uppercase",
      condition: upperCases.length >= rules.min_uppercase,
      name: getName("uppercase"),
    },
    {
      value: "special",
      condition: specialChars.length >= rules.min_special,
      name: getName("special"),
    },
    {
      value: "match",
      condition: isPasswordMatch,
      name: getName("match"),
    },
  ];
};
