import { t } from "stores/i18n.js";
import { teamUserCreateableRoles } from "stores/team_user.js";
import { toasts } from "stores/toasts.js";
import { get } from "svelte/store";
import Api from "apis/api";

class TeamUsersApi {
  constructor() {
    this.endpoint = "/team_users";
  }

  search(options) {
    Api.get(`${this.endpoint}/search`, options);
  }

  settings(options) {
    Api.patch(`${this.endpoint}/settings`, options);
  }

  deactivateNotifications(options) {
    Api.patch(`${this.endpoint}/deactivate_notifications`, options);
  }

  getCreateableAdminRoles(teamUser) {
    Api.get(`${this.endpoint}/${teamUser.permalink}/createable`, {
      success: (response) => {
        teamUserCreateableRoles.set(response);
      },
      error: this.error,
    });
  }

  error() {
    toasts.send({ title: get(t)("errors.unknown"), type: "error" });
  }
}

export default new TeamUsersApi();
