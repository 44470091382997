<script>
  import { onMount } from "svelte";

  import { DefaultSettingsListItem } from "apps/administration/components";
  import {
    currentAdminPage,
    defaultSettingsStore,
  } from "apps/administration/stores";
  import { isAccountFeature } from "stores/account";
  import { breadcrumb } from "stores/breadcrumb.js";
  import { t } from "stores/i18n.js";
  import { updatePageHistory } from "stores/page_history.js";
  import { can } from "stores/permission.js";

  import AccountApi from "apis/admin/accounts.js";
  import { userPath } from "lib/helpers.js";

  import { LegacyButton as Button, Toast } from "components";
  import styles from "styleguide/AdministrationDefaultSettings.json";

  const { project_allowed, project_default } = defaultSettingsStore;
  let isDisabled = false;

  let projectItems = [
    {
      uid: "public",
      title: "public_title",
      popover_subtitle: "public_popover_subtitle",
      allowed: true,
      default: true,
    },
    {
      uid: "private",
      title: "private_title",
      popover_subtitle: "private_popover_subtitle",
      allowed: true,
      default: false,
    },
    {
      uid: "confidential",
      title: "confidential_title",
      popover_subtitle: "confidential_popover_subtitle",
      allowed: true,
      default: false,
    },
  ];

  onMount(async () => {
    if (
      !$isAccountFeature("admin_default_settings") ||
      !$can("manage_default_settings", "account")
    ) {
      window.history.back();
    }

    breadcrumb.set([
      {
        translation: "administration",
        click: () => {
          currentAdminPage.set("dashboard");
          window.history.pushState("", userPath + "/account/");
        },
      },
      { translation: "default_settings" },
    ]);

    updatePageHistory([
      {
        path: userPath + "/account/default_settings",
        titles: [
          { translation: "administration" },
          { translation: "default_settings" },
        ],
      },
    ]);

    setProjectItems();
  });

  function changeAllowed(item) {
    isDisabled = true;

    if (projectItems.some((e) => e.allowed === true)) {
      isDisabled = false;
    }
  }

  function setDefault(item_uid) {
    projectItems.forEach((projectItem) => {
      if (projectItem.uid == item_uid) {
        projectItem.default = true;
        projectItem.allowed = true;
      } else {
        projectItem.default = false;
      }
    });

    projectItems = projectItems;
  }

  function setProjectItems() {
    projectItems.forEach((projectItem) => {
      projectItem.allowed = $project_allowed.includes(projectItem.uid);
      projectItem.default = $project_default == projectItem.uid;
    });

    projectItems = projectItems;
  }

  function updateProjectsSettings() {
    let params = { allowed_accesses: [], default_access: null };

    projectItems.forEach((projectItem) => {
      if (projectItem.default) {
        params.default_access = projectItem.uid;
      }

      if (projectItem.allowed) {
        params.allowed_accesses.push(projectItem.uid);
      }
    });

    params.allowed_accesses = params.allowed_accesses.join(", ");

    AccountApi.updateProjectsSettings(params);
  }
</script>

<div class={styles["content"]}>
  <div class={styles["section"]}>
    <div class={styles["section-header"]}>
      {$t("administration_default_settings.header")}
    </div>
    <p>
      {$t("administration_default_settings.description")}
    </p>
    <Toast message={$t("administration_default_settings.key_description")} />
  </div>

  <DefaultSettingsListItem
    isHeader={true}
    HeaderTitle={"project_type"}
    items={projectItems}
    {changeAllowed}
    {setDefault}
  />

  <div class={styles["save-button"]}>
    <Button
      click={updateProjectsSettings}
      style="new-settings-primary"
      disabled={isDisabled}>{$t("administration_default_settings.save")}</Button
    >
  </div>
</div>

<style scoped lang="scss">
  .content {
    width: 679px;
    padding: 24px;
    padding-top: 0px;
    background: var(--white);
    box-shadow:
      0px 6px 8px -6px rgba(24, 39, 75, 0.12),
      0px 8px 16px -6px rgba(24, 39, 75, 0.08);
    border-radius: 8px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
  }

  .section {
    padding: 24px 0px;

    &-header {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: var(--primary-500);
      padding-bottom: 8px;
    }
  }

  .save-button {
    padding: 24px 0 0px 0;
  }

  p {
    margin-top: 0;
  }
</style>
