<script>
  import { getContext } from "svelte";
  import { account } from "stores/account.js";
  import { hasBrandSpace } from "stores/brand_space";
  import { t } from "stores/i18n.js";
  import { changeLanguage } from "stores/i18n.js";
  import { user } from "stores/user.js";

  import { userPath, csrfToken } from "lib/helpers.js";
  import { Boundary, Box, Button } from "components";
  import styles from "styleguide/DashboardClientSidebar.json";

  const { language } = getContext("config");

  $: activeLanguage = language;

  let userPermalink;

  user.subscribe((value) => {
    userPermalink = value.permalink;
  });

  function setLanguage(newLanguage) {
    fetch(
      `${userPath}/client_users/${userPermalink}/change_locale?language=${newLanguage}`,
      {
        method: "POST",
        headers: {
          "X-CSRF-Token": csrfToken(),
        },
      },
    ).then(() => {
      changeLanguage(newLanguage);
      activeLanguage = newLanguage;
    });
  }

  function signOut() {
    fetch(`${userPath}/logins/sign_out`, {
      method: "DELETE",
      headers: {
        "X-CSRF-Token": csrfToken(),
      },
    }).then(() => {
      window.location = "/login";
    });
  }
</script>

<Boundary>
  <div data-component="DashboardClientSidebar">
    <div class={styles.item}>
      <Box>
        <div class={styles.container}>
          <div class={styles.note}>
            {$t("dashboard_client_sidebar.logged_in_as")}
          </div>
          <div class={styles.email}>{$user.email}</div>
          <div class={styles.controls}>
            <div class={styles["controls-gap"]}>
              <Button style={"error-text small"} click={signOut}
                >{$t("dashboard_client_sidebar.logout")}</Button
              >
              {#each $account.languages as language}
                {#if language !== activeLanguage}
                  <Button
                    style={"primary-text small"}
                    click={setLanguage.bind(this, language)}
                    >{$t(`dashboard_client_sidebar.switch_${language}`)}</Button
                  >
                {/if}
              {/each}
            </div>
          </div>
        </div>

        {#if $hasBrandSpace("eyde")}
          <div class={styles.container}>
            <div class={styles.note}>
              {$t("dashboard_client_sidebar.help_and_contact")}
            </div>
            <div class="">confirmations@de.ey.com</div>
            <div class="">+49 6196 996 11111</div>
          </div>
        {/if}

        {#if $user.verifyEnabled}
          <div class={styles.container}>
            <div class={styles.text}>
              {@html $t(
                "dashboard_client_sidebar.two_factor_authentication_disable",
              )}
            </div>
          </div>
          <div class={styles.controls}>
            <Button
              style={"blue-text sidebar-action"}
              link={`${userPath}/2fa-verification/new`}
              >{$t("dashboard_client_sidebar.disable")}</Button
            >
          </div>
        {:else}
          <div class={styles.container}>
            <div class={styles.text}>
              {@html $t(
                "dashboard_client_sidebar.two_factor_authentication_enable",
              )}
            </div>
          </div>
          <div class={styles.controls}>
            <Button
              style={"blue-text sidebar-action"}
              link={`${userPath}/2fa-verification/new`}
              >{$t("dashboard_client_sidebar.enable")}</Button
            >
          </div>
        {/if}
      </Box>
    </div>

    {#if $hasBrandSpace("aude")}
      <div class={styles.item}>
        <Box>
          <div class={styles.container}>
            <div class={styles.info}>
              <div class={styles.title}>
                {$t("dashboard_client_sidebar.technical_help_title")}
              </div>
              <div class={styles.text}>
                {$t("dashboard_client_sidebar.technical_help_description")}
              </div>
              <div class={styles.description}>
                <div>support@auditi.de</div>
                <div>
                  {$t("dashboard_client_sidebar.technical_help_via_phone")}
                </div>
                <div>+49 211 / 240 922 90</div>
                <div>
                  {$t("dashboard_client_sidebar.technical_help_keyword")}
                </div>
              </div>
            </div>
          </div>
        </Box>
      </div>
    {/if}
  </div>
</Boundary>

<style lang="scss">
  .item {
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .note {
    color: var(--primary-300);
  }

  .container {
    padding: 15px 18px;

    &:not(:first-child) {
      border-top: 1px solid var(--primary-050);
    }
  }

  .controls {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
    &-gap {
      display: flex;
      gap: 8px;
    }
  }

  .title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 15px;
    &-no-margin {
      margin-bottom: 0;
    }
  }

  .button-wrapper {
    margin: 0 10px 10px 0;

    &:last-child {
      margin: 0;
    }
  }

  .description {
    margin-top: 15px;
  }

  .info {
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }
</style>
