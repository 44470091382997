import Api from "apis/api";
import { buildFormData } from "lib/helpers.js";

class WebhooksApi {
  constructor() {
    this.endpoint = "/webhook_subscriptions";
  }

  getSubscriptions(options) {
    const { success, error } = options;

    Api.get(`${this.endpoint}`, {
      success,
      error: (err) => {
        if (error) {
          error();
        } else {
          console.error("Error", err);
        }
      },
    });
  }

  addSubscription(options) {
    const { params, success, error } = options;

    const formData = new FormData();
    buildFormData(formData, params, null, true);

    Api.post(`${this.endpoint}`, {
      body: formData,
      success,
      error: (err) => {
        if (error) {
          error();
        } else {
          console.error("Error", err);
        }
      },
    });
  }

  editSubscription(options) {
    const { params, success, error } = options;

    const { permalink, ...payload } = params;

    const formData = new FormData();
    buildFormData(formData, payload, null, true);

    Api.patch(`${this.endpoint}/${permalink}`, {
      body: formData,
      success,
      error: (err) => {
        if (error) {
          error();
        } else {
          console.error("Error", err);
        }
      },
    });
  }

  deleteSubscription(options) {
    const { params, success, error } = options;

    Api.delete(this.endpoint + `/${params.id}`, {
      success,
      error: (err) => {
        if (error) {
          error();
        } else {
          console.error("Error", err);
        }
      },
    });
  }

  getEvents(options) {
    const { success, error } = options;

    Api.get(`${this.endpoint}/events`, {
      success,
      error: (err) => {
        if (error) {
          error();
        } else {
          console.error("Error", err);
        }
      },
    });
  }

  static instance;

  static getInstance() {
    if (!WebhooksApi.instance) {
      WebhooksApi.instance = new WebhooksApi();
    }
    return WebhooksApi.instance;
  }
}

export const webhooksApi = WebhooksApi.getInstance();
