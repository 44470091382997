<script>
  import { SampleService } from "apps/sample/services";
  import {
    sample,
    showOpenPositionsModal,
    openPositionsItems,
  } from "apps/sample/stores";
  import { Modal } from "components/modals";
  import { account, isAccountFeature } from "stores/account.js";
  import { currentLanguage, t } from "stores/i18n.js";

  import { platformLink } from "lib/helpers.js";
  import { Button } from "components";
  import styles from "styleguide/SampleOpenPositionsModal.json";

  function onHide() {
    SampleService.resetOpenPositionsModal();
  }
</script>

<Modal
  shown={$showOpenPositionsModal}
  {onHide}
  title={$t("sample_open_positions_modal.title")}
>
  <div class={styles.wrapper} data-component="SampleOpenPositionsModal">
    {#if $sample.open_positions_count}
      <div>{$t("sample_open_positions_modal.description_items")}</div>

      <table>
        <thead>
          <tr>
            <th>{$t("sample_open_positions_modal.date_of_invoice")}</th>
            <th>{$t("sample_open_positions_modal.bill_number")}</th>
            <th>{$t("sample_open_positions_modal.invoice_amount")}</th>
          </tr>
        </thead>
        <tbody>
          {#each $openPositionsItems.items as position}
            <tr>
              <td>{position.item_date}</td>
              <td>{position.item_number}</td>
              <td>{position.item_value}</td>
            </tr>
          {/each}

          <tr>
            <td>{$t("sample_open_positions_modal.total")}</td>
            <td></td>
            <td>{$openPositionsItems.total_value}</td>
          </tr>
        </tbody>
      </table>
    {:else}
      <div class={styles.title}>
        {$t("sample_open_positions_modal.subtitle_empty")}
      </div>
      <div>{@html $t("sample_open_positions_modal.description_empty")}</div>
      {#if !$isAccountFeature("hide_documentation")}
        <div>
          <Button style={"primary-tonal"} link={platformLink("docs")}>
            {$t("sample_open_positions_modal.button")}
          </Button>
        </div>
      {/if}
    {/if}
  </div>
</Modal>

<style lang="scss">
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 25px;

    .title {
      font-weight: 500;
    }

    table {
      width: 100%;
    }

    th,
    td {
      &:last-child {
        text-align: right;
      }
    }

    th {
      &:last-child {
        text-align: right;
      }
    }
  }
</style>
