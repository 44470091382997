import { profileSettingsApi } from "apps/profileSettings/apis";
import { t } from "stores/i18n";
import { teamUser } from "stores/team_user.js";
import { toasts } from "stores/toasts";
import { get } from "svelte/store";

class ProfileSettingsService {
  constructor() {}

  getTeamUser({ params, onSuccess, onError } = {}) {
    profileSettingsApi.getTeamUser({
      params,
      success: (response) => {
        teamUser.set(response);
        onSuccess?.(response);
      },
      error: (error) => {
        toasts.send({
          title: get(t)("errors.unknown"),
          type: "error",
        });
        onError?.(error);
      },
    });
  }

  updateTeamUser({ params, onSuccess, onError } = {}) {
    profileSettingsApi.updateTeamUser({
      params,
      success: (res) => {
        teamUser.set(res?.data);

        toasts.send({
          title: get(t)("success.update_successful"),
          type: "success",
        });

        onSuccess?.(response);
      },
      error: (error) => {
        toasts.send({
          title: get(t)("errors.unknown"),
          type: "error",
        });
        onError?.(error);
      },
    });
  }

  changePassword({ params, onSuccess, onError } = {}) {
    profileSettingsApi.changePassword({
      params,
      success: (res) => {
        teamUser.set(res);
        onSuccess?.(res);
      },
      error: (err) => {
        if (err?.errors?.current_password) {
          toasts.send({
            message: get(t)("reset_password.current_password_error"),
            type: "error",
          });
          onError?.(err);
          return;
        }

        const firstError = err?.errors?.password?.[0];

        if (firstError?.error === "too_long") {
          toasts.send({
            message: get(t)("reset_password.long_password", {
              count: firstError?.count,
            }),
            type: "error",
          });
          onError?.(err);
          return;
        }

        if (firstError?.error === "taken_in_past") {
          toasts.send({
            message: get(t)("reset_password.repeat_password_error"),
            type: "error",
          });
          onError?.(err);
          return;
        }

        toasts.send({
          message: get(t)("errors.unknown"),
          type: "error",
        });
        onError?.(err);
      },
    });
  }

  removeAvatar({ params, onSuccess, onError } = {}) {
    profileSettingsApi.removeAvatar({
      params,
      success: (res) => {
        teamUser.set(res);
        toasts.send({
          title: get(t)("success.update_successful"),
          type: "success",
        });
        onSuccess?.(res);
      },
      error: (err) => {
        toasts.send({
          message: get(t)("errors.unknown"),
          type: "error",
        });
        onError?.(err);
      },
    });
  }

  static instance;

  static getInstance() {
    if (!ProfileSettingsService.instance) {
      ProfileSettingsService.instance = new ProfileSettingsService();
    }
    return ProfileSettingsService.instance;
  }
}

export const profileSettingsService = ProfileSettingsService.getInstance();
