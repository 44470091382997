<script>
  import { t } from "stores/i18n.js";
  import { Tag } from "components";

  export let label;
  export let status;
  export let close = null;
  export let size = "small";
  export let style = "semi-bold";

  function calculateStatusColor(newStatus) {
    if (newStatus == "imported") {
      return "blue";
    } else if (newStatus == "selected") {
      return "blue";
    } else if (newStatus == "waiting_confirmation") {
      return "yellow";
    } else if (newStatus == "waiting_companies") {
      return "yellow"
    } else if(newStatus == "waiting_for_signature") {
      return "yellow";
    } else if (newStatus == "waiting_signatures") {
      return "yellow";
    } else if (newStatus == "waiting_letterhead") {
      return "yellow";
    } else if (newStatus == "ready_to_send") {
      return "blue";
    } else if (newStatus == "sending_scheduled") {
      return "blue";
    } else if (newStatus == "sent") {
      return "grey";
    } else if (newStatus == "reminded") {
      return "green";
    } else if (newStatus == "received") {
      return "blue";
    } else if (newStatus == "accepted") {
      return "green";
    } else if (newStatus == "rejected") {
      return "red";
    } else if (newStatus == "action_required") {
      return "red";
    } else if (newStatus == "not_delivered") {
      return "red";
    } else {
      return "grey";
    }
  }

  $: statusColor = calculateStatusColor(status && status.toLowerCase());
</script>

<span data-component="ConfirmationStatus">
  <Tag
    {style}
    {close}
    label={label ? label : $t(`confirmation.status.${status}`)}
    color={statusColor}
    {size}
  />
</span>
