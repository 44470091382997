// the list is taken from
// https://www.notion.so/auditi/Account-Features-533c99be1f1542839f7052e10646af03

export const ACCOUNT_FEATURES = Object.freeze({
  mentions: "mentions",
  extendedUserRoles: "extended_user_roles",
  otherCreditors: "other_creditors",
  pdftron: "pdftron",
  auditDefaultTemplates: "audit_default_templates",
  pbcDelegation: "pbc_delegation",
  compliancePackage: "compliance_package",
  teams: "teams",
  showEnterpriseAddon: "show_enterprise_addon",
  showComplianceAddon: "show_compliance_addon",
  showWhitelabelAddon: "show_whitelabel_addon",
  adminDefaultSettings: "admin_default_settings",
  pbcReminders: "pbc_reminders",
  quickshare: "quickshare",
  pbcClientUserControls: "pbc_client_user_controls",
  pdftronAnnotations: "pdftron_annotations",
  pbc: "pbc",
  sba: "sba",
  xba: "xba",
  adminScim: "admin_scim",
  adminApi: "admin_api",
  adminStatistics: "admin_statistics",
  adminSso: "admin_sso",
  adminTheme: "admin_theme",
  xbaMailingAttachments: "xba_mailing_attachments",
  quickshareOutlookInfobox: "quickshare_outlook_infobox",
});
