<script>
  import { Box, FrontendFooter, FrontendHeader } from "components";
  import styles from "styleguide/FrontendWrapper.json";

  const SIZE = {
    full: "full",
    narrow: "narrow",
  };

  export let footerLinks;
  export let showLoginBtn;
  export let size = SIZE.narrow;
</script>

<div data-component="FrontendWrapper" class={styles.wrapper}>
  <div class={styles[`size-${size}`]}>
    <Box>
      <FrontendHeader {showLoginBtn} />
      <slot />
      <FrontendFooter {footerLinks} />
    </Box>
  </div>
</div>

<style lang="scss">
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    padding: 70px 5% 110px;
    background-color: var(--primary-010);
    box-sizing: border-box;
  }

  .size {
    &-narrow {
      width: 700px;
    }

    &-full {
      width: 870px;
      max-width: 100dvw;
    }
  }
</style>
