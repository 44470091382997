import { t } from "stores/i18n.js";
import { toasts } from "stores/toasts.js";
import { get } from "svelte/store";
import { serializeQuery } from "lib/helpers.js";

class Api {
  constructor() {
    const protocol = window.location.protocol;
    const host = window.location.host;
    const middleware = this.userMiddleware();

    this.queue = [];
    this.requesting = false;
    this.host = protocol + "//" + host + middleware + "/internal-api/v1";
    this.debug = false; // this.host.includes("localhost")
    this.log("API HOST", this.host);
    this.userCantChangeData = document.cookie.includes("shadow_mode");
  }

  saveFile(response, success, error) {
    response.blob().then((b) => {
      if (success && b?.type === "application/json") {
        success({ ...response, code: "success" });
        toasts.send({
          title: get(t)("success.batch_download"),
          type: "success",
        });
        return;
      }

      let filename = "download";
      let disposition = response.headers.get("content-disposition");
      if (disposition && disposition.indexOf("attachment") !== -1) {
        let filenameRegex = /filename\*=UTF-8''(.*)/gm;
        let matches = filenameRegex.exec(disposition);
        if (matches && matches[1]) {
          filename = decodeURIComponent(matches[1]);
        }
      }

      let a = document.createElement("a");
      a.href = URL.createObjectURL(b);
      a.setAttribute("download", filename);
      a.click();

      if (success) success(response);
    });
  }

  get(endpoint, options) {
    let params = new URLSearchParams(options.params);

    // TODO: remove question mark if no params
    this.queue.push({
      endpoint: endpoint + "?" + params,
      method: "GET",
      options: options,
    });

    return this.requestNext();
  }

  post(endpoint, options) {
    if (!this.canUserChangeData()) {
      return this.requestNext();
    }
    this.queue.push({
      endpoint: endpoint,
      method: "POST",
      options: options,
    });

    return this.requestNext();
  }

  put(endpoint, options) {
    if (!this.canUserChangeData()) {
      return this.requestNext();
    }
    this.queue.push({
      endpoint: endpoint,
      method: "PUT",
      options: options,
    });

    return this.requestNext();
  }

  patch(endpoint, options) {
    if (!this.canUserChangeData()) {
      return this.requestNext();
    }
    this.queue.push({
      endpoint: endpoint,
      method: "PATCH",
      options: options,
    });

    return this.requestNext();
  }

  delete(endpoint, options) {
    if (!this.canUserChangeData()) {
      return this.requestNext();
    }
    this.queue.push({
      endpoint: endpoint,
      method: "DELETE",
      options: options,
    });

    return this.requestNext();
  }

  download(endpoint, success, error) {
    if (success || error) {
      fetch(this.host + endpoint)
        .then((response) => {
          if (response?.status === 500) {
            if (success && success instanceof Function) {
              success(response);
            }

            return;
          }

          this.saveFile(response, success, error);
        })
        .catch((err) => {
          if (error) success(err);
        });
    } else {
      let href = this.host + endpoint;
      let a = document.createElement("a");
      a.href = href;
      a.click();
    }
  }

  downloadBatch(endpoint, options = {}) {
    const { success, error } = options;
    const params = new URLSearchParams(options.params);
    const requestEndpoint = this.host + endpoint + "?" + params;
    const requestParams = {
      method: "GET",
      options,
    };

    fetch(requestEndpoint, requestParams).then((response) => {
      if (!response.ok) {
        return response.text().then((text) => error(JSON.parse(text)));
      }
      if (response?.status === 500) {
        if (success && success instanceof Function) {
          success(response);
        }

        return;
      }

      return this.saveFile(response, success, error);
    });
  }

  downloadBatchAttachment(endpoint, options = {}) {
    const { body, success, error } = options;
    const callbacks = {
      success: success || function () {},
      error: error || function () {},
    };

    let controller = {};

    if (AbortController) {
      controller = new AbortController();
    }

    const fetchOptions = {
      method: "POST",
      headers: this.defaultHeaders(),
      signal: controller.signal,
      body,
    };

    fetch(this.host + endpoint, fetchOptions)
      .then((response) => {
        if (response?.status === 500) {
          if (success && success instanceof Function) {
            success(response);
          }

          return;
        }

        return this.saveFile(response, callbacks.success, callbacks.error);
      })
      .catch((e) => error(e));
  }

  requestNext() {
    if (this.queue.length === 0) {
      return;
    }
    const requestData = this.queue.shift();
    this.requesting = true;
    return this.request(
      requestData.endpoint,
      requestData.method,
      requestData.options,
    );
  }

  redirect(url) {
    window.location = url;
  }

  request(endpoint, method, options) {
    let { timeout = 15000 } = options;

    let callbacks = {
      success: options.success || function () {},
      error: options.error || function () {},
    };

    let controller = {};

    let abort = () => {
      if (controller.abort) controller.abort();
      this.requesting = false;
      this.requestNext();
    };

    if (AbortController) {
      controller = new AbortController();
    }

    let id = setTimeout(() => {
      abort();
      callbacks.error();
    }, timeout);

    let fetchOptions = {
      method: method,
      headers: { ...this.defaultHeaders(), ...options.headers },
      body: this.requestBody(options),
      signal: controller.signal,
      timeoutId: id,
      // credentials: "include"
    };

    let responseStatus;

    this.log(`Requesting ${endpoint} with ${JSON.stringify(fetchOptions)}`);

    let url;
    try {
      url = new URL(endpoint);
    } catch (_) {
      url = new URL(this.host + endpoint);
    }

    fetch(url, fetchOptions)
      .then((response) => {
        clearTimeout(fetchOptions.timeoutId);
        responseStatus = response.status;

        if (options.file) {
          return new Promise((resolve) => {
            resolve(response);
          });
        } else {
          return response.json();
        }
      })
      .then((response) => {
        switch (responseStatus) {
          case 200:
          case 201:
          case 304:
            this.log("success", response);
            callbacks.success(response);
            this.requesting = false;
            this.requestNext();
            break;
          case 400:
            this.log("error", responseStatus);
            callbacks.error(response);
            this.requesting = false;
            this.requestNext();
            break;
          case 401:
          case 422:
            this.log(response, response.error);
            callbacks.error(response);
            this.requesting = false;
            this.requestNext();
            break;
          case 500:
            this.log("server error", response);
            callbacks.error(response);
            this.requesting = false;
            this.requestNext();
            break;
          default:
            this.log("unhandled error", responseStatus);
            this.requesting = false;
            this.requestNext();
            break;
        }
      })
      .catch((err) => {
        callbacks.error(err);
      });

    return {
      abort,
    };
  }

  defaultHeaders() {
    return {
      "X-CSRF-Token": this.csrfToken(),
    };
  }

  requestBody(options) {
    if (options.body instanceof FormData) {
      return options.body;
    } else if (
      options.headers &&
      options.headers["Content-Type"] == "application/x-www-form-urlencoded"
    ) {
      return serializeQuery(options.body);
    } else {
      return JSON.stringify(options.body);
    }
  }

  userMiddleware() {
    const regex = /\/(u|c)\/\w{24}/g;
    const middleware = window.location.pathname.match(regex);
    if (middleware) {
      return middleware[0];
    } else {
      return "";
    }
  }

  csrfToken() {
    let metaToken = document.querySelector(`meta[name="csrf-token"]`);
    return metaToken ? metaToken.content : "";
  }

  log(...messages) {
    if (!this.debug) return;

    for (var message of messages) {
      console.log(message);
    }
  }

  canUserChangeData() {
    if (this.userCantChangeData) {
      toasts.send({
        title: get(t)("errors.action_prevented"),
        type: "error",
      });
      return false;
    }
    return true;
  }
}

export default new Api();
