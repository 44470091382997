import { writable, derived, get } from "svelte/store";

export const sample = writable({});
export const parentId = writable(null);
export const parentType = writable("");
export const openPositionsItems = writable([]);
export const showOpenPositionsModal = writable(false);
export const showSampleEditModal = writable(false);
export const showSampleDeleteModal = writable(false);
export const activities = writable([]);
export const comments = writable([]);
export const availableStatuses = writable({});

sample.subscribe((value) => {
  if (value.permalink) {
    parentId.set(value.permalink);
    parentType.set("sample");
  }
});

export const entries = derived([sample], ([$sample], set) => {
  let mailings = $sample.sample_mailings || [];
  let responses = $sample.sample_responses || [];

  let newEntries = [...mailings, ...responses].sort((a, b) => {
    return new Date(b.created_at) - new Date(a.created_at);
  });

  set(newEntries);
});

export const hasOpenPositions = derived(
  sample,
  ($sample) => $sample.open_positions_count !== undefined,
);

export const isCommentsVisible = derived(
  sample,
  ($sample) => $sample && $sample.status !== 0,
);

export function updateAddressValidated(isValidated) {
  sample.update((current) => ({
    ...current,
    contact: {
      ...current.contact,
      address_validated: isValidated,
    },
  }));
}

export function updateSampleMailings(newMailings) {
  sample.update((current) => ({
    ...current,
    sample_mailings: newMailings,
  }));
}

export function updateComments(comment) {
  comments.update((existingComments) => [comment, ...existingComments]);
}

export function updateMailings(newMailing) {
  sample.update((current) => {
    let existingMailing = false;

    const updatedMailings = current.sample_mailings.reduce((acc, mailing) => {
      if (mailing.permalink === newMailing.permalink) {
        acc.push({ ...mailing, ...newMailing });
        existingMailing = true;
      } else {
        acc.push(mailing);
      }
      return acc;
    }, []);

    if (!existingMailing) {
      updatedMailings.unshift(newMailing);
    }

    return {
      ...current,
      sample_mailings: updatedMailings,
    };
  });
}

export function initializeValues(fieldGroups) {
  const current = get(sample);

  return Object.entries(fieldGroups).reduce((acc, [group, fields]) => {
    acc[group] =
      group === "request_value"
        ? current[group]
        : fields.reduce((groupAcc, field) => {
            groupAcc[field] = current[group][field];
            return groupAcc;
          }, {});

    return acc;
  }, {});
}

export function getUpdatedValues(initialValues, currentValues, fieldGroups) {
  const updatedContacts = fieldGroups.contact.reduce((acc, field) => {
    if (currentValues.contact[field] !== initialValues.contact[field]) {
      acc[field] = currentValues.contact[field];
    }
    return acc;
  }, {});

  return {
    ...(Object.keys(updatedContacts).length > 0 && {
      contact_attributes: updatedContacts,
    }),
    ...(currentValues.request_value !== initialValues.request_value && {
      request_value: currentValues.request_value,
    }),
  };
}
