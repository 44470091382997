<script>
  import { onMount } from "svelte";

  import { webhooksService } from "apps/administration/modules/administrationApi/services";
  import clsx from "clsx";

  import { t } from "stores/i18n.js";

  import { Boundary, Box, Button, Spinner } from "components";
  import { WebhookForm, WebhooksList } from "./components";
  import { initSubscriptionValue } from "./constants";

  import styles from "styleguide/AdministrationWebhooks.json";

  let formData = null;
  let isLoading = true;

  onMount(() => {
    webhooksService.getSubscriptions({
      onSuccess: () => (isLoading = false),
      onError: () => (isLoading = false),
    });
  });
</script>

<Boundary>
  <Box>
    <div data-component="AdministrationWebhooks" class={styles.wrapper}>
      <div>
        <div class={styles.title}>
          {$t("administration_webhooks.header")}
        </div>

        <div class={styles.descr}>
          <div>
            {$t("administration_webhooks.description")}
          </div>

          <div class={clsx(formData && styles["hidden-action"])}>
            <Button
              style={"primary-tonal"}
              click={() => (formData = { ...initSubscriptionValue })}
              disabled={isLoading}
            >
              {$t("administration_webhooks.new_subscr")}
            </Button>
          </div>
        </div>
      </div>

      {#if isLoading}
        <Spinner scale="0.2" color="var(--primary-100)" />
      {:else if formData}
        <WebhookForm bind:formData />
      {:else}
        <WebhooksList bind:editableItem={formData} />
      {/if}
    </div>
  </Box>
</Boundary>

<style lang="scss">
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
  }

  .title {
    @include typography-caption-semibold;

    color: var(--primary-500);
  }

  .descr {
    @include typography-body-regular;

    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
  }

  .hidden-action {
    visibility: hidden;
  }
</style>
