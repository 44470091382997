<script>
  import clsx from "clsx";
  import { t } from "stores/i18n.js";
  import { isUserTeamUser } from "stores/user.js";
  import { Checkbox } from "components";
  import styles from "styleguide/ContactInfoCard.json";

  export let company;
  export let street;
  export let zip;
  export let city;
  export let country;
  export let email;
  export let address_validated;
  export let language;
  export let contact;
  export let validateAddress;
  export let reference;
  export let is_valid_email = true;

  $: isValidAddress = street && zip && city && country;

  function displayValue(value, placeholder) {
    return value || $t(`contact_info_card.${placeholder}`);
  }
</script>

<div data-component="ContactInfoCard">
  <div class={styles.contacts}>
    <div class={styles.title}>{$t("contact_info_card.company")}</div>
    <div class={styles.bold}>{company || reference}</div>
  </div>

  <div
    class={`${styles.contacts} ${address_validated ? styles.validated : ""}`}
  >
    <div class={styles.title}>{$t("contact_info_card.address")}</div>
    <div class={clsx(!street && styles.placeholder)}>
      {displayValue(street, "address")}
    </div>
    <div>
      <span class={clsx(!zip && styles.placeholder)}>
        {displayValue(zip, "zip_placeholder")}
      </span>
      <span class={clsx(!city && styles.placeholder)}>
        {displayValue(city, "city_placeholder")}
      </span>
    </div>
    <div class={clsx(!country && styles.placeholder)}>
      {displayValue(country, "country_placeholder")}
    </div>

    <div class={clsx(email && !is_valid_email && styles.invalid)}>
      {email || "-"}
    </div>

    {#if $isUserTeamUser}
      <div class={styles["contacts-validate"]}>
        <Checkbox
          toggle={validateAddress}
          bind:toggled={address_validated}
          disabled={!isValidAddress}
          label={$t("contact_info_card.validate_address")}
        />
      </div>
    {/if}
  </div>

  <div class={styles.contacts}>
    <div class={styles.title}>{$t("contact_info_card.language")}</div>
    <div>
      {language ? $t(`sample.language.${language}`) : "-"}
    </div>

    <div class={styles.title}>{$t("contact_info_card.contact")}</div>
    <div>{contact || "-"}</div>
  </div>
</div>

<style lang="scss">
  .contacts {
    padding: 10px 20px 15px;
    border-bottom: 1px solid var(--primary-050);

    &-validate {
      margin-top: 15px;
    }

    &:last-child {
      border-bottom: none;
    }

    &.validated {
      background: var(--green-010);
    }
  }

  .title {
    color: var(--primary-300);

    &:not(:first-child) {
      margin-top: 5px;
    }

    .validated & {
      color: var(--green-400);
    }
  }

  .bold {
    font-weight: 600;
  }

  .placeholder {
    color: var(--primary-200);
  }

  .invalid {
    color: var(--red-200);
  }
</style>
