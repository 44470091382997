<script>
  import clsx from "clsx";

  import Icons from "components/icons/Icons.svelte";
  import { t } from "stores/i18n";
  import { toasts } from "stores/toasts";
  import { get } from "svelte/store";

  import { Button, PasswordInput } from "components";

  import { defaultRules } from "./constants";
  import { getValidators } from "./helpers";

  import styles from "styleguide/ResetPasswordForm.json";

  export let onSubmit = () => {};
  export let rules = { ...defaultRules };
  export let title = $t("reset_password_form.password_header");
  export let description = "";
  export let actionBtnText = $t("reset_password_form.save");
  export let isCurrentPassword = true;
  export let isTwoColumnLayout = true;
  export let isFullWidthAction = false;

  let newPassword = "";
  let newPasswordConfirmation = "";
  let isPasswordMatch = false;
  let isPasswordValid = false;
  let currentPassword;

  const footnotes = isCurrentPassword
    ? [$t("reset_password_form.password_desc_help")]
    : [];

  $: currentValidators = getValidators({
    password: newPassword,
    rules,
    isPasswordMatch,
  });
  $: isPasswordValid = currentValidators.every(
    (validator) => validator.condition,
  );
  $: isDisabled = (!isPasswordValid || !currentPassword) && isCurrentPassword;

  function checkValidators() {
    checkPasswordMatch();

    currentValidators = getValidators({
      password: newPassword,
      rules,
      isPasswordMatch,
    });
  }

  function checkPasswordMatch() {
    isPasswordMatch = newPassword && newPassword === newPasswordConfirmation;
  }

  function handleSubmit() {
    if (!isPasswordValid) {
      toasts.send({
        message: get(t)("reset_password_form.requirements_error"),
        type: "error",
      });

      return;
    }

    const data = {
      ...(currentPassword && { currentPassword }),
      newPassword,
      newPasswordConfirmation,
    };

    onSubmit(data);
  }
</script>

<div data-component="ResetPasswordForm" class={styles.wrapper}>
  <div class={styles.content}>
    <div class={styles.head}>
      <div class={styles.title}>
        {title}
      </div>

      {#if description}
        <span>{description}</span>
      {/if}
    </div>

    <div class={styles["requirements-wrapper"]}>
      <span>{$t("reset_password_form.password_desc_list")}</span>

      <ul class={styles.requirements}>
        {#each currentValidators as validator}
          <li
            class={clsx(styles.requirement, {
              [styles["valid"]]: validator.condition,
            })}
          >
            {#if validator.condition}
              <div class={styles.checkmark}>
                <Icons icon="checkmarkFilled" width="15" height="15" />
              </div>
            {/if}
            <span>{validator.name}</span>
          </li>
        {/each}
      </ul>
    </div>

    {#if !!rules.non_reuse_count}
      <span>
        {$t("reset_password_form.password_non_reuse_count", {
          postProcess: "interval",
          count: rules.non_reuse_count,
        })}
      </span>
    {/if}

    {#if isCurrentPassword}
      <div class={styles["inputs-section"]}>
        <PasswordInput
          label={$t("reset_password_form.password_old")}
          bind:value={currentPassword}
          footnotes={[$t("reset_password_form.password_desc_help")]}
        />
      </div>
    {/if}

    <div
      class={clsx(styles["inputs-section"], {
        [styles["column"]]: !isTwoColumnLayout,
      })}
    >
      <PasswordInput
        label={$t("reset_password_form.password_new")}
        bind:value={newPassword}
        {footnotes}
        onKeyup={checkValidators}
      />

      <PasswordInput
        label={$t("reset_password_form.password_confirm")}
        bind:value={newPasswordConfirmation}
        {footnotes}
        onKeyup={checkPasswordMatch}
      />
    </div>
  </div>

  <div class={clsx({ [styles["action-full"]]: isFullWidthAction })}>
    <Button click={handleSubmit} style={"primary"} disabled={isDisabled}>
      {actionBtnText}
    </Button>
  </div>
</div>

<style lang="scss">
  .wrapper {
    @include typography-body-regular;

    display: flex;
    flex-direction: column;
    gap: 24px;
    color: var(--primary-500);
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .head {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .title {
    @include typography-caption-semibold;
  }

  .inputs-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;

    &.column {
      grid-template-columns: 1fr;
    }
  }

  .requirements-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .requirements {
    padding: 0;
    margin: 0;
    list-style-type: disc;
  }

  .requirement {
    @include typography-body-regular;

    position: relative;
    margin-left: 20px;
    padding-left: 4px;

    &::marker {
      color: var(--blue-100);
    }

    &.valid {
      text-decoration-line: line-through;
      color: var(--primary-100);
    }
  }

  .checkmark {
    position: absolute;
    height: 15px;
    left: -17px;
    top: 50%;
    transform: translateY(-50%);
  }

  .action-full {
    display: flex;
    flex-direction: column;
  }
</style>
