<script>
  import { currentUpsellModal } from "apps/administration/stores";

  import { Modal } from "components/modals";
  import {
    ListFeatureItems,
    UpsellFooter,
    WhitelabelUpsell,
  } from "components/modals/administrationModals/AdministrationUpsellModal";
  import { t } from "stores/i18n.js";
  import { Toast } from "components";

  function onHide() {
    currentUpsellModal.set(null);
  }

  const icons = {
    //compliance
    customPasswordRule: "customPasswordRule",
    emailWhitelist: "emailSignature",
    twoFactorAuthentication: "twoFactorAuth",
    ipAllowList: "ipAllowList",
    extendedUserRoles: "extendedUserRoles",
    projectAccessRules: "projectAccessRules",
    //enterprise
    userSynchronization: "synchronization",
    singleSignOn: "singleSignOn",
    api: "api",
    customDevelopment: "customDevelopment",
    customStatistics: "chart",
    customBilling: "invoice",
    customPBCTemplates: "customPBCTemplates",
    securityQuestionnaires: "securityQuestionnaires",
  };

  const enterpriseItems = [
    "userSynchronization",
    "singleSignOn",
    "api",
    "customDevelopment",
    "customStatistics",
    "customBilling",
    "customPBCTemplates",
    "securityQuestionnaires",
  ].map((feature) => ({
    title: $t(`administrationUpsellModal.enterprise.features.${feature}.title`),
    description: $t(
      `administrationUpsellModal.enterprise.features.${feature}.description`,
    ),
    icon: icons[feature],
  }));

  const complianceItems = [
    "customPasswordRule",
    "emailWhitelist",
    "twoFactorAuthentication",
    "ipAllowList",
    "extendedUserRoles",
    "projectAccessRules",
  ].map((feature) => ({
    title: $t(`administrationUpsellModal.compliance.features.${feature}.title`),
    description: $t(
      `administrationUpsellModal.compliance.features.${feature}.description`,
    ),
    icon: icons[feature],
  }));
</script>

{#if $currentUpsellModal}
  <Modal
    isWide
    shown={$currentUpsellModal}
    {onHide}
    title={$t(`administrationUpsellModal.${$currentUpsellModal}.title`)}
    fitContent={true}
    lockBodyScroll={false}
    isScrollingInsideModal={false}
  >
    <div data-component="AdministrationUpsellModal">
      {#if $currentUpsellModal === "enterprise"}
        <Toast
          type="info"
          title={$t("administrationUpsellModal.enterprise.allinone.title")}
        />
        <ListFeatureItems featureItems={enterpriseItems} />
      {/if}
      {#if $currentUpsellModal === "compliance"}
        <ListFeatureItems featureItems={complianceItems} />
      {/if}
      {#if $currentUpsellModal === "whitelabel"}
        <WhitelabelUpsell />
      {/if}
      <UpsellFooter />
    </div>
  </Modal>
{/if}
