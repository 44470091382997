<script>
  import { onMount } from "svelte";

  import { webhooksService } from "apps/administration/modules/administrationApi/services";

  import { t } from "stores/i18n.js";

  import { Boundary, Button, Spinner, TextInput } from "components";

  import { WebhookEvents } from "./components";
  import { validators } from "./helpers";

  import styles from "styleguide/AdministrationWebhookForm.json";

  export let formData;

  let isLoading = true;
  let isActionLoading = false;

  let errors = {
    title: "",
    endpoint_url: "",
    event_types: "",
  };

  $: validationErrors = {
    title: validators.title(formData?.title || ""),
    endpoint_url: validators.endpoint_url(formData?.endpoint_url || ""),
    event_types: validators.event_types(formData?.event_types || []),
  };

  function handleChange(key) {
    errors[key] = "";
  }

  function handleSubmit() {
    const isErrors = Object.values(validationErrors).some((err) => !!err);

    if (isErrors) {
      errors = { ...validationErrors };
      return;
    }

    isActionLoading = true;

    if (formData.permalink) {
      handleEdit();
    } else {
      handleAdd();
    }
  }

  function handleEdit() {
    webhooksService.editSubscription({
      params: {
        ...formData,
      },
      onSuccess: () => {
        formData = null;
        isActionLoading = false;
      },
      onError: () => (isActionLoading = false),
    });
  }

  function handleAdd() {
    webhooksService.addSubscription({
      params: {
        ...formData,
      },
      onSuccess: () => {
        formData = null;
        isActionLoading = false;
      },
      onError: () => (isActionLoading = false),
    });
  }

  onMount(() => {
    webhooksService.getEvents({
      onSuccess: () => (isLoading = false),
      onError: () => (isLoading = false),
    });
  });
</script>

<Boundary>
  <div data-component="AdministrationWebhookForm" class={styles.wrapper}>
    {#if isLoading}
      <Spinner scale="0.2" color="var(--primary-100)" />
    {:else}
      <TextInput
        label={$t("administration_webhooks.title")}
        style={"border medium round-border"}
        bind:value={formData.title}
        onInput={() => handleChange("title")}
        errors={errors.title ? [errors.title] : []}
      />

      <TextInput
        label={$t("administration_webhooks.endpoint")}
        style={"border medium round-border"}
        bind:value={formData.endpoint_url}
        onInput={() => handleChange("endpoint_url")}
        errors={errors.endpoint_url ? [errors.endpoint_url] : []}
      />

      <WebhookEvents
        bind:events={formData.event_types}
        onChange={() => handleChange("event_types")}
        error={errors.event_types}
      />

      <div class={styles.buttons}>
        <Button
          style={"primary"}
          click={handleSubmit}
          disabled={isActionLoading}
        >
          {formData?.permalink ? $t("actions.edit") : $t("actions.save")}
        </Button>

        <Button
          style={"error-text"}
          click={() => (formData = null)}
          disabled={isActionLoading}
        >
          {$t("actions.cancel")}
        </Button>
      </div>
    {/if}
  </div>
</Boundary>

<style lang="scss">
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .buttons {
    display: flex;
    gap: 8px;
  }
</style>
