<script>
  import styles from "styleguide/AuditTemplateItem.json";

  export let title = "";
  export let description = "";
  export let onClick;
</script>

<div data-component="AuditTemplateItem" class={styles.item} on:click={onClick}>
  <div class={styles.title}>{title}</div>
  {#if description}
    <div class={styles.description}>{description}</div>
  {/if}
</div>

<style lang="scss">
  .item {
    padding: 16px;
    border-radius: 12px;
    min-height: 120px;
    border: 1px solid var(--primary-050);
    cursor: pointer;
    transition: all 0.2s ease-out;
    &:hover {
      border: 1px solid var(--blue-100);
      background: var(--blue-010);
    }
  }

  .title {
    @include typography-body-medium;
    color: var(--blue-200);
    margin-bottom: 8px;
  }

  .description {
    @include typography-footnote-regular;
    color: var(--primary-500);
  }
</style>
