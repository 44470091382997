import { t } from "stores/i18n.js";
import { setModalsContentToStorage } from "stores/modals.js";
import { setPageHistoryToStorage } from "stores/page_history.js";
import { toasts } from "stores/toasts.js";
import { get } from "svelte/store";

import { csrfToken } from "lib/helpers.js";

class MainNavigationService {
  constructor() {}

  logout({ onSuccess, onError } = {}) {
    fetch("/logins/sign_out", {
      method: "DELETE",
      headers: {
        "X-CSRF-Token": csrfToken(),
      },
    })
      .then((res) => {
        setPageHistoryToStorage(null);
        setModalsContentToStorage(null);
        window.location.href = "/login";
        onSuccess?.(res);
      })
      .catch((err) => {
        toasts.send({
          message: get(t)("errors.unknown"),
          type: "error",
        });
        onError?.(err);
      });
  }

  static instance;

  static getInstance() {
    if (!MainNavigationService.instance) {
      MainNavigationService.instance = new MainNavigationService();
    }
    return MainNavigationService.instance;
  }
}

export const mainNavigationService = MainNavigationService.getInstance();
