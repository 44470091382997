<script>
  import { onMount } from "svelte";

  import { currentAdminPage } from "apps/administration/stores";
  import { breadcrumb } from "stores/breadcrumb.js";
  import { t } from "stores/i18n.js";
  import { updatePageHistory } from "stores/page_history.js";

  import AccountApi from "apis/admin/accounts.js";
  import { userPath, dateTimeLogFormat } from "lib/helpers.js";

  import { Box, Pagination } from "components";
  import styles from "styleguide/AdministrationLogs.json";

  onMount(async () => {
    loadLogs(1);

    breadcrumb.set([
      {
        translation: "administration",
        click: () => {
          currentAdminPage.set("dashboard");
          window.history.pushState("", userPath + "/account/");
        },
      },
      { translation: "logs" },
    ]);

    updatePageHistory([
      {
        path: userPath + "/account/logs",
        titles: [{ translation: "administration" }, { translation: "logs" }],
      },
    ]);
  });
  let logs = false;

  function loadLogs(page) {
    AccountApi.getLogs({
      params: {
        page: page,
      },
      success: function (data) {
        logs = data;
      },
    });
  }
</script>

<div data-component="AdministrationLogs">
  <Box title={$t("administration_logs.title")} style="padding no-title-border">
    {#if logs}
      <table>
        {#each logs.activities as activity}
          <tr>
            <td class={styles.date}>{dateTimeLogFormat(activity.created_at)}</td
            >
            <td>
              <strong>{activity.user}</strong>
              {activity.message}
            </td>
          </tr>
        {/each}
      </table>
      <div class={styles.footer}>
        <Pagination
          currentPage={logs.page}
          pagesNumber={logs.pages_num}
          click={loadLogs}
        />
      </div>
    {/if}
  </Box>
</div>

<style scoped lang="scss">
  .date {
    width: 130px;
  }
  td {
    vertical-align: top;
    padding-bottom: 0.25em;
  }
  .footer {
    display: flex;
    text-align: center;
    justify-content: center;
    gap: 1em;
  }
</style>
