<script>
  import { MainNavigation } from "shared/modules";

  import { Boundary } from "components";
  import styles from "styleguide/QuickshareLandingHeader.json";
</script>

<Boundary>
  <div data-component="QuickshareLandingHeader" class={styles.header}>
    <MainNavigation
      showActions={false}
      showLanguageSwitcher={true}
      isAccountLink={false}
    />
  </div>
</Boundary>

<style lang="scss">
  .header {
    min-height: 80px;
  }
</style>
