<script>
  import { t } from "stores/i18n.js";
  import { audit, filteredEntities } from "stores/pbc";
  import { isUserTeamUser, isUserClient } from "stores/user.js";
  import { platformLink } from "lib/helpers";

  import { Button, Checkbox, InputWrapper, TextInput } from "components";
  import UserSelectorItem from "./components/UserSelectorItem.svelte";
  import styles from "styleguide/UserSelector.json";

  export let style = ""; // 'embedded'
  export let users = [];
  export let selectedUsers = [];
  export let teams = [];
  export let selectedTeams = [];
  export let mode = "users"; // 'teams'
  export let onUpdateUsers;
  export let onUpdateTeams;
  export let onSave;
  export let label;
  export let controlIds;

  let filterQuery = "";
  let isAllUsersChecked;
  let toggled;

  const userMode = $isUserClient || !$audit.client_id ? "user" : "team_user";
  const clientLink = platformLink(`clients/${$audit.client_id}/edit`);

  $: calculatedStyles = function () {
    let s = style.split(" ");
    const res = s.map((x) => styles[x]).join(" ");
    return res;
  };

  $: {
    mode;
    users;
    teams;
    selectedUsers;
    selectedTeams;
    updateEntities();
    isAllUsersChecked = $filteredEntities.every((entity) => entity.selected);
    toggled = $filteredEntities.some((entity) => entity.selected === true);
  }

  function getSortedEntities(selectedEntities, entities, query) {
    const selectedEntitiesPermalinks = selectedEntities.map((entity) => {
      return entity.permalink ? entity.permalink : entity;
    });

    return entities.reduce((accumulator, entity) => {
      const entityLabel = entity.email ? entity.email : entity.name;
      if (entityLabel.toLowerCase().includes(query)) {
        entity.selected = selectedEntitiesPermalinks.includes(entity.permalink);
        accumulator.push(entity);
      }
      return accumulator;
    }, []);
  }

  function updateEntities() {
    const query = filterQuery.toLowerCase();

    let filteredUsers = getSortedEntities(selectedUsers, users, query);
    let filteredTeams = getSortedEntities(selectedTeams, teams, query);

    filteredEntities.set(mode == "users" ? filteredUsers : filteredTeams);
  }

  function onUserToggleAll(checked) {
    const users = $filteredEntities;
    users.map((u) => (u.selected = checked));
    filteredEntities.set(users);
    emitChanges();
  }

  function onToggle(checked, entity) {
    entity.selected = checked;
    if (!style.includes("embedded")) {
      emitChanges();
    }
  }

  function emitChanges() {
    onUpdateUsers?.(getSelectedPermalinks(users));
    onUpdateTeams?.(getSelectedPermalinks(teams));
    onSave?.({
      teams: getSelectedPermalinks(teams),
      users: getSelectedPermalinks(users),
    });
  }

  function createNewClientUser() {
    onSave?.({
      controlIds,
      filterQuery,
    });
  }

  function getSelectedPermalinks(collection) {
    return collection.reduce((selectedPermalinks, item) => {
      if (item.selected) {
        selectedPermalinks.push(item.permalink);
      }
      return selectedPermalinks;
    }, []);
  }

  function goToSettings(event) {
    event.preventDefault();
    window.location = clientLink;
  }
</script>

<div data-component="UserSelector" class={calculatedStyles()}>
  <InputWrapper>
    <div class={styles.filter}>
      <TextInput
        bind:value={filterQuery}
        onInput={updateEntities}
        icon="search"
        {label}
      />
    </div>
    <table>
      <thead>
        <div class={styles.header}>
          <Checkbox
            checkmark={isAllUsersChecked ? "default" : "minus"}
            {toggled}
            toggle={onUserToggleAll}
          >
            <div class={styles["checkbox-label"]}>
              {$t("user_selector.email")}
            </div>
          </Checkbox>
        </div>
      </thead>
      <tbody>
        <div class={styles["list-container"]} data-tooltip-container="true">
          {#if $filteredEntities.length === 0}
            <div class={styles["no-match"]}>
              <span>{$t(`user_selector.no_${mode}_found_for_${userMode}`)}</span
              >
              {#if $audit.client_id}
                <a href={clientLink} on:click={goToSettings}>
                  {$t("user_selector.client_settings_button")}
                </a>
              {/if}
            </div>
          {:else}
            {#each $filteredEntities as entity}
              <UserSelectorItem {entity} {style} {onToggle} />
            {/each}
          {/if}
        </div>
      </tbody>
    </table>
    {#if onSave}
      <div class={styles.footer}>
        {#if !$filteredEntities.length && !$isUserTeamUser && mode === "users"}
          <Button style="primary" click={createNewClientUser}>
            {$t("user_selector.add_user")}
          </Button>
        {:else}
          <Button style="primary" click={emitChanges}>
            {$t("user_selector.save")}
          </Button>
        {/if}
      </div>
    {/if}
  </InputWrapper>
</div>

<style scoped lang="scss">
  .list-container {
    max-height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
    display: block;
  }

  table {
    position: relative;
    width: 100%;
    border-collapse: collapse;
    margin: 0;
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 1;
    background: var(--white);
  }

  .filter {
    padding: 0 24px;
  }

  .header {
    cursor: pointer;
    padding: 0 24px;
    border-bottom: 1px solid var(--primary-050);
  }

  .checkbox-label {
    display: flex;
    align-items: center;
    height: 54px;
    font-weight: 600;
    padding: 0 14px;
  }

  .embedded thead {
    display: none;
  }
  .embedded .filter {
    padding: 1em;
    border-bottom: 1px solid var(--primary-050);
  }
  .footer {
    padding: 1em 1em;
    border-top: 1px solid var(--primary-050);
  }

  .no-match {
    padding: 14px;
  }
</style>
