<script>
  import { Activities } from "components";

  export let store;
  export let services;
  export let itemId;

  const { ActivitiesService } = services;

  function getActivities(page = 1) {
    ActivitiesService.getActivities({
      params: {
        page,
        id: itemId,
      },
    });
  }
</script>

<div data-component="SidebarActivity">
  <Activities
    style={"max-height"}
    activitiesStore={store.activities}
    {getActivities}
  />
</div>
