<script>
  import { AuditTemplateItem } from "apps/pbc/components/AuditTemplate/components/index.js";
  import { t } from "stores/i18n.js";
  import { Skeleton } from "components";
  import styles from "styleguide/AuditTemplateList.json";

  export let title = "";
  export let filteredTemplates;
  export let onClickDetails;
</script>

<Skeleton loading={!filteredTemplates}>
  <div class={styles.title} id={title}>
    {$t(`audit_template.${title}`)}
  </div>
  <div class={styles.description}>
    {$t(`audit_template.${title}_description`)}
  </div>
  <div class={styles["template-list"]}>
    {#each filteredTemplates as template}
      <AuditTemplateItem
        title={template.name}
        description={template.description}
        onClick={() => onClickDetails(template, title)}
      />
    {/each}
  </div>
</Skeleton>

<style lang="scss">
  .title {
    @include typography-caption-semibold;
    margin-bottom: 8px;
    &:first-child {
      padding-top: 16px;
      margin-top: -16px;
    }
  }
  .description {
    @include typography-footnote-regular;
    color: var(--primary-500);
    margin-bottom: 16px;
  }
  .template-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 16px;
    margin-bottom: 16px;
  }
</style>
