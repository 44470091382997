import { writable, derived, get } from "svelte/store";
import ConfirmationAttachmentsApi from "apis/confirmation/confirmation_attachments.js";
import ConfirmationItemsApi from "apis/confirmation/confirmation_items.js";

const itemId = writable(null);
const confirmationId = writable(null);
const parentType = writable("");
const item = writable({ confirmation_signers: [] });
const letterhead = writable({});
const comments = writable([]);
const activities = writable([]);
const activeEditListItem = writable(false);
const statusesPossibleDelete = ["imported"];
const confirmationAttachments = writable([]);
const isCommentsVisible = writable(true);

item.subscribe((value) => {
  if (value.permalink) {
    itemId.set(value.permalink);
    confirmationId.set(value.confirmation_permalink);
    parentType.set("confirmation_items");
  }
});

const entries = derived([item], ([$item], set) => {
  let mailings = $item.confirmation_mailings ? $item.confirmation_mailings : [];
  mailings.forEach((entry) => (entry.type = "mailings"));
  let responses = $item.confirmation_responses
    ? $item.confirmation_responses
    : [];
  responses.forEach((entry) => (entry.type = "responses"));
  let newEntries = [...mailings, ...responses].sort((a, b) => {
    return new Date(b.created_at) - new Date(a.created_at);
  });
  set(newEntries);
});

const clientUsers = derived([item], ([$item], set) => {
  const type = $item.confirmation_type;
  const clientsGroupedByTypes = $item.confirmation_client_users.reduce(
    (acc, user) => {
      const { confirmation_type } = user;
      acc[confirmation_type] = [...(acc[confirmation_type] || []), user];
      return acc;
    },
    {},
  );

  let clientsByTypes =
    clientsGroupedByTypes[type]?.map((user) => user.client_user) || [];
  set(clientsByTypes);
});

function updateComments(comment) {
  comments.update((existingComments) => [comment, ...existingComments]);
}

function getItem() {
  ConfirmationItemsApi.getItem({
    params: {
      id: get(itemId),
    },
    success: (data) => {
      item.set(data);
    },
    error: () => {},
  });
}

function getConfirmationAttachments() {
  ConfirmationAttachmentsApi.get({
    params: {
      confirmation_id: get(confirmationId),
    },
    success: (response) => {
      confirmationAttachments.set(response.data.attachments);
    },
  });
}

export {
  item,
  itemId,
  parentType,
  entries,
  clientUsers,
  updateComments,
  getItem,
  getConfirmationAttachments,
  comments,
  letterhead,
  confirmationId,
  activeEditListItem,
  activities,
  statusesPossibleDelete,
  confirmationAttachments,
  isCommentsVisible,
};
