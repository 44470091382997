<script>
  import { onMount, setContext } from "svelte";

  import { account } from "stores/account";
  import * as brandSpaceStore from "stores/brand_space";
  import { initializeI18N } from "stores/i18n";

  import { Boundary, Logo, Theme } from "components";

  import styles from "styleguide/AppAuth.json";

  export let currentAccount;
  export let language;
  export let theme;
  export let brandSpace;

  let i18nInitialized = false;

  setContext("config", {
    language,
  });

  account.set(currentAccount);
  brandSpaceStore.brandSpace.set(brandSpace);

  onMount(async () => {
    initializeI18N(language).then(() => {
      i18nInitialized = true;
    });
  });
</script>

<Boundary>
  {#if i18nInitialized}
    <Theme name={theme}>
      <div class={styles.wrapper}>
        <div class={styles.content}>
          <div class={styles.box}>
            <div class={styles["box-header"]}>
              <a href="/login">
                <Logo />
              </a>
            </div>

            <div class={styles["box-body"]}>
              <slot />
            </div>

            {#if $$slots.boxFooter}
              <div class={styles["box-footer"]}>
                <slot name="boxFooter" />
              </div>
            {/if}
          </div>

          {#if $$slots.info}
            <div class={styles.info}>
              <slot name="info" />
            </div>
          {/if}
        </div>
      </div>
    </Theme>
  {/if}
</Boundary>

<style lang="scss">
  .wrapper {
    @include typography-body-regular;

    padding: 60px 0;
    display: flex;
    width: 100vw;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
    background: var(--primary-010);
    box-sizing: border-box;
  }

  .content {
    width: 400px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .box {
    background: var(--white);
    border-radius: var(--border-radius);
    border: 1px solid var(--primary-050);
  }

  .box-header {
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    background: var(--header-background-color);
    border-bottom: 1px solid var(--primary-050);
    padding: 24px 0;
    display: flex;
    justify-content: center;
  }

  .box-body {
    padding: 40px;
  }

  .box-footer {
    border-top: 1px solid var(--primary-050);
    padding: 16px 0;
    text-align: center;
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
  }

  .info {
    @include typography-footnote-regular;

    color: var(--primary-300);
    text-align: center;
  }
</style>
