<script>
  import { hasBrandSpace } from "stores/brand_space";
  import {
    activeExport,
    activeLetterhead,
    activeRollForward,
    sidebar,
    filter,
    setFilter,
    filtersType,
    filtersNew,
    confirmationId,
  } from "stores/confirmation/confirmation.js";
  import { t } from "stores/i18n.js";
  import { can } from "stores/permission.js";
  import { toasts } from "stores/toasts.js";
  import { user } from "stores/user.js";
  import ConfirmationApi from "apis/confirmation/confirmation.js";

  import { Boundary, Button, LegacyButton, SidebarArea } from "components";
  import styles from "styleguide/ConfirmationSidebarStatus.json";

  function testSignMailings() {
    ConfirmationApi.testSignMailings($confirmationId);
  }

  function testMailingsSend() {
    ConfirmationApi.testMailingsSend($confirmationId);
  }

  function testResponseReceive() {
    ConfirmationApi.testResponseReceive($confirmationId);
  }

  function sendContactValidations() {
    ConfirmationApi.sendContactValidations($confirmationId);
    toasts.send({
      title: $t("confirmation_sidebar_status.contact_validations_sent"),
      type: "success",
    });
  }

  function downloadAllResponses() {
    ConfirmationApi.downloadAllResponses({
      params: { id: $confirmationId },
      success: () => {
        toasts.send({
          title: $t(
            "confirmation_sidebar_status.download_all_responses_success",
          ),
          type: "success",
        });
      },
      error: () => {
        toasts.send({ title: $t("errors.unknown"), type: "error" });
      },
    });
  }
</script>

<Boundary>
  <div data-component="ConfirmationSidebarStatus" class={styles.wrapper}>
    <SidebarArea style="border-top padding">
      {#each $filtersType as type, index}
        <LegacyButton
          color="grey"
          style={index > 0 ? "margin-top" : ""}
          click={() => setFilter(type, $t(`confirmation.types.${type}`), true)}
          selected={$filter === type}
          counter={$sidebar[`${type}_items_count`]}
          >{$t(`confirmation.types.${type}`)}</LegacyButton
        >
      {/each}
    </SidebarArea>

    <SidebarArea style="border-top padding padding-bottom">
      {#each filtersNew as type}
        <LegacyButton
          color="grey"
          style="margin-top"
          click={() =>
            setFilter(type, $t(`confirmation_sidebar_status.${type}`), true)}
          selected={$filter === type}
          counter={$sidebar[`${type}_count`]}
          >{$t(`confirmation_sidebar_status.${type}`)}</LegacyButton
        >
      {/each}
    </SidebarArea>

    <SidebarArea>
      {#if $user.role !== "client"}
        <Button
          click={() => {
            activeLetterhead.set(true);
          }}
          style="blue-text sidebar-action"
          >{$t("confirmation_sidebar_status.upload_letterhead")}</Button
        >
      {/if}

      {#if $user.role !== "client"}
        <Button
          click={() => activeExport.set(true)}
          style="blue-text sidebar-action"
          >{$t("confirmation_sidebar_status.export_documents")}</Button
        >
      {/if}

      {#if $user.role === "client" && $sidebar.new_answers_count > 0}
        <Button click={downloadAllResponses} style="blue-text sidebar-action"
          >{$t("confirmation_sidebar_status.download_all_responses")}</Button
        >
      {/if}

      {#if $user.role !== "client"}
        <Button
          click={() => activeRollForward.set(true)}
          style="blue-text sidebar-action"
          >{$t("confirmation_sidebar_status.roll_forward")}</Button
        >
      {/if}

      {#if $hasBrandSpace("eyde") && $can("eyde_validate_contacts", "confirmation")}
        <Button click={sendContactValidations} style="blue-text sidebar-action"
          >{$t("confirmation_sidebar_status.send_contact_validations")}</Button
        >
      {/if}

      {#if $user.is_not_production_env}
        <Button click={testMailingsSend} style="blue-text sidebar-action"
          >Test mailings send</Button
        >
        <Button click={testResponseReceive} style="blue-text sidebar-action"
          >Test answer receive</Button
        >
      {/if}
    </SidebarArea>
  </div>
</Boundary>

<style lang="scss">
  .wrapper {
    padding: 15px 20px;
  }
</style>
