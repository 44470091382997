<script>
  import { t } from "stores/i18n.js";

  import { NavButton, LanguageSwitcher, Logo } from "components";

  import styles from "styleguide/FrontendHeader.json";

  export let showLoginBtn = true;
</script>

<div data-component="FrontendHeader" class={styles.header}>
  <Logo />

  <div class={styles.buttons}>
    {#if showLoginBtn}
      <NavButton link="/login" isPublic>
        {$t("actions.login")}
      </NavButton>
    {/if}

    <LanguageSwitcher />
  </div>
</div>

<style lang="scss">
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 35px;
    border-bottom: solid 1px var(--primary-050);
    background: var(--header-background-color);
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
  }

  .buttons {
    display: flex;
    gap: 12px;
  }
</style>
