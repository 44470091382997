import Api from "apis/api";
import { buildFormData } from "lib/helpers";

class PasswordResetApi {
  constructor() {
    this.endpoint = window.location.protocol + window.location.host + "/logins";
  }

  resetPassword(options) {
    const { params, success, error } = options;

    const formData = new FormData();

    const payload = {
      login: {
        reset_password_token: params.token,
        password: params.newPassword,
        password_confirmation: params.newPasswordConfirmation,
      },
    };
    buildFormData(formData, payload);

    Api.put(`${this.endpoint}/password`, {
      headers: { Accept: "application/json" },
      body: formData,
      success: (res) => {
        success(res);
      },
      error: (err) => {
        if (error) {
          error(err);
        } else {
          console.error("Error", err);
        }
      },
    });
  }

  static instance;

  static getInstance() {
    if (!PasswordResetApi.instance) {
      PasswordResetApi.instance = new PasswordResetApi();
    }
    return PasswordResetApi.instance;
  }
}

export const passwordResetApi = PasswordResetApi.getInstance();
