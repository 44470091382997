<script>
  import { onMount } from "svelte";

  import { currentAdminPage } from "apps/administration/stores";
  import { isAccountFeature } from "stores/account";
  import { breadcrumb } from "stores/breadcrumb.js";
  import { updatePageHistory } from "stores/page_history.js";

  import { userPath } from "lib/helpers.js";

  import { Boundary } from "components";
  import { ApiKey, Webhooks } from "./components";

  import styles from "styleguide/AdministrationApi.json";

  onMount(async () => {
    breadcrumb.set([
      {
        translation: "administration",
        click: () => {
          currentAdminPage.set("dashboard");
          window.history.pushState("", userPath + "/account/");
        },
      },
      { translation: "api" },
    ]);

    updatePageHistory([
      {
        path: userPath + "/account/api",
        titles: [{ translation: "administration" }, { translation: "api" }],
      },
    ]);
  });
</script>

<Boundary>
  <div data-component="AdministrationApi" class={styles.wrapper}>
    <ApiKey />

    {#if $isAccountFeature("webhooks")}
      <Webhooks />
    {/if}
  </div>
</Boundary>

<style lang="scss">
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    max-width: 680px;
  }
</style>
