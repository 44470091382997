import { webhooksApi } from "apps/administration/modules/administrationApi/apis";

import { t } from "stores/i18n.js";
import { toasts } from "stores/toasts.js";
import { get } from "svelte/store";

import { webhookEvents, subscriptions } from "../stores";

class WebhooksService {
  constructor() {}

  getSubscriptions({ onSuccess, onError } = {}) {
    webhooksApi.getSubscriptions({
      success: (res) => {
        subscriptions.set(res?.data?.webook_subscriptions || []);
        onSuccess?.(res);
      },
      error: (err) => {
        toasts.send({
          message: get(t)("errors.unknown"),
          type: "error",
        });
        onError?.(err);
      },
    });
  }

  addSubscription({ params, onSuccess, onError } = {}) {
    webhooksApi.addSubscription({
      params,
      success: (res) => {
        const item = res?.data?.webhook_subscription;
        subscriptions.update((data) => [...(item ? [item] : []), ...data]);
        onSuccess?.(res);
      },
      error: (err) => {
        toasts.send({
          message: get(t)("errors.unknown"),
          type: "error",
        });
        onError?.(err);
      },
    });
  }

  editSubscription({ params, onSuccess, onError } = {}) {
    webhooksApi.editSubscription({
      params,
      success: (res) => {
        const item = res?.data?.webhook_subscription;
        subscriptions.update((data) =>
          data.map((subscr) => {
            if (subscr.permalink === item.permalink) {
              return item;
            } else {
              return subscr;
            }
          }),
        );
        onSuccess?.(res);
      },
      error: (err) => {
        toasts.send({
          message: get(t)("errors.unknown"),
          type: "error",
        });
        onError?.(err);
      },
    });
  }

  deleteSubscription({ params, onSuccess, onError } = {}) {
    webhooksApi.deleteSubscription({
      params,
      success: (res) => {
        subscriptions.update((data) =>
          data.filter((subscr) => subscr.permalink !== params.id),
        );
        onSuccess?.(res);
      },
      error: (err) => {
        toasts.send({
          message: get(t)("errors.unknown"),
          type: "error",
        });
        onError?.(err);
      },
    });
  }

  getEvents({ onSuccess, onError } = {}) {
    webhooksApi.getEvents({
      success: (res) => {
        webhookEvents.set(res?.data?.events || {});
        onSuccess?.(res);
      },
      error: (err) => {
        toasts.send({
          message: get(t)("errors.unknown"),
          type: "error",
        });
        onError?.(err);
      },
    });
  }

  static instance;

  static getInstance() {
    if (!WebhooksService.instance) {
      WebhooksService.instance = new WebhooksService();
    }
    return WebhooksService.instance;
  }
}

export const webhooksService = WebhooksService.getInstance();
