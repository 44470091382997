<script>
  import { onMount } from "svelte";

  import { apiKeyService } from "apps/administration/modules/administrationApi/services";
  import { t } from "stores/i18n.js";
  import { user } from "stores/user.js";

  import { dateTimeFormat } from "lib/helpers.js";

  import { Button, Toast, Boundary, Box } from "components";

  import styles from "styleguide/AdministrationApiKey.json";

  let apiKey;
  let apiKeyPresent = false;
  let apiKeyGeneratedAt;

  function generateApiKey() {
    if (apiKeyPresent ? confirm($t("administration_api.alert")) : true) {
      const payload = {
        user: $user,
      };

      apiKeyService.generateApiKey({
        payload,
        onSuccess: (data) => {
          apiKey = data.api_key;
          apiKeyPresent = true;
          apiKeyGeneratedAt = data.generated_at;
        },
      });
    }
  }

  function confirmApiKey() {
    apiKey = null;
  }

  function deleteApiKey() {
    if (confirm($t("administration_api.alert"))) {
      const payload = {
        user: $user,
      };

      apiKeyService.deleteApiKey({
        payload,
        onSuccess: () => {
          apiKey = apiKeyPresent = apiKeyGeneratedAt = null;
        },
      });
    }
  }

  onMount(() => {
    apiKeyService.hasApiKey({
      onSuccess: (data) => {
        apiKeyPresent = data.has_key;
        apiKeyGeneratedAt = data.generated_at;
      },
    });
  });
</script>

<Boundary>
  <Box>
    <div data-component="AdministrationApiKey" class={styles.wrapper}>
      <div class={styles.header}>
        {$t("administration_api.header")}
      </div>

      <div class={styles.description}>
        {$t("administration_api.description")}
      </div>

      {#if apiKey}
        <Toast
          title={$t("administration_api.key_title")}
          message={apiKey}
          margin={false}
        >
          <p>
            {$t("administration_api.key_description")}
          </p>
          <Button click={confirmApiKey} style={"primary"}>
            {$t("administration_api.key_confirm")}
          </Button>
        </Toast>
      {/if}

      {#if apiKeyPresent && !apiKey}
        {@html $t("administration_api.key_present", {
          date: dateTimeFormat(apiKeyGeneratedAt),
        })}
      {/if}

      {#if !apiKey}
        <div class={styles.buttons}>
          <Button click={generateApiKey} style={"primary"}>
            {$t(
              apiKeyPresent
                ? "administration_api.rotate"
                : "administration_api.generate",
            )}
          </Button>
          {#if apiKeyPresent}
            <Button click={deleteApiKey} style={"error-text"}>
              {$t("administration_api.delete")}
            </Button>
          {/if}
        </div>
      {/if}
    </div>
  </Box>
</Boundary>

<style lang="scss">
  .wrapper {
    padding: 24px;
  }

  .header {
    @include typography-caption-semibold;

    color: var(--primary-500);
    padding-bottom: 8px;
  }

  .description {
    padding-bottom: 16px;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 16px;
  }
</style>
