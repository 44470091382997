import Api from "apis/api";
import { buildFormData } from "lib/helpers";

class PasswordForgotApi {
  constructor() {
    this.endpoint = window.location.protocol + window.location.host + "/logins";
  }

  confirmEmail(options) {
    const { params, success, error } = options;

    const formData = new FormData();

    const payload = {
      login: {
        email: params.email,
      },
    };
    buildFormData(formData, payload);

    Api.post(`${this.endpoint}/password`, {
      body: formData,
      success: (response) => {
        success(response?.data);
      },
      error: (response) => {
        if (error) {
          error();
        } else {
          console.error("Error", response);
        }
      },
    });
  }

  static instance;

  static getInstance() {
    if (!PasswordForgotApi.instance) {
      PasswordForgotApi.instance = new PasswordForgotApi();
    }
    return PasswordForgotApi.instance;
  }
}

export const passwordForgotApi = PasswordForgotApi.getInstance();
