// Rename to project
import { writable, get } from "svelte/store";
import Projects from "apis/dashboard/projects.js";

let projectId;

const project = writable({});
const projectActivities = writable([]);

const projects = writable([]);
const projectsLoading = writable(true);
const couldSaveUsers = writable(true);
const projectsStats = writable(false);
const projectsToShow = writable({});
const projectsSort = writable(null);
const projectsSelectedFilters = writable({});
const collapsedGroups = writable({});
const isCollapseAllGroups = writable(getInitialCollapseState());
const projectsAccesses = writable({});
const prevStatusLabel = writable(null);
const shownArchiveModal = writable(false);
const statusesCount = writable(null);
const dashboardProjectState = writable({});

const page = writable(1);
const pagesNum = writable(1);

const statusesConfig = [
  "unseen_documents_count",
  "unseen_comments_count",
  "overdue_controls_count",
  "waiting_for_review_controls_count",
  "due_date_controls_count",
  "new_responses_count",
  "waiting_confirmation_count",
  "waiting_companies_count",
  "bounced_mailings_count"
];

projects.subscribe((value) => {
  projectsToShow.set(value);
  collapsedGroups.set(
    JSON.parse(localStorage.getItem("projects_collapsed")) || {},
  );
});

isCollapseAllGroups.subscribe((value) => {
  localStorage.setItem("projectsIsCollapseAll", JSON.stringify(value));
});

function getInitialCollapseState() {
  const storedValue = localStorage.getItem("projectsIsCollapseAll");
  return storedValue === null ? false : JSON.parse(storedValue);
}

function setCollapsedGroups(groups) {
  collapsedGroups.set(groups);
  localStorage.setItem("projects_collapsed", JSON.stringify(groups));
}

function getProject(id) {
  const params = {
    params: "",
    success: onProjectSuccess,
    error: onProjectError,
  };

  Projects.getProject(params, id);
}

function onProjectSuccess(response) {
  receiveProject(response.data.project);
}

function onProjectError() {
  console.error("Projects Error");
}

function getProjectsOverview(projects) {
  const params = {
    params: "",
    success: onFiltersSuccess,
    error: onFiltersError,
  };

  getProjectsStatus(projects);
  Projects.getProjectsOverview(params);
}

function getProjectsStatus(projects) {
  if (!projects.length) return;
  let projectsIds = projects.map((project) => project.permalink);

  const options = {
    params: {
      ids: projectsIds,
    },
    success: (res) => {
      statusesCount.set(res.data.projects);
      projectsLoading.set(false);
    },
  };

  projectsLoading.set(true);
  Projects.getProjectsStatus(options);
}

function onFiltersSuccess(response) {
  projectsStats.set(response.data);
  checkParams();
}

function onFiltersError() {
  console.error("Filters Error");
}

function getProjects(page) {
  const params = {
    success: onProjectsSuccess,
    error: onProjectsError,
  };

  let additionParams = `?page=${page ? page : 1}`;

  for (const [key] of Object.entries(get(projectsSelectedFilters))) {
    additionParams += `&filter=${key.slice(0, -6)}`;
  }

  if (get(projectsSort)) {
    additionParams += `&sort=${get(projectsSort)}`;
  }

  params.params = additionParams;

  projectsLoading.set(true);

  Projects.get(params);

  history.pushState({ name: "dashboard" }, null, additionParams);
}

function checkParams() {
  let href = new URL(document.location);

  let sort = href.searchParams.get("sort");
  let filter = href.searchParams.get("filter");

  if (filter) filter += "_count";

  if (filter) {
    projectsSelectedFilters.set({
      [filter]: get(projectsStats)[filter],
    });
  } else {
    projectsSelectedFilters.set({});
  }

  projectsSort.set(sort);
}

function checkOnPopstate(event) {
  checkParams();
  let params = window.location.search;

  Projects.get({
    params,
    success: onProjectsSuccess,
    error: onProjectsError,
  });
}

function onProjectsSuccess(response) {
  receiveProjects(response.data);
  getProjectsStatus(response.data.projects);
}

function onProjectsError() {
  console.error("Projects Error");
}

function getProjectsActivities(page, success) {
  if (!projectId) return;

  const params = {
    success: (response) => {
      projectActivities.set({ ...response.data, loaded: true });
      success();
    },
    error: () => {
      console.error("Project Activities Error");
    },
  };

  let additionParams = `?page=${page ? page : 1}`;

  params.params = additionParams;

  Projects.getActivities(params, projectId);
}

function combineItemStatuses(data) {
  const statuses = [];

  for (const property in data) {
    const found = statusesConfig.find((item) => item === property);

    if (found) {
      statuses.push({
        status: property,
        count: data[property],
        color: found.color,
      });
    }
  }

  return statuses;
}

function combineProjects(data) {
  return data.map((project) => {
    return combineProject(project);
  });
}

function combineProject(project) {
  const {
    permalink,
    name,
    access,
    team_users,
    archived,
    project_path,
    edit_project_path,
  } = project;

  const mapProjectItems = (items, type, path) => {
    return items.map((item) => {
      item.type = type;
      item.path = item[path];
      item.statuses = combineItemStatuses(item);
      return item;
    });
  };

  const audits = mapProjectItems(
    project.audits,
    "audits",
    "apps_pbc_audit_path",
  );
  const reviews = mapProjectItems(project.reviews, "reviews", "review_path");
  const confirmations = mapProjectItems(
    project.confirmations,
    "confirmations",
    "apps_confirmation_path",
  );

  const newProject = {
    permalink,
    name,
    access,
    team_users,
    project_path,
    edit_project_path,
    status: archived ? "inactive" : "active",
    items: [...confirmations, ...reviews, ...audits],
  };

  if (
    get(projectsSelectedFilters).notifications_sba ||
    get(projectsSelectedFilters).notifications_pbc
  ) {
    newProject.items = newProject.items.filter((item) =>
      item.statuses.some((x) => x.count > 0),
    );
  }

  return newProject;
}

function refreshProject() {
  getProject(projectId);
}

function receiveProject(data) {
  project.set(combineProject(data));
  projectId = data.permalink;
}

function receiveProjects(data) {
  page.set(+data.page);
  pagesNum.set(+data.pages_num);
  projects.set(combineProjects(data.projects));
  projectsLoading.set(false);
}

function filterProjects(type, count) {
  projectsSelectedFilters.set({
    [type]: count,
  });

  getProjects(1);
}

function filterRemove() {
  projectsSelectedFilters.set({});
  prevStatusLabel.set(null);
  getProjects(1);
}

function sortProjects(sort) {
  projectsSort.set(sort);
  getProjects();
}

function setCollapseAllGroups(value) {
  isCollapseAllGroups.set(value);
}

export {
  project,
  projectId,
  projectActivities,
  projects,
  projectsLoading,
  couldSaveUsers,
  projectsStats,
  projectsToShow,
  projectsSelectedFilters,
  projectsAccesses,
  statusesConfig,
  collapsedGroups,
  prevStatusLabel,
  statusesCount,
  dashboardProjectState,
  setCollapsedGroups,
  getProject,
  getProjects,
  getProjectsActivities,
  refreshProject,
  receiveProject,
  receiveProjects,
  getProjectsOverview,
  getProjectsStatus,
  filterProjects,
  filterRemove,
  sortProjects,
  checkParams,
  checkOnPopstate,
  page,
  pagesNum,
  shownArchiveModal,
  setCollapseAllGroups,
  isCollapseAllGroups,
};
