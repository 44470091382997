import { activities } from "stores/confirmation/confirmation_item.js";
import ConfirmationItemsApi from "apis/confirmation/confirmation_items.js";

class ActivitiesService {
  constructor() {}

  getActivities({ params = {} }) {
    ConfirmationItemsApi.getActivities({
      params,
      success: (responce) => {
        activities.set({ ...responce, loaded: true });
        onSuccess?.(responce);
      },
    });
  }
}

export default new ActivitiesService();
