import { brandSpace } from "stores/brand_space";
import { writable, get } from "svelte/store";
import ConfirmationApi from "apis/confirmation/confirmation.js";
import { dateTimeString } from "lib/helpers.js";

const title = writable("");
const client = writable({});
const senderAddress = writable("");
const availableConfirmationTypes = writable([]);
const types = writable(getInitTypes());
const deadline = writable("");
const startSending = writable("");
const responsePeriod = writable("14");
const targetDate = writable("");
const fixedResponsePeriod = writable("");
const fiscalYearBeginningDate = writable("");
const invitation = writable(false);
const usePreviousLetterhead = writable(false);
const invalid = writable(false);
const companies = writable([]);
const billingData = writable({});
const step = writable(1);
const touchedSteps = writable({
  1: { dirty: false, valide: false },
  2: { dirty: false, valide: false },
  3: { dirty: false, valide: false },
  4: { dirty: false, valide: true },
});
const user = writable({});
const contactPerson = writable({});

function create(options) {
  let { projectId, success, error } = options;
  let confirmations = [];

  Object.entries(get(types)).map(([key, value]) => {
    value.forEach((item) => {
      let obj = {
        email: item.email,
        can_see_answer: item.allowed,
        can_manage_confirmation_items: item.can_manage_confirmation_items,
        confirmation_type: key,
      };

      if (obj.email) {
        confirmations.push(obj);
      }
    });
  });

  let _billingData = get(billingData);
  let billing_data = {};

  if (get(brandSpace) === "made") {
    billing_data[get(brandSpace)] = {
      anlagejahr: _billingData.anlagejahr,
      auftragsnummer: _billingData.auftragsnummer,
      auftragspartner_id: _billingData.auftragspartner.id,
    };
  } else if (get(brandSpace) === "ebde") {
    billing_data[get(brandSpace)] = _billingData;
  }

  let params = {
    confirmations,
    project_id: projectId,
    name: get(title),
    sender_address: get(senderAddress),
    client_id: get(client).id,
    deadline: get(deadline),
    start_sending_at: get(startSending),
    response_period: get(responsePeriod),
    sent_invitations: get(invitation),
    target_date: get(targetDate),
    fixed_response_period: get(fixedResponsePeriod),
    fiscal_year_beginning_date: get(fiscalYearBeginningDate),
    billing_data,
    use_previous_letterhead: get(usePreviousLetterhead),
  };

  ConfirmationApi.create({
    params,
    success,
    error,
  });
}

function reset() {
  title.set("");
  client.set({});
  senderAddress.set("");
  types.set(getInitTypes());
  deadline.set(dateTimeString(new Date(new Date().getFullYear() - 1, 11, 31)));
  targetDate.set(
    dateTimeString(new Date(new Date().getFullYear() - 1, 11, 31)),
  );
  fiscalYearBeginningDate.set(
    dateTimeString(new Date(new Date().getFullYear() - 1, 0, 1)),
  );
  startSending.set("");
  responsePeriod.set("14");
  invitation.set(false);
  usePreviousLetterhead.set(false);
  invalid.set(false);
  step.set(1);
  touchedSteps.set({
    1: { dirty: false, valide: false },
    2: { dirty: false, valide: false },
    3: { dirty: false, valide: false },
    4: { dirty: false, valide: true },
  });
}

function getInitTypes() {
  return get(availableConfirmationTypes).reduce(
    (prev, curr) => ({ ...prev, [curr]: [] }),
    {},
  );
}

function getInitBillingData() {
  switch (get(brandSpace)) {
    case "made":
      return {
        anlagejahr: 2019,
        auftragsnummer: "",
        auftragspartner: {},
      };
    case "ebde":
      return {
        order_year: 2019,
        order_number: "",
        part_number: "",
        cost: "",
      };
    default:
      return {};
  }
}

brandSpace.subscribe(() => {
  billingData.set(getInitBillingData());
});

export {
  availableConfirmationTypes,
  title,
  client,
  senderAddress,
  types,
  deadline,
  companies,
  startSending,
  responsePeriod,
  targetDate,
  billingData,
  fixedResponsePeriod,
  fiscalYearBeginningDate,
  invitation,
  usePreviousLetterhead,
  invalid,
  step,
  touchedSteps,
  user,
  contactPerson,
  create,
  reset,
  getInitBillingData,
};
