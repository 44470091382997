import { apiKeyApi } from "apps/administration/modules/administrationApi/apis";
import { t } from "stores/i18n.js";
import { toasts } from "stores/toasts.js";
import { get } from "svelte/store";

class ApiKeyService {
  constructor() {}

  generateApiKey({ payload, onSuccess, onError } = {}) {
    const { user } = payload || {};

    apiKeyApi.generateApiKey({
      params: {
        user,
      },
      success: (data) => {
        onSuccess?.(data);
      },
      error: (err) => {
        toasts.send({
          message: get(t)("errors.unknown"),
          type: "error",
        });
        onError?.(err);
      },
    });
  }

  deleteApiKey({ payload, onSuccess, onError } = {}) {
    const { user } = payload || {};

    apiKeyApi.deleteApiKey({
      params: {
        user,
      },
      success: (data) => {
        onSuccess?.(data);
      },
      error: (err) => {
        toasts.send({
          message: get(t)("errors.unknown"),
          type: "error",
        });
        onError?.(err);
      },
    });
  }

  hasApiKey({ onSuccess, onError } = {}) {
    apiKeyApi.hasApiKey({
      success: (data) => {
        onSuccess?.(data);
      },
      error: (err) => {
        toasts.send({
          message: get(t)("errors.unknown"),
          type: "error",
        });
        onError?.(err);
      },
    });
  }

  static instance;

  static getInstance() {
    if (!ApiKeyService.instance) {
      ApiKeyService.instance = new ApiKeyService();
    }
    return ApiKeyService.instance;
  }
}

export const apiKeyService = ApiKeyService.getInstance();
