import { t } from "stores/i18n.js";
import { get } from "svelte/store";

export const validators = {
  title: (item) => {
    if (!item.trim()) {
      return get(t)("administration_webhooks.title_required");
    }
    return "";
  },
  endpoint_url: (item) => {
    const regex = /^https:\/\/[^\s/$.?#].[^\s]*$/i;
    if (!regex.test(item)) {
      return get(t)("administration_webhooks.url_required");
    }

    return "";
  },
  event_types: (item) => {
    if (!item.length) {
      return get(t)("administration_webhooks.events_required");
    }

    return "";
  },
};
